import { Button, Dialog, LinearProgress, Pagination } from "@mui/material";
import { BsPencilSquare } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import iconOcultar from "../../../../../assets/iconOcultar.svg";
import { Header } from "../../../../Header";
import { ConfirmExclude } from "../../ConfirmExclude";
import { ModalAggregateUnits } from "./ModalAggregateUnits";
import "./styles.scss";
import { ContentProps, useAggregateUnits } from "./useAggregateUnits";

export function AggregateUnits() {
  const {
    modalState,
    setModalState,
    AggregateUnits,
    handlePaginate,
    setSelectedAggregateUnits,
    selectedAggregateUnits,
    handleGetAggregateUnits,
    handleEditModal,
    handleDeleteAggregateUnits,
    isLoading,
    aggregateToDelete,
    setAggregateToDelete,
    showModalExclude,
    setShowModalExclude,
    page
  } = useAggregateUnits();
  return (
    <div className="AggregateUnits-container">
      <Header
        message={"Dashboard/Lançamentos Financeiros/Unidades Agregadas"}
        showAtualizacao={true}
        backScreen={true}
        url="LancamentosFinanceiros"
      />
      <main>
        <div className="AggregateUnits-main-card">
          <header className="AggregateUnits-main-card-header">
            <h2>Unidades Agregadas</h2>
            <div className="header-buttons">
              <aside>
                <Button variant="outlined" onClick={() => setModalState(true)}>
                  Novo Registro <FaPlus />
                </Button>
              </aside>
            </div>
          </header>
          <section className="AggregateUnits-main-card-section">
            <Dialog
              open={modalState}
              onClose={() => (
                setModalState(false),
                setSelectedAggregateUnits({} as ContentProps)
              )}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalAggregateUnits
                handleCloseModal={() => (
                  setModalState(false),
                  setSelectedAggregateUnits({} as ContentProps)
                )}
                selectedAggregateUnits={selectedAggregateUnits}
                handleGetAggregateUnits={() => handleGetAggregateUnits(page)}
              />
            </Dialog>
            <Dialog
              open={showModalExclude}
              onClose={() => setShowModalExclude(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ConfirmExclude
                handleCloseModal={() => setShowModalExclude(false)}
                handleSubmit={() =>
                  handleDeleteAggregateUnits(
                    aggregateToDelete.cdUnidadesAgregadas
                  )
                }
              />
            </Dialog>
            {!isLoading ? (
              <table>
                {AggregateUnits?.content?.length !== 0 ? (
                  <thead>
                    <tr>
                      <th>Id Unidade</th>
                      <th>Id Material Equipamento</th>
                      <th>Linha Serviço Id</th>
                      <th>Quantidade</th>
                      <th>Valor</th>
                      <th>Número Pagamento</th>
                      <th>Id tipo despesa</th>
                      <th colSpan={1}></th>
                    </tr>
                  </thead>
                ) : (
                  <p>Nenhum Registro Encontrado</p>
                )}
                <tbody>
                  {AggregateUnits?.content?.map((units, index) => (
                    <tr key={index}>
                      <td>{units.unidadeId}</td>
                      <td>{units.materialEquipamentoId}</td>
                      <td>{units.linhaServicoId}</td>
                      <td>{units.quantidade}</td>
                      <td>{units.valor}</td>
                      <td>{units.numPagamento}</td>
                      <td>{units.tipoDespesaId}</td>
                      <td>
                        <div className="AggregateUnits-main-card-section-icons">
                          <p
                            data-tip="Editar Registro"
                            onClick={() => handleEditModal(units)}
                          >
                            <BsPencilSquare color="#D8A511" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                          <p
                            data-tip="Excluir Registro"
                            onClick={() => {
                              setAggregateToDelete(units);
                              setShowModalExclude(true);
                            }}
                          >
                            <img src={iconOcultar} alt="" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <LinearProgress />
            )}
          </section>
          <footer>
            <Pagination
              count={AggregateUnits.totalPages}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginate}
            />
          </footer>
        </div>
      </main>
    </div>
  );
}
