import { RxCross1 } from "react-icons/rx";
import { CustomController } from "../../../../../../utils/FormController/FormController";
import { ContentProps } from "../useAccountPlan";
import "./styles.scss";
import { useModalAccountPlan } from "./useModalAggregateUnits";

interface ModalRegisterComponentProps {
  handleCloseModal: () => void;
  handleGetAccountPlan: () => void;
  selectedAccountPlan: ContentProps;
}
export function ModalAccountPlan({
  handleCloseModal,
  handleGetAccountPlan,
  selectedAccountPlan,
}: ModalRegisterComponentProps) {
  const { handleSubmitForm, methods, deparaTipoDocumento } =
    useModalAccountPlan({
      handleCloseModal: handleCloseModal,
      handleGetAccountPlan: handleGetAccountPlan,
      selectedAccountPlan: selectedAccountPlan,
    });
  return (
    <div className="modal-register-container-account">
      <header>
        <RxCross1 onClick={() => handleCloseModal()} />
      </header>
      <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <main>
          <div className="input-group-small">
            <p>Número Pagamento</p>

            <input
              type="text"
              {...methods.register("numPagamento")}
              autoComplete="off"
              disabled
            />
          </div>{" "}
          <div className="input-group-small">
            <p>Id tipo despesa </p>
            <select {...methods.register("tipoDespesaId")}>
              <option hidden value={selectedAccountPlan.tipoDespesaId || ""}>
                {selectedAccountPlan.tipoDespesaId !== null
                  ? deparaTipoDocumento.find(
                    (tipo) =>
                      tipo.cdDeparaSicap === selectedAccountPlan.tipoDespesaId
                  )?.dsDeparaSicap || "Selecione"
                  : "Selecione"}
              </option>
              {deparaTipoDocumento.map((tipoDespesa) => (
                <option
                  key={tipoDespesa.cdDepara}
                  value={tipoDespesa.cdDeparaSicap}
                >
                  {tipoDespesa.dsDeparaSicap}
                </option>
              ))}
            </select>
            {/* <CustomController
              name="tipoDespesaId"
              type="text"
              control={methods.control}
              defaultValue={selectedAccountPlan.tipoDespesaId || null}
            /> */}
          </div>{" "}
          <div className="input-group-small">
            <p>Valor</p>
            <CustomController
              name="valor"
              type="number"
              control={methods.control}
              defaultValue={selectedAccountPlan.valor || null}
              hasStep
            />
          </div>
        </main>
        <footer>
          <button type="submit" disabled={methods.formState.isSubmitting}>
            Confirmar
          </button>

          <button onClick={() => handleCloseModal()} type="button">
            Cancelar
          </button>
        </footer>
      </form>
    </div>
  );
}
