import {
  Checkbox,
  CircularProgress,
  Dialog,
  LinearProgress,
  Pagination,
} from "@mui/material";
import Button from "@mui/material/Button";
import { BsPencilSquare } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { VscDebugRestart } from "react-icons/vsc";
import ReactTooltip from "react-tooltip";
import iconOcultar from "../../../../assets/iconOcultar.svg";
import { Header } from "../../../Header";
import { ConfirmExclude } from "../ConfirmExclude";
import { ModalImport } from "./ModalImport";
import { ModalPositionSalary } from "./ModalPositionSalary";
import "./styles.scss";
import { ContentProps, usePositionSalary } from "./usePositionSalary";

export default function PositionSalary() {
  const {
    positionSalary,
    handlePaginate,
    selectedPositionSalary,
    handleGetPositionSalary,
    setSelectedPositionSalary,
    handleEditModal,
    modalState,
    setModalState,
    handleDeletePositionSalary,
    isLoading,
    handleImportRegisters,
    selectedImportRegisters,
    handleSubmitImport,
    page,
    allSelected,
    setAllSelected,
    setFilterRegisters,
    filterRegisters,
    showModalExclude,
    setShowModalExclude,
    positionToDelete,
    setPositionToDelete,
    showModalImport,
    setShowModalImport,
    installmentList,
    selectedInstallment,
    setSelectedInstallment,
    setPositionSalary,
  } = usePositionSalary();

  return (
    <div className="PositionSalary-container">
      <Header message={"Dashboard/Cargos e Salários"} showAtualizacao={true} />
      <main>
        <div className="PositionSalary-main-card">
          <h2>Cargos e Salários</h2>

          <header className="PositionSalary-main-card-header">
            <aside className="PositionSalary-main-card-filters">
              <select
                onChange={(e) => {
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    tpStatus: e.target.value,
                  }));
                  setSelectedInstallment(Number(e.target.value));
                }}
              >
                <option hidden>Status</option>
                <option value="">Nenhum</option>
                <option value="A">Aguardando</option>
                <option value="I">Importado</option>
                <option value="T">Transferido</option>
                <option value="E">Erro</option>
              </select>
              <input
                type="text"
                placeholder="Cargo Descrição"
                onBlur={(e) =>
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    descricaoCargo: e.target.value,
                  }))
                }
              />

              <button
                type="button"
                className="filter-button"
                onClick={() =>
                  handleGetPositionSalary(
                    0,
                    filterRegisters.descricaoCargo,
                    filterRegisters.tpStatus
                  )
                }
              >
                Filtrar
              </button>
            </aside>
            <div className="header-buttons">
              <aside>
                <Button
                  variant="contained"
                  onClick={() => setShowModalImport(true)}
                >
                  Importar Totvs <FaPlus />
                </Button>
              </aside>
              <aside>
                <Button variant="outlined" onClick={() => setModalState(true)}>
                  Novo Registro <FaPlus />
                </Button>
              </aside>
              <aside>
                <Button
                  variant="contained"
                  onClick={() => handleSubmitImport()}
                  disabled={selectedImportRegisters.length === 0}
                >
                  Enviar Sicap <FaPlus />
                </Button>
                <Checkbox
                  color="primary"
                  size="small"
                  title="Todos Registros"
                  checked={allSelected}
                  onChange={() => setAllSelected(!allSelected)}
                />
                Todos Registros
              </aside>
              <div className="header-buttons-restart">
                {isLoading ? (
                  <CircularProgress
                    style={{
                      color: "white",
                      animation: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                ) : (
                  <VscDebugRestart
                    data-tip="Atualizar"
                    onClick={() => handleGetPositionSalary(page)}
                  />
                )}
              </div>
            </div>
          </header>
          <section className="PositionSalary-main-card-section">
            <Dialog
              open={modalState}
              onClose={() => (
                setModalState(false),
                setSelectedPositionSalary({} as ContentProps)
              )}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalPositionSalary
                prestacaoContaId={selectedInstallment}
                handleCloseModal={() => (
                  setModalState(false),
                  setSelectedPositionSalary({} as ContentProps)
                )}
                selectedPositionSalary={selectedPositionSalary}
                handleGetPositionSalary={() => handleGetPositionSalary(page)}
              />
            </Dialog>
            <Dialog
              open={showModalExclude}
              onClose={() => setShowModalExclude(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ConfirmExclude
                handleCloseModal={() => setShowModalExclude(false)}
                handleSubmit={() =>
                  handleDeletePositionSalary(positionToDelete.cdCargoSalario)
                }
              />
            </Dialog>
            <Dialog
              open={showModalImport}
              onClose={() => setShowModalImport(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalImport
                handleCloseModal={() => setShowModalImport(false)}
                installmentList={installmentList}
                setNewList={setPositionSalary}
              />
            </Dialog>
            {!isLoading ? (
              <table>
                <thead>
                  <tr>
                    <th colSpan={1}></th>
                    <th>Status</th>
                    <th>Descrição Cargo</th>
                    <th>Código CBO</th>
                    <th>Comentários</th>
                    <th>Convenção Coletiva</th>
                    <th>Nível</th>
                    <th>Número Horas Semanais</th>
                    <th>Sindicato</th>
                    <th>Valor Salario</th>
                    <th colSpan={1}></th>
                  </tr>
                </thead>
                <tbody>
                  {positionSalary?.content?.map((salary, index) => (
                    <tr key={index}>
                      <td>
                        {(salary.tpStatus === "A" ||
                          salary.tpStatus === "E") && (
                            <Checkbox
                              color="primary"
                              size="small"
                              onChange={() => {
                                handleImportRegisters(salary.cdCargoSalario);
                              }}
                              checked={selectedImportRegisters.some(
                                (item) => item === salary.cdCargoSalario
                              )}
                            />
                          )}
                      </td>
                      <td>
                        {salary.tpStatus === "A" && (
                          <div className="circle-status">
                            <span
                              data-tip="Aguardando"
                              className="circle-aguardando"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {salary.tpStatus === "I" && (
                          <div className="circle-status">
                            <span
                              data-tip="Importado"
                              className="circle-importado"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {salary.tpStatus === "T" && (
                          <div className="circle-status">
                            <span
                              data-tip="Transferido"
                              className="circle-transferido"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {salary.tpStatus === "E" && (
                          <div className="circle-status">
                            <span
                              data-tip={
                                salary?.dsErro?.length < 100
                                  ? salary?.dsErro
                                  : salary?.dsErro?.substring(0, 100) + "..."
                              }
                              className="circle-error"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {salary.tpStatus === "F" && (
                          <div className="circle-status">
                            <span
                              data-tip="Finalizado"
                              className="circle-finalizado"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                      </td>
                      <td>{salary.descricaoCargo}</td>
                      <td>{salary.codigoCbo}</td>
                      <td>{salary.comentarios}</td>
                      <td>{salary.convencaoColetiva}</td>
                      <td>{salary.nivel}</td>
                      <td>{salary.numeroHorasSemanais}</td>
                      <td>{salary.sindicato}</td>
                      <td>{salary.valorSalario}</td>
                      <td>
                        <div className="PositionSalary-main-card-section-icons">
                          <p
                            data-tip="Editar Registro"
                            onClick={() => handleEditModal(salary)}
                          >
                            <BsPencilSquare color="#D8A511" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                          <p
                            data-tip="Excluir Registro"
                            onClick={() => (
                              setShowModalExclude(true),
                              setPositionToDelete(salary)
                            )}
                          >
                            <img src={iconOcultar} alt="" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <LinearProgress />
            )}
          </section>
          <footer>
            <Pagination
              count={positionSalary.totalPages}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginate}
            />
          </footer>
        </div>
      </main>
    </div>
  );
}
