import { WithheldTaxesSchemaProps } from "../../../../components/CustomScreens/totvs/FinancialRelease/WithheldTaxes/ModalWithheldTaxes/schema";
import { customScreenApi } from "../../../../services/api";

export const getWithheldTaxes = async (
  numPagamento: number,
  prestacaoContaId: number,
  page?: number
) => {
  const response = await customScreenApi.get(
    `/lancamentosFinanceiros/pagamentosTributosRetidos/doc/${numPagamento}`,
    {
      params: {
        size: 7,
        page: page || 0,
      },
      headers: {
        prestacaoContaId: prestacaoContaId,
      },
    }
  );
  return response;
};

export const postWithheldTaxes = async (data: WithheldTaxesSchemaProps) => {
  const response = await customScreenApi.post(
    "/lancamentosFinanceiros/pagamentosTributosRetidos",
    {
      ...data,
      numPagamento: data.numPagamento === "" ? null : Number(data.numPagamento),
      parceriaId: sessionStorage.getItem("parceriaId"),
    }
  );
  return response;
};

export const putWithheldTaxes = async (
  data: WithheldTaxesSchemaProps,
  cdPagTribRet: number
) => {
  const response = await customScreenApi.put(
    "/lancamentosFinanceiros/pagamentosTributosRetidos",
    {
      ...data,
      cdPagTribRet: cdPagTribRet,
      parceriaId: sessionStorage.getItem("parceriaId"),
    }
  );
  return response;
};

export const deleteWithheldTaxes = async (cdPagTribRet: number) => {
  const response = await customScreenApi.delete(
    `/lancamentosFinanceiros/pagamentosTributosRetidos/${cdPagTribRet}`
  );
  return response;
};
