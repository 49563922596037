import { UseFormReturn } from "react-hook-form";
import { CustomController } from "../../../../../../utils/FormController/FormController";
import { ContentProps } from "../../usePayroll";
import { useModalPayroll } from "../useModalPayroll";

interface GeneralFieldsProps {
  methods: UseFormReturn;
  selectedPayroll: ContentProps;
}
export function GeneralFields({
  selectedPayroll,
  methods,
}: GeneralFieldsProps) {
  const {
    deparaAutoDeclaracaoGenero,
    deparaAutoDeclaracaoRacial,
    deparaRegimeContratacao,
    deparaTipoJornada,
    deparaTipoFolha,
    deparaTipoColaborador,
    deparaTipoCoordenadoria,
    deparaTipoAtividade,
    handleGetNameByCPF,
  } = useModalPayroll({});
  return (
    <>
      <div className="input-group-normal">
        <p>Nome</p>
        <CustomController
          name="nome"
          type="text"
          control={methods.control}
          defaultValue={selectedPayroll.nome || null}
        />
      </div>
      <div className="input-group-small">
        <p>Nome social</p>
        <CustomController
          name="nomeSocial"
          type="text"
          control={methods.control}
          defaultValue={selectedPayroll.nomeSocial || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Cpf</p>
        <CustomController
          name="cpf"
          type="text"
          control={methods.control}
          defaultValue={selectedPayroll.cpf || null}
        />
      </div>

      <div className="input-group-small">
        <p>Data nascimento</p>
        <input
          type="date"
          autoComplete="off"
          {...methods.register("dataNascimento")}
        />
      </div>
      <div className="input-group-small">
        <p>Tipo Jornada</p>
        <select {...methods.register("tipoJornada")}>
          <option hidden>
            {selectedPayroll.tipoJornada !== null
              ? deparaTipoJornada.find(
                (tipo) =>
                  tipo.cdDeparaSicap === String(selectedPayroll.tipoJornada)
              )?.dsDeparaSicap || "Selecione"
              : "Selecione"}
          </option>
          {deparaTipoJornada.map((tipoOperacao) => (
            <option
              key={tipoOperacao.cdDepara}
              value={Number(tipoOperacao.cdDeparaSicap)}
            >
              {tipoOperacao.dsDeparaSicap}
            </option>
          ))}
        </select>
      </div>
      <div className="input-group-normal">
        <p>Autodeclaração gênero</p>
        <select
          {...methods.register("autoDeclaracaoGenero")}
          className={
            methods.formState.errors?.autoDeclaracaoGenero ? "error" : ""
          }
        >
          <option hidden>
            {selectedPayroll.autoDeclaracaoGenero !== null
              ? deparaAutoDeclaracaoGenero.find(
                (tipo) =>
                  tipo.cdDeparaSicap === selectedPayroll.autoDeclaracaoGenero
              )?.dsDeparaSicap || "Selecione"
              : "Selecione"}
          </option>
          {deparaAutoDeclaracaoGenero.map((tipoOperacao) => (
            <option
              key={tipoOperacao.cdDepara}
              value={tipoOperacao.cdDeparaSicap}
            >
              {tipoOperacao.dsDeparaSicap}
            </option>
          ))}
        </select>
      </div>
      <div className="input-group-small">
        <p>Tipo atividade</p>
        <select
          {...methods.register("tipoAtividade")}
          className={methods.formState.errors?.tipoAtividade ? "error" : ""}
        >
          <option hidden>
            {selectedPayroll.tipoAtividade !== null
              ? deparaTipoAtividade.find(
                (tipo) => tipo.cdDeparaSicap === selectedPayroll.tipoAtividade
              )?.dsDeparaSicap || "Selecione"
              : "Selecione"}
          </option>
          {deparaTipoAtividade.map((tipoOperacao) => (
            <option
              key={tipoOperacao.cdDepara}
              value={tipoOperacao.cdDeparaSicap}
            >
              {tipoOperacao.dsDeparaSicap}
            </option>
          ))}
        </select>
      </div>

      <div className="input-group-normal">
        <p>Autodeclaração racial</p>
        <select {...methods.register("autoDeclaracaoRacial")}>
          <option hidden>
            {selectedPayroll.autoDeclaracaoRacial !== null
              ? deparaAutoDeclaracaoRacial.find(
                (tipo) =>
                  tipo.cdDeparaSicap === selectedPayroll.autoDeclaracaoRacial
              )?.dsDeparaSicap || "Selecione"
              : "Selecione"}
          </option>
          {deparaAutoDeclaracaoRacial.map((tipoOperacao) => (
            <option
              key={tipoOperacao.cdDepara}
              value={tipoOperacao.cdDeparaSicap}
            >
              {tipoOperacao.dsDeparaSicap}
            </option>
          ))}
        </select>
      </div>

      <div className="input-group-small">
        <p>Tipo colaborador</p>
        <select
          {...methods.register("tipoColaborador")}
          className={methods.formState.errors?.tipoColaborador ? "error" : ""}
        >
          <option hidden>
            {selectedPayroll.tipoColaborador !== null
              ? deparaTipoColaborador.find(
                (tipo) =>
                  tipo.cdDeparaSicap === selectedPayroll.tipoColaborador
              )?.dsDeparaSicap || "Selecione"
              : "Selecione"}
          </option>
          {deparaTipoColaborador.map((tipoOperacao) => (
            <option
              key={tipoOperacao.cdDepara}
              value={tipoOperacao.cdDeparaSicap}
            >
              {tipoOperacao.dsDeparaSicap}
            </option>
          ))}
        </select>
      </div>
      <div className="input-group-normal">
        <p>Tipo coordenadoria</p>
        <select
          {...methods.register("tipoCoordenadoria")}
          className={methods.formState.errors?.tipoCoordenadoria ? "error" : ""}
        >
          <option hidden>
            {selectedPayroll.tipoCoordenadoria !== null
              ? deparaTipoCoordenadoria.find(
                (tipo) =>
                  tipo.cdDeparaSicap === selectedPayroll.tipoCoordenadoria
              )?.dsDeparaSicap || "Selecione"
              : "Selecione"}
          </option>
          {deparaTipoCoordenadoria.map((tipoOperacao) => (
            <option
              key={tipoOperacao.cdDepara}
              value={tipoOperacao.cdDeparaSicap}
            >
              {tipoOperacao.dsDeparaSicap}
            </option>
          ))}
        </select>
      </div>
      <div className="input-group-small">
        <p>Tipo folha</p>
        <select {...methods.register("tipoFolha")}>
          <option hidden>
            {selectedPayroll.tipoFolha !== null
              ? deparaTipoFolha.find(
                (tipo) => tipo.cdDeparaSicap === selectedPayroll.tipoFolha
              )?.dsDeparaSicap || "Selecione"
              : "Selecione"}
          </option>
          {deparaTipoFolha.map((tipoOperacao) => (
            <option
              key={tipoOperacao.cdDepara}
              value={tipoOperacao.cdDeparaSicap}
            >
              {tipoOperacao.dsDeparaSicap}
            </option>
          ))}
        </select>
      </div>
      <div className="input-group-small">
        <p>Jornada</p>
        <CustomController
          name="jornada"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.jornada !== null &&
              selectedPayroll.jornada !== undefined
              ? selectedPayroll.jornada
              : null
          }
        />
      </div>
      <div className="input-group-normal">
        <p>Regime contratação</p>
        <select
          {...methods.register("regimeContratacao")}
          className={methods.formState.errors?.regimeContratacao ? "error" : ""}
        >
          <option hidden>
            {selectedPayroll.regimeContratacao !== null
              ? deparaRegimeContratacao.find(
                (tipo) =>
                  tipo.cdDeparaSicap === selectedPayroll.regimeContratacao
              )?.dsDeparaSicap || "Selecione"
              : "Selecione"}
          </option>
          {deparaRegimeContratacao.map((tipoOperacao) => (
            <option
              key={tipoOperacao.cdDepara}
              value={tipoOperacao.cdDeparaSicap}
            >
              {tipoOperacao.dsDeparaSicap}
            </option>
          ))}
        </select>
      </div>
      <div className="input-group-small">
        <p>Data admissão</p>
        <input
          type="date"
          autoComplete="off"
          {...methods.register("dataAdmissao")}
        />
      </div>
      <div className="input-group-normal">
        <p>Numero Conselho Classe</p>
        <CustomController
          name="numConselhoClasse"
          type="text"
          control={methods.control}
          defaultValue={selectedPayroll.numConselhoClasse || null}
        />
      </div>
      <div className="input-group-small">
        <p>Plantão Mensal</p>
        <CustomController
          name="plantaoMensal"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.plantaoMensal !== null &&
              selectedPayroll.plantaoMensal !== undefined
              ? selectedPayroll.plantaoMensal
              : null
          }
        />
      </div>

      <div className="input-group-small">
        <p>Cns profissional</p>
        <CustomController
          name="cnsProfissional"
          type="text"
          control={methods.control}
          defaultValue={selectedPayroll.cnsProfissional || null}
        />
      </div>

      <div className="input-group-small">
        <p>Código CBO</p>
        {/* <CustomController
          name="codigoCbo"
          type="text"
          control={methods.control}
          defaultValue={selectedPayroll.codigoCbo || null}
        /> */}
        <input
          type="text"
          defaultValue={selectedPayroll.codigoCbo || null}
          {...methods.register("codigoCbo")}
          className={methods.formState.errors?.codigoCbo ? "error" : ""}
        />
      </div>
      <div className="input-group-normal">
        <p>Data desligamento</p>
        <input
          type="date"
          autoComplete="off"
          {...methods.register("dataDesligamento")}
        />
      </div>
    </>
  );
}
