import { z } from "zod";

export const BankAccountSchema = z.object({
  id: z.string().nullable(),
  contaContabil: z.string().nullable(),
  dsContaBancaria: z.string().nullable(),
  dsAgencia: z.string().nullable(),
  dsAgenciaDigito: z.string().nullable(),
  numeroContaBancaria: z.string().nullable(),
  contaDigito: z.string().nullable(),
  contaAplicacao: z.string().nullable(),
  idFonteRecurso: z.string().nullable(),
  dsFonteRecurso: z.string().nullable(),
  idTipoRecurso: z.string().nullable(),
  dsTipoRecurso: z.string().nullable(),
  saldoAnterior: z.string().nullable(),
  prestacaoContaId: z.number().optional(),
  parceriaId: z.number().nullable().optional(),
  contaContabilPai: z.string().nullable(),
});

export type BankAccountSchemaProps = z.infer<typeof BankAccountSchema>;
