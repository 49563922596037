import { UseFormReturn } from "react-hook-form";
import { CustomController } from "../../../../../../utils/FormController/FormController";
import { ContentProps } from "../../usePayroll";

interface DiscountsFieldsProps {
  methods: UseFormReturn;
  selectedPayroll: ContentProps;
}
export function DiscountsFields({
  methods,
  selectedPayroll,
}: DiscountsFieldsProps) {
  return (
    <>
      <div className="input-group-small">
        <p>Desconto Auxílios</p>
        <CustomController
          name="descontosAuxilios"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosAuxilios !== null &&
              selectedPayroll.descontosAuxilios !== undefined
              ? selectedPayroll.descontosAuxilios
              : null
          }
        //defaultValue={selectedPayroll.descontosAuxilios || null}
        />
      </div>
      <div className="input-group-small">
        <p>Desconto DSR</p>
        <CustomController
          name="descontosDsr"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosDsr !== null &&
              selectedPayroll.descontosDsr !== undefined
              ? selectedPayroll.descontosDsr
              : null
          }
        //defaultValue={selectedPayroll.descontosDsr || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos Antecipação Folha</p>
        <CustomController
          name="descontosAntecipacaoFolha"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosAntecipacaoFolha !== null &&
              selectedPayroll.descontosAntecipacaoFolha !== undefined
              ? selectedPayroll.descontosAntecipacaoFolha
              : null
          }
        ///defaultValue={selectedPayroll.descontosAntecipacaoFolha || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos Pagamento Indevido</p>
        <CustomController
          name="descontosPagamentoIndevido"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosPagamentoIndevido !== null &&
              selectedPayroll.descontosPagamentoIndevido !== undefined
              ? selectedPayroll.descontosPagamentoIndevido
              : null
          }
        //defaultValue={selectedPayroll.descontosPagamentoIndevido || null}
        />
      </div>
      <div className="input-group-small">
        <p>Descontos Estorno</p>
        <CustomController
          name="descontosEstorno"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosEstorno !== null &&
              selectedPayroll.descontosEstorno !== undefined
              ? selectedPayroll.descontosEstorno
              : null
          }
        //defaultValue={selectedPayroll.descontosEstorno || null}
        />
      </div>
      <div className="input-group-small">
        <p>Descontos Faltas</p>
        <CustomController
          name="descontosFaltas"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosFaltas !== null &&
              selectedPayroll.descontosFaltas !== undefined
              ? selectedPayroll.descontosFaltas
              : null
          }
        //defaultValue={selectedPayroll.descontosFaltas || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos Não Dedutíveis</p>
        <CustomController
          name="descontosNaoDedutiveis"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosNaoDedutiveis !== null &&
              selectedPayroll.descontosNaoDedutiveis !== undefined
              ? selectedPayroll.descontosNaoDedutiveis
              : null
          }
        //defaultValue={selectedPayroll.descontosNaoDedutiveis || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos Plano Saúde</p>
        <CustomController
          name="descontosPlanoSaude"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosPlanoSaude !== null &&
              selectedPayroll.descontosPlanoSaude !== undefined
              ? selectedPayroll.descontosPlanoSaude
              : null
          }
        //defaultValue={selectedPayroll.descontosPlanoSaude || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Vale Transporte Individualizado</p>
        <CustomController
          name="desValetransIndivi"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.desValetransIndivi !== null &&
              selectedPayroll.desValetransIndivi !== undefined
              ? selectedPayroll.desValetransIndivi
              : null
          }
        // defaultValue={selectedPayroll.desValetransIndivi || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos Arredondamento</p>
        <CustomController
          name="descontosArredondamento"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosArredondamento !== null &&
              selectedPayroll.descontosArredondamento !== undefined
              ? selectedPayroll.descontosArredondamento
              : null
          }
        //defaultValue={selectedPayroll.descontosArredondamento || null}
        />
      </div>
      {/* <div className="input-group-normal">
        <p>Desconto Multa Art. 480</p>
        <CustomController
          name="descMulta480"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descMulta480 !== null &&
            selectedPayroll.descMulta480 !== undefined
              ? selectedPayroll.descMulta480
              : null
          }
        />
      </div> */}
      <div className="input-group-normal">
        <p>Desconto do Aviso Prévio</p>
        <CustomController
          name="desAvisoPrevio"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.desAvisoPrevio !== null &&
              selectedPayroll.desAvisoPrevio !== undefined
              ? selectedPayroll.desAvisoPrevio
              : null
          }
        //defaultValue={selectedPayroll.desAvisoPrevio || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos 1ª Parc 13º Salário</p>
        <CustomController
          name="desPrimeParcelaDecTer"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.desPrimeParcelaDecTer !== null &&
              selectedPayroll.desPrimeParcelaDecTer !== undefined
              ? selectedPayroll.desPrimeParcelaDecTer
              : null
          }
        //  defaultValue={selectedPayroll.desPrimeParcelaDecTer || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos Adiantamento 13º Salário</p>
        <CustomController
          name="desAdianDecimoTerceiro"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.desAdianDecimoTerceiro !== null &&
              selectedPayroll.desAdianDecimoTerceiro !== undefined
              ? selectedPayroll.desAdianDecimoTerceiro
              : null
          }
        // defaultValue={selectedPayroll.desAdianDecimoTerceiro || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Empréstimos Consignados</p>
        <CustomController
          name="desEmprestimosConsignados"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.desEmprestimosConsignados !== null &&
              selectedPayroll.desEmprestimosConsignados !== undefined
              ? selectedPayroll.desEmprestimosConsignados
              : null
          }
        // defaultValue={selectedPayroll.desEmprestimosConsignados || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Multa Rescisória</p>
        <CustomController
          name="descontosMultaRescisoria"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosMultaRescisoria !== null &&
              selectedPayroll.descontosMultaRescisoria !== undefined
              ? selectedPayroll.descontosMultaRescisoria
              : null
          }
        // defaultValue={selectedPayroll.descontosMultaRescisoria || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Contribuição Sindical Mensal</p>
        <CustomController
          name="desContriSindicalMensal"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.desContriSindicalMensal !== null &&
              selectedPayroll.desContriSindicalMensal !== undefined
              ? selectedPayroll.desContriSindicalMensal
              : null
          }
        // defaultValue={selectedPayroll.desContriSindicalMensal || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Contribuição Sindical Anual</p>
        <CustomController
          name="desContriSindicalAnual"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.desContriSindicalAnual !== null &&
              selectedPayroll.desContriSindicalAnual !== undefined
              ? selectedPayroll.desContriSindicalAnual
              : null
          }
        // defaultValue={selectedPayroll.desContriSindicalAnual || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Desconto Adiantamento Salário</p>
        <CustomController
          name="desAdiantamentoSalario"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.desAdiantamentoSalario !== null &&
              selectedPayroll.desAdiantamentoSalario !== undefined
              ? selectedPayroll.desAdiantamentoSalario
              : null
          }
        //   defaultValue={selectedPayroll.desAdiantamentoSalario || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos de Convênios</p>
        <CustomController
          name="descontosConvenios"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosConvenios !== null &&
              selectedPayroll.descontosConvenios !== undefined
              ? selectedPayroll.descontosConvenios
              : null
          }
        // defaultValue={selectedPayroll.descontosConvenios || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Seguro de Vida</p>
        <CustomController
          name="descontosSeguroVida"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosSeguroVida !== null &&
              selectedPayroll.descontosSeguroVida !== undefined
              ? selectedPayroll.descontosSeguroVida
              : null
          }
        // defaultValue={selectedPayroll.descontosSeguroVida || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Pensão Alimentícia</p>
        <CustomController
          name="pensaoAlimenticia"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.pensaoAlimenticia !== null &&
              selectedPayroll.pensaoAlimenticia !== undefined
              ? selectedPayroll.pensaoAlimenticia
              : null
          }
        //   defaultValue={selectedPayroll.pensaoAlimenticia || null}
        />
      </div>

      <div className="input-group-normal">
        <p>Arredondamento Saldo Desconto</p>
        <CustomController
          name="desArredonSaldoDesconto"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.desArredonSaldoDesconto !== null &&
              selectedPayroll.desArredonSaldoDesconto !== undefined
              ? selectedPayroll.desArredonSaldoDesconto
              : null
          }
        //  defaultValue={selectedPayroll.desArredonSaldoDesconto || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos Multa Res Antecipado</p>
        <CustomController
          name="desMultaResAntecipado"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.desMultaResAntecipado !== null &&
              selectedPayroll.desMultaResAntecipado !== undefined
              ? selectedPayroll.desMultaResAntecipado
              : null
          }
        //  defaultValue={selectedPayroll.desMultaResAntecipado || null}
        />
      </div>

      <div className="input-group-small">
        <p>Descontos Outros</p>
        <CustomController
          name="descontosOutros"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosOutros !== null &&
              selectedPayroll.descontosOutros !== undefined
              ? selectedPayroll.descontosOutros
              : null
          }
        //  defaultValue={selectedPayroll.descontosOutros || null}
        />
      </div>
      <div className="input-group-small">
        <p>Descontos INSS</p>
        <CustomController
          name="descontosInss"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosInss !== null &&
              selectedPayroll.descontosInss !== undefined
              ? selectedPayroll.descontosInss
              : null
          }
        // defaultValue={selectedPayroll.descontosInss || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos INSS 13º </p>
        <CustomController
          name="descontosInssDecimoTerceiro"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosInssDecimoTerceiro !== null &&
              selectedPayroll.descontosInssDecimoTerceiro !== undefined
              ? selectedPayroll.descontosInssDecimoTerceiro
              : null
          }
        // defaultValue={selectedPayroll.descontosInssDecimoTerceiro || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos INSS Férias</p>
        <CustomController
          name="descontosInssFerias"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosInssFerias !== null &&
              selectedPayroll.descontosInssFerias !== undefined
              ? selectedPayroll.descontosInssFerias
              : null
          }
        //defaultValue={selectedPayroll.descontosInssFerias || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos INSS Rescisão</p>
        <CustomController
          name="descontosInssRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosInssRescisao !== null &&
              selectedPayroll.descontosInssRescisao !== undefined
              ? selectedPayroll.descontosInssRescisao
              : null
          }
        //defaultValue={selectedPayroll.descontosInssRescisao || null}
        />
      </div>

      <div className="input-group-normal">
        <p>Descontos IRRF 13º</p>
        <CustomController
          name="descontosIrrfDecimoTerceiro"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosIrrfDecimoTerceiro !== null &&
              selectedPayroll.descontosIrrfDecimoTerceiro !== undefined
              ? selectedPayroll.descontosIrrfDecimoTerceiro
              : null
          }
        //defaultValue={selectedPayroll.descontosIrrfDecimoTerceiro || null}
        />
      </div>
      <div className="input-group-small">
        <p>Descontos IRRF</p>
        <CustomController
          name="descontosIrrf"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosIrrf !== null &&
              selectedPayroll.descontosIrrf !== undefined
              ? selectedPayroll.descontosIrrf
              : null
          }
        // defaultValue={selectedPayroll.descontosIrrf || null}
        />
      </div>
      <div className="input-group-small">
        <p>Atestado 15 Dias</p>
        <CustomController
          name="atestadoAteQuinzeDias"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.atestadoAteQuinzeDias !== null &&
              selectedPayroll.atestadoAteQuinzeDias !== undefined
              ? selectedPayroll.atestadoAteQuinzeDias
              : null
          }
        //defaultValue={selectedPayroll.atestadoAteQuinzeDias || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos IRRF Férias</p>
        <CustomController
          name="descontosIrrfFerias"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosIrrfFerias !== null &&
              selectedPayroll.descontosIrrfFerias !== undefined
              ? selectedPayroll.descontosIrrfFerias
              : null
          }
        // defaultValue={selectedPayroll.descontosIrrfFerias || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Descontos IRRF Rescisão</p>
        <CustomController
          name="descontosIrrfRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosIrrfRescisao !== null &&
              selectedPayroll.descontosIrrfRescisao !== undefined
              ? selectedPayroll.descontosIrrfRescisao
              : null
          }
        //defaultValue={selectedPayroll.descontosIrrfRescisao || null}
        />
      </div>

      {/* <div className="input-group-normal">
        <p>Descontos pagamentos Indevidos</p>
        <CustomController
          name="descontosPagamentosIndevidos"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontosPagamentosIndevidos !== null &&
            selectedPayroll.descontosPagamentosIndevidos !== undefined
              ? selectedPayroll.descontosPagamentosIndevidos
              : null
          }
        />
      </div> */}
      <div className="input-group-normal">
        <p>FGTS Rescisão Antecipada</p>
        <CustomController
          name="fgtsRescisaoAntecipada"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.fgtsRescisaoAntecipada !== null &&
              selectedPayroll.fgtsRescisaoAntecipada !== undefined
              ? selectedPayroll.fgtsRescisaoAntecipada
              : null
          }
        //  defaultValue={selectedPayroll.fgtsRescisaoAntecipada || null}
        />
      </div>
      {/* <div className="input-group-normal">
        <p>Horas Trabalhadas Mensal</p>
        <CustomController
          name="horasTrabalhadasMensal"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.horasTrabalhadasMensal !== null &&
            selectedPayroll.horasTrabalhadasMensal !== undefined
              ? selectedPayroll.horasTrabalhadasMensal
              : null
          }
        />
      </div> */}
      {/* <div className="input-group-small">
        <p>Id Linha Serviço</p>
        <CustomController
          name="linhaServicoId"
          type="text"
          control={methods.control}
          defaultValue={selectedPayroll.linhaServicoId || null}
        />
      </div> */}
      {/* <div className="input-group-small">
        <p>Id Parceria</p>
        <CustomController
          name="parceriaId"
          type="number"
          control={methods.control}
            defaultValue={
            selectedPayroll.adiantamentoSalario !== null &&
            selectedPayroll.adiantamentoSalario !== undefined
              ? selectedPayroll.adiantamentoSalario
              : null
          }
          defaultValue={selectedPayroll.parceriaId || null}
        />
      </div> */}
      {/* <div className="input-group-normal">
        <p>Id Unidade</p>
        <CustomController
          name="unidadeId"
          type="text"
          control={methods.control}
          defaultValue={selectedPayroll.unidadeId || null}
        />
      </div> */}
    </>
  );
}
