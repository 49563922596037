import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTooltip from "react-tooltip";
import logo from "../../assets/LogoArgo.svg";
import logoDti from "../../assets/LogoDataIntegra-SimbColLetrasBrancaLateralPNG.png";
import { AuthContext } from "../../contexts/Auth/ProviderContext";
import { GlobalContext } from "../../contexts/GlobalContext";
import "./style.css";

export const UserLogin = () => {
  const auth = useContext(AuthContext);
  const { handleGetChartData } = useContext(GlobalContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const login = async (event: any) => {
    if (event.key === "Enter" || event.type === "click") {
      if (email && password) {
        const isLogged = await auth.UserLogin(email, password);
        if (isLogged === true) {
          handleGetChartData();
          navigate("/");
        }
      }
    }
  };

  const recupera = () => {
    navigate("/recuperaSenha");
  };

  return (
    <>
      <ToastContainer />
      <div className="painel">
        <div className="dataintegra">
          <img src={logo} alt="DataIntegra" className="imgDTI" />
          <ReactTooltip place="right" type="light" effect="solid" />
          {/* <h1>Faça login no dashboard</h1> */}
        </div>
        <div className="container">
          <div className="container-login">
            <div className="wrap-login">
              <div className="wrap-input">
                <input
                  className={email !== "" ? "has-val input" : "input"}
                  id="user"
                  type="text"
                  name="user"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />

                <span className="focus-input" data-placeholder="Login"></span>
              </div>

              <div className="wrap-input">
                <input
                  className={password !== "" ? "has-val input" : "input"}
                  id="password"
                  type="password"
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Senha"
                />
                <span className="focus-input" data-placeholder=""></span>
              </div>
              <button className="esqueciSenha" onClick={recupera}>
                Esqueci minha senha
              </button>
              <div className="container-login-form-btn">
                <button className="login-form-btn" onClick={login}>
                  Login
                </button>
              </div>
              <div className="dti-login-image">
                <img src={logoDti} className="img-dti" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
