import dayjs from "dayjs";
import { useEffect } from "react";
import { RxCross1 } from "react-icons/rx";
import {
  InstallmentListProps,
  PositionSalaryProps,
} from "../usePositionSalary";
import "./styles.scss";
import { useModalImport } from "./useModalImport";
interface ModalImportProps {
  handleCloseModal: () => void;
  installmentList?: InstallmentListProps[];
  setNewList: React.Dispatch<React.SetStateAction<PositionSalaryProps>>;
}
export function ModalImport({
  handleCloseModal,
  setNewList,
  installmentList,
}: ModalImportProps) {
  const { setImportConfig, handleImport } = useModalImport({
    handleCloseModal: handleCloseModal,
    setNewList: setNewList,
    installmentList: installmentList,
  });

  useEffect(() => {
    setImportConfig((prevState) => {
      return {
        ...prevState,
        prestacaoContaId: installmentList[0].prestacaoContaId,
      };
    });
  }, []);

  return (
    <div className="modal-import-container">
      <header>
        <RxCross1 onClick={() => handleCloseModal()} />
      </header>
      <main>
        <div className="modal-import-input-card">
          <p>Data Inicial</p>
          <input
            type="date"
            disabled
            onBlur={(e) =>
              setImportConfig((prevState) => {
                return {
                  ...prevState,
                  dataInicial: dayjs(e.target.value).format("YYYY-MM-DD"),
                };
              })
            }
          />
        </div>
        <div className="modal-import-input-card">
          <p>Prestação Conta</p>
          <select
            onChange={(e) =>
              setImportConfig((prevState) => {
                return {
                  ...prevState,
                  prestacaoContaId: Number(e.target.value),
                };
              })
            }
          >
            {installmentList.map((installment) => (
              <option
                key={installment.prestacaoContaId}
                value={installment.prestacaoContaId}
              >
                {installment.vigencia}
              </option>
            ))}
          </select>
        </div>
        <div className="modal-import-input-card">
          <p>Data Final</p>
          <input
            type="date"
            disabled
            onBlur={(e) =>
              setImportConfig((prevState) => {
                return {
                  ...prevState,
                  dataFinal: dayjs(e.target.value).format("YYYY-MM-DD"),
                };
              })
            }
          />
        </div>
      </main>
      <footer>
        <button onClick={() => handleImport()}>Confirmar</button>
        <button onClick={() => handleCloseModal()}>Cancelar</button>
      </footer>
    </div>
  );
}
