import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteWithheldTaxes,
  getWithheldTaxes,
} from "../../../../../Controllers/CustomScreens/FinancialRelease/WithheldTaxes/WithheldTaxesController";

interface WithheldTaxesProps {
  content: ContentProps[];
  totalPages: number;
}
export interface ContentProps {
  cdPagTribRet: number;
  fornecedorId: string;
  fornecedorcnpjcpf: string;
  linhaServicoId: string;
  notaFiscalId: string;
  numPagamento: number;
  tributoId: string;
  unidadeId: string;
  valorLinhaServico: number;
  valorUnidade: number;
}
export const useWithheldTaxes = () => {
  const { numPagamento, prestacaoContaId } = useParams();

  const [modalState, setModalState] = useState<boolean>(false);

  const [WithheldTaxes, setWithheldTaxes] = useState<WithheldTaxesProps>(
    {} as WithheldTaxesProps
  );
  const [selectedWithheldTaxes, setSelectedWithheldTaxes] =
    useState<ContentProps>({} as ContentProps);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showModalExclude, setShowModalExclude] = useState<boolean>(false);
  const [withheldToDelete, setWithheldToDelete] = useState(null);
  async function handleGetWithheldTaxes(page?: number) {
    setIsLoading(true);
    try {
      const { data } = await getWithheldTaxes(
        Number(numPagamento),
        Number(prestacaoContaId),
        page
      );
      setWithheldTaxes(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  function handleEditModal(taxes: ContentProps) {
    setSelectedWithheldTaxes(taxes);
    setModalState(true);
  }

  async function handleDeleteWithheldTaxes(cdPagTribRet: number) {
    try {
      await deleteWithheldTaxes(cdPagTribRet);
      handleGetWithheldTaxes();
      setWithheldToDelete(null);
      setShowModalExclude(false);
      toast.success("Conta deletada com sucesso");
    } catch (error) {
      toast.error("Erro ao deletar");
    }
  }
  useEffect(() => {
    handleGetWithheldTaxes();
  }, []);

  async function handlePaginate(
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    handleGetWithheldTaxes(value - 1);
  }

  return {
    handleGetWithheldTaxes,
    setModalState,
    modalState,
    WithheldTaxes,
    handlePaginate,
    selectedWithheldTaxes,
    setSelectedWithheldTaxes,
    handleEditModal,
    handleDeleteWithheldTaxes,
    isLoading,
    withheldToDelete,
    setWithheldToDelete,
    showModalExclude,
    setShowModalExclude,
  };
};
