import "./styles.scss";
interface ConfirmExcludeProps {
  handleSubmit: () => void;
  handleCloseModal: () => void;
}
export function ConfirmExclude({
  handleCloseModal,
  handleSubmit,
}: ConfirmExcludeProps) {
  return (
    <div className="confirmExclude-container">
      <h1>Você confirma a exclusão deste item?</h1>
      <footer>
        <button onClick={() => handleCloseModal()}>Cancelar</button>
        <button onClick={() => handleSubmit()}>Confirmar</button>
      </footer>
    </div>
  );
}
