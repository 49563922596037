import { z } from "zod";

export const UpdateDeparaSchema = z.object({
  tp_depara: z.string().nullable().default("").nullable(),
  cd_multi_empresa: z.string().default("").nullable(),
  cd_depara_mv: z.string().min(1).default("").nullable(),
  ds_depara_mv: z.string().default("").nullable(),
  cd_depara_integra: z.string().min(1).default("").nullable(),
  ds_depara_integra: z.string().default("").nullable(),
  cd_sistema_integra: z.string().default("").nullable(),
  cd_dti_depara: z.number(),
});

export type UpdateDeparaSchemaProps = z.infer<typeof UpdateDeparaSchema>;
