import { RxCross1 } from "react-icons/rx";
import { ContentProps } from "../useBankAccount";
import "./styles.scss";
import { useModalBankAccount } from "./useModalBankAccount";

interface ModalRegisterComponentProps {
  handleCloseModal: () => void;
  tblDtiCdBanco?: number;
  handleGetBankAccount: () => void;
  selectedBankAccount: ContentProps;
  prestacaoContaId?: number;
}
export function ModalBankAccount({
  handleCloseModal,
  handleGetBankAccount,
  selectedBankAccount,
  prestacaoContaId,
}: ModalRegisterComponentProps) {
  const { handleSubmitForm, methods, deparaFonteRecurso, deparaTipoRecurso } =
    useModalBankAccount({
      handleCloseModal: handleCloseModal,
      handleGetBankAccount: handleGetBankAccount,
      selectedBankAccount: selectedBankAccount,
      prestacaoContaId: prestacaoContaId,
    });
  return (
    <div className="modal-register-container-bank">
      <header>
        <RxCross1 onClick={() => handleCloseModal()} />
      </header>
      <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <main>
          <div className="input-group-small">
            <p>Id</p>
            <input type="text" autoComplete="off" {...methods.register("id")} />
          </div>
          <div className="input-group-normal">
            <p>Conta Bancária</p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("dsContaBancaria")}
            />
          </div>
          <div className="input-group-small">
            <p>Conta contábil</p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("contaContabil")}
            />
          </div>
          <div className="input-group-small">
            <p>Agencia</p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("dsAgencia")}
            />
          </div>
          <div className="input-group-normal">
            <p>Tipo recurso</p>
            <select
              onChange={(e) => {
                const tipoRecurso = JSON.parse(e.target.value);
                methods.setValue("idTipoRecurso", tipoRecurso?.cdDeparaSicap);
                methods.setValue("dsTipoRecurso", tipoRecurso?.dsDeparaSicap);
              }}
            >
              <option hidden>
                {methods.getValues("dsTipoRecurso") !== ""
                  ? methods.getValues("dsTipoRecurso")
                  : "Selecione"}
              </option>
              {deparaTipoRecurso.map((tipoRecurso) => (
                <option
                  key={tipoRecurso.cdDepara}
                  value={JSON.stringify(tipoRecurso)}
                >
                  {tipoRecurso.dsDeparaSicap}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group-small">
            <p>Agencia digito</p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("dsAgenciaDigito")}
            />
          </div>
          <div className="input-group-small">
            <p>Numero da conta </p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("numeroContaBancaria")}
            />
          </div>
          <div className="input-group-normal">
            <p>Saldo anterior</p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("saldoAnterior")}
            />
          </div>
          <div className="input-group-small">
            <p>Conta digito</p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("contaDigito")}
            />
          </div>
          <div className="input-group-small">
            <p>Conta aplicação</p>
            <select {...methods.register("contaAplicacao")}>
              <option hidden value="false">
                Selecione
              </option>
              <option value="true">SIM</option>
              <option value="false">NÃO</option>
            </select>
          </div>

          <div className="input-group-small">
            <p>Fonte recurso</p>
            <select
              onChange={(e) => {
                const fonteRecurso = JSON.parse(e.target.value);
                methods.setValue("idFonteRecurso", fonteRecurso?.cdDeparaSicap);
                methods.setValue("dsFonteRecurso", fonteRecurso?.dsDeparaSicap);
              }}
            >
              <option hidden>
                {methods.getValues("dsFonteRecurso") !== ""
                  ? methods.getValues("dsFonteRecurso")
                  : "Selecione"}
              </option>
              {deparaFonteRecurso.map((fonteRecurso) => (
                <option
                  key={fonteRecurso.cdDepara}
                  value={JSON.stringify(fonteRecurso)}
                >
                  {fonteRecurso.dsDeparaSicap}
                </option>
              ))}
            </select>
          </div>

          {/* <div className="input-group-small">
            <p>Id prestação conta</p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("prestacaoContaId")}
            />{" "}
          </div> */}
          <div className="input-group-small">
            <p>Conta Contábil Pai</p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("contaContabilPai")}
            />{" "}
          </div>
        </main>
        <footer>
          <button type="submit" disabled={methods.formState.isSubmitting}>
            Confirmar
          </button>

          <button type="button" onClick={() => handleCloseModal()}>
            Cancelar
          </button>
        </footer>
      </form>
    </div>
  );
}
