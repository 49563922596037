import { UseFormReturn } from "react-hook-form";
import { CustomController } from "../../../../../../utils/FormController/FormController";
import { ContentProps } from "../../usePayroll";
interface EarningsFieldsProps {
  methods: UseFormReturn;
  selectedPayroll: ContentProps;
}
export function EarningsFields({
  methods,
  selectedPayroll,
}: EarningsFieldsProps) {
  return (
    <>
      <div className="input-group-small">
        <p>Salário Base</p>
        <CustomController
          name="salarioBase"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.salarioBase !== null &&
              selectedPayroll.salarioBase !== undefined
              ? selectedPayroll.salarioBase
              : null
          }
        //defaultValue={selectedPayroll.salarioBase || null}
        />
      </div>
      <div className="input-group-small">
        <p>Salário Bruto</p>
        <CustomController
          name="salarioBruto"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.salarioBruto !== null &&
              selectedPayroll.salarioBruto !== undefined
              ? selectedPayroll.salarioBruto
              : null
          }
        // defaultValue={selectedPayroll.salarioBruto || null}
        />
      </div>
      <div className="input-group-small">
        <p>Salário Liquido</p>
        <CustomController
          name="salarioLiquido"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.salarioLiquido !== null &&
              selectedPayroll.salarioLiquido !== undefined
              ? selectedPayroll.salarioLiquido
              : null
          }
        //defaultValue={selectedPayroll.salarioLiquido || null}
        />
      </div>
      <div className="input-group-small">
        <p>Gratificação</p>
        <CustomController
          name="gratificacao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.gratificacao !== null &&
              selectedPayroll.gratificacao !== undefined
              ? selectedPayroll.gratificacao
              : null
          }
        //defaultValue={selectedPayroll.gratificacao || null}
        />
      </div>
      <div className="input-group-small">
        <p>Horas Extras</p>
        <CustomController
          name="horasExtras"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.horasExtras !== null &&
              selectedPayroll.horasExtras !== undefined
              ? selectedPayroll.horasExtras
              : null
          }
        //defaultValue={selectedPayroll.horasExtras || null}
        />
      </div>
      <div className="input-group-small">
        <p>DSR Horas Extras </p>
        <CustomController
          name="horasExtrasDsr"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.horasExtrasDsr !== null &&
              selectedPayroll.horasExtrasDsr !== undefined
              ? selectedPayroll.horasExtrasDsr
              : null
          }
        // defaultValue={selectedPayroll.horasExtrasDsr || null}
        />
      </div>
      <div className="input-group-small">
        <p>Adicional Noturno</p>
        <CustomController
          name="adicionalNoturno"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.adicionalNoturno !== null &&
              selectedPayroll.adicionalNoturno !== undefined
              ? selectedPayroll.adicionalNoturno
              : null
          }
        //defaultValue={selectedPayroll.adicionalNoturno || null}
        />
      </div>
      <div className="input-group-small">
        <p>Prêmio</p>
        <CustomController
          name="premio"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.premio !== null &&
              selectedPayroll.premio !== undefined
              ? selectedPayroll.premio
              : null
          }
        // defaultValue={selectedPayroll.premio || null}
        />
      </div>
      <div className="input-group-normal">
        <p>DSR Adicional Noturno </p>
        <CustomController
          name="adicionalNoturnoDsr"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.adicionalNoturnoDsr !== null &&
              selectedPayroll.adicionalNoturnoDsr !== undefined
              ? selectedPayroll.adicionalNoturnoDsr
              : null
          }
        // defaultValue={selectedPayroll.adicionalNoturnoDsr || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Adicional Periculosidade</p>
        <CustomController
          name="adicionalPericulosidade"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.adicionalPericulosidade !== null &&
              selectedPayroll.adicionalPericulosidade !== undefined
              ? selectedPayroll.adicionalPericulosidade
              : null
          }
        //defaultValue={selectedPayroll.adicionalPericulosidade || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Adicional Insalubridade</p>
        <CustomController
          name="adicionalInsalubridade"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.adicionalInsalubridade !== null &&
              selectedPayroll.adicionalInsalubridade !== undefined
              ? selectedPayroll.adicionalInsalubridade
              : null
          }
        //defaultValue={selectedPayroll.adicionalInsalubridade || null}
        />
      </div>
      <div className="input-group-small">
        <p>Diferença Salarial</p>
        <CustomController
          name="diferencaSalarial"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.diferencaSalarial !== null &&
              selectedPayroll.diferencaSalarial !== undefined
              ? selectedPayroll.diferencaSalarial
              : null
          }
        //defaultValue={selectedPayroll.diferencaSalarial || null}
        />
      </div>
      <div className="input-group-small">
        <p>Salário Familia</p>
        <CustomController
          name="salarioFamilia"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.salarioFamilia !== null &&
              selectedPayroll.salarioFamilia !== undefined
              ? selectedPayroll.salarioFamilia
              : null
          }
        //defaultValue={selectedPayroll.salarioFamilia || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Salário Maternidade</p>
        <CustomController
          name="salarioMaternidade"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.salarioMaternidade !== null &&
              selectedPayroll.salarioMaternidade !== undefined
              ? selectedPayroll.salarioMaternidade
              : null
          }
        // defaultValue={selectedPayroll.salarioMaternidade || null}
        />
      </div>
      <div className="input-group-small">
        <p>Restituição Inss</p>
        <CustomController
          name="restituicaoInss"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.restituicaoInss !== null &&
              selectedPayroll.restituicaoInss !== undefined
              ? selectedPayroll.restituicaoInss
              : null
          }
        //defaultValue={selectedPayroll.restituicaoInss || null}
        />
      </div>
      <div className="input-group-small">
        <p>Restituição Irrf</p>
        <CustomController
          name="restituicaoIrrf"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.restituicaoIrrf !== null &&
              selectedPayroll.restituicaoIrrf !== undefined
              ? selectedPayroll.restituicaoIrrf
              : null
          }
        //defaultValue={selectedPayroll.restituicaoIrrf || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Adiantamento Salario</p>
        <CustomController
          name="desAdiantamentoSalario"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.desAdiantamentoSalario !== null &&
              selectedPayroll.desAdiantamentoSalario !== undefined
              ? selectedPayroll.desAdiantamentoSalario
              : null
          }
        //defaultValue={selectedPayroll.desAdiantamentoSalario || null}
        />
      </div>
      <div className="input-group-small">
        <p>Devolução </p>
        <CustomController
          name="devolucao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.devolucao !== null &&
              selectedPayroll.devolucao !== undefined
              ? selectedPayroll.devolucao
              : null
          }
        // defaultValue={selectedPayroll.devolucao || null}
        />
      </div>
      <div className="input-group-small">
        <p>Outros proventos</p>
        <CustomController
          name="outrosProventos"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.outrosProventos !== null &&
              selectedPayroll.outrosProventos !== undefined
              ? selectedPayroll.outrosProventos
              : null
          }
        //defaultValue={selectedPayroll.outrosProventos || null}
        />
      </div>
      <div className="input-group-small">
        <p>Férias</p>
        <CustomController
          name="ferias"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.ferias !== null &&
              selectedPayroll.ferias !== undefined
              ? selectedPayroll.ferias
              : null
          }
        //  defaultValue={selectedPayroll.ferias || null}
        />
      </div>
      <div className="input-group-small">
        <p>Férias Abono</p>
        <CustomController
          name="feriasAbono"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.feriasAbono !== null &&
              selectedPayroll.feriasAbono !== undefined
              ? selectedPayroll.feriasAbono
              : null
          }
        //defaultValue={selectedPayroll.feriasAbono || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Férias Próximo Mês</p>
        <CustomController
          name="feriasProximoMes"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.feriasProximoMes !== null &&
              selectedPayroll.feriasProximoMes !== undefined
              ? selectedPayroll.feriasProximoMes
              : null
          }
        //defaultValue={selectedPayroll.feriasProximoMes || null}
        />
      </div>
      <div className="input-group-small">
        <p>1ª Parc 13º Salário</p>
        <CustomController
          name="decimoTerceiroPrimeiraParcela"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.decimoTerceiroPrimeiraParcela !== null &&
              selectedPayroll.decimoTerceiroPrimeiraParcela !== undefined
              ? selectedPayroll.decimoTerceiroPrimeiraParcela
              : null
          }
        //defaultValue={selectedPayroll.decimoTerceiroPrimeiraParcela || null}
        />
      </div>
      <div className="input-group-small">
        <p>2ª Parc 13º Salário</p>
        <CustomController
          name="decimoTerceiroSegundaParcela"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.decimoTerceiroSegundaParcela !== null &&
              selectedPayroll.decimoTerceiroSegundaParcela !== undefined
              ? selectedPayroll.decimoTerceiroSegundaParcela
              : null
          }
        //defaultValue={selectedPayroll.decimoTerceiroSegundaParcela || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Saldo Salário Rescisão</p>
        <CustomController
          name="saldoSalarioRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.saldoSalarioRescisao !== null &&
              selectedPayroll.saldoSalarioRescisao !== undefined
              ? selectedPayroll.saldoSalarioRescisao
              : null
          }
        // defaultValue={selectedPayroll.saldoSalarioRescisao || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Férias Proporcional Rescisão</p>
        <CustomController
          name="feriasProporcionaisRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.feriasProporcionaisRescisao !== null &&
              selectedPayroll.feriasProporcionaisRescisao !== undefined
              ? selectedPayroll.feriasProporcionaisRescisao
              : null
          }
        //defaultValue={selectedPayroll.feriasProporcionaisRescisao || null}
        />
      </div>

      <div className="input-group-normal">
        <p>13º Proporcional Rescisão</p>
        <CustomController
          name="decimoTerPropRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.decimoTerPropRescisao !== null &&
              selectedPayroll.decimoTerPropRescisao !== undefined
              ? selectedPayroll.decimoTerPropRescisao
              : null
          }
        //  defaultValue={selectedPayroll.decimoTerPropRescisao || null}
        />
      </div>
      <div className="input-group-normal">
        <p>13º Salário Indenizado</p>
        <CustomController
          name="decimoTerceiroIndenizado"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.decimoTerceiroIndenizado !== null &&
              selectedPayroll.decimoTerceiroIndenizado !== undefined
              ? selectedPayroll.decimoTerceiroIndenizado
              : null
          }
        //defaultValue={selectedPayroll.decimoTerceiroIndenizado || null}
        />
      </div>

      <div className="input-group-normal">
        <p>Outras verbas rescisórias</p>
        <CustomController
          name="outrasVerbasRescisorias"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.outrasVerbasRescisorias !== null &&
              selectedPayroll.outrasVerbasRescisorias !== undefined
              ? selectedPayroll.outrasVerbasRescisorias
              : null
          }
        //defaultValue={selectedPayroll.outrasVerbasRescisorias || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Valor Bruto Rescisão</p>
        <CustomController
          name="valorBrutoRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.valorBrutoRescisao !== null &&
              selectedPayroll.valorBrutoRescisao !== undefined
              ? selectedPayroll.valorBrutoRescisao
              : null
          }
        //defaultValue={selectedPayroll.valorBrutoRescisao || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Valor Liquido Rescisão (A Pagar)</p>
        <CustomController
          name="valorLiquidoRescisaoApagar"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.valorLiquidoRescisaoApagar !== null &&
              selectedPayroll.valorLiquidoRescisaoApagar !== undefined
              ? selectedPayroll.valorLiquidoRescisaoApagar
              : null
          }
        //defaultValue={selectedPayroll.valorLiquidoRescisaoApagar || null}
        />
      </div>
      {/* <div className="input-group-small">
        <p>Cd Folha Pag</p>
        <CustomController
          name="cdFolhaPagamento"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.cdFolhaPagamento !== null &&
            selectedPayroll.cdFolhaPagamento !== undefined
              ? selectedPayroll.cdFolhaPagamento
              : null
          }
          //defaultValue={selectedPayroll.cdFolhaPagamento || null}
        />
      </div> */}
      <div className="input-group-small">
        <p>Multa Rescisória</p>
        <CustomController
          name="multaRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.multaRescisao !== null &&
              selectedPayroll.multaRescisao !== undefined
              ? selectedPayroll.multaRescisao
              : null
          }
        //defaultValue={selectedPayroll.multaRescisao || null}
        />
      </div>
      {/* <div className="input-group-normal">
        <p>Saldo prop/Resc 13º</p>
        <CustomController
          name="saldoPropRescDecimoTerceiro"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.saldoPropRescDecimoTerceiro !== null &&
            selectedPayroll.saldoPropRescDecimoTerceiro !== undefined
              ? selectedPayroll.saldoPropRescDecimoTerceiro
              : null
          }
        />
      </div> */}
    </>
  );
}
