import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteAccountPlan,
  getAccountPlan,
} from "../../../../../Controllers/CustomScreens/FinancialRelease/AccountPlanController/AccountPlanController";

interface AccountPlanProps {
  content: ContentProps[];
  totalPages: number;
}
export interface ContentProps {
  cdPlanoConta: number;
  numPagamento: number;
  tipoDespesaId: string;
  valor: number;
}
export const useAccountPlan = () => {
  const { numPagamento } = useParams();

  const [modalState, setModalState] = useState<boolean>(false);

  const [accountPlan, setAccountPlan] = useState<AccountPlanProps>(
    {} as AccountPlanProps
  );
  const [selectedAccountPlan, setSelectedAccountPlan] = useState<ContentProps>(
    {} as ContentProps
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  async function handleGetAccountPlan(page?: number) {
    setIsLoading(true);
    try {
      const { data } = await getAccountPlan(Number(numPagamento), page);
      setAccountPlan(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  function handleEditModal(account: ContentProps) {
    setSelectedAccountPlan(account);
    setModalState(true);
  }

  async function handleDeleteAccountPlan(cdPlanoConta: number) {
    try {
      await deleteAccountPlan(cdPlanoConta);
      handleGetAccountPlan();
      toast.success("Conta deletada com sucesso");
    } catch (error) {
      toast.error("Erro ao deletar");
    }
  }
  useEffect(() => {
    handleGetAccountPlan();
  }, []);

  async function handlePaginate(
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    handleGetAccountPlan(value - 1);
  }

  return {
    handleGetAccountPlan,
    setModalState,
    modalState,
    accountPlan,
    handlePaginate,
    selectedAccountPlan,
    setSelectedAccountPlan,
    handleEditModal,
    handleDeleteAccountPlan,
    isLoading,
  };
};
