import { Button, Dialog, Pagination } from "@mui/material";
import { BsPencilSquare } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { MdBlockFlipped } from "react-icons/md";
import ReactTooltip from "react-tooltip";
import iconOcultar from "../../../../assets/iconOcultar.svg";
import { Header } from "../../../Header";
import { CreateDepara } from "./dialogs/createDepara";
import { UpdateDepara } from "./dialogs/updateDepara";
import "./styles.scss";
import { useDepara } from "./useDeparas";

export function DeparasUnifacisa() {
  const {
    dialogCreate,
    dialogUpdate,
    setDialogCreate,
    setDialogUpdate,
    deparasList,
    setDeparaFilters,
    handlePaginate,
    handleGetDeparas,
    handleDeleteDepara,
    handleUpdateDepara,
    updateDeparaObject,
  } = useDepara();
  return (
    <div className="depara-container">
      <Header message={"Dashboard/De-paras Unifacisa"} showAtualizacao={true} />
      <main>
        <div className="depara-main-card">
          <h2>De-paras Unifacisa</h2>{" "}
          <header className="depara-main-card-header">
            <div className="depara-main-card-header-filters">
              <input
                type="text"
                placeholder="Código Empresa"
                onBlur={(e) =>
                  setDeparaFilters((prevState) => {
                    return {
                      ...prevState,
                      cd_multi_empresa: Number(e.target.value),
                    };
                  })
                }
              />
              <input
                type="text"
                placeholder="Código MV"
                onBlur={(e) =>
                  setDeparaFilters((prevState) => {
                    return {
                      ...prevState,
                      cd_depara_mv: e.target.value,
                    };
                  })
                }
              />
              <input
                type="text"
                placeholder=" Código RM"
                onBlur={(e) =>
                  setDeparaFilters((prevState) => {
                    return {
                      ...prevState,
                      cd_depara_integra: e.target.value,
                    };
                  })
                }
              />
              <select
                onBlur={(e) =>
                  setDeparaFilters((prevState) => {
                    return {
                      ...prevState,
                      tp_depara: e.target.value,
                    };
                  })
                }
              >
                <option selected hidden>
                  Tipo Depara
                </option>
                <option>CONTA_CONTABIL_RM</option>
                <option>CENTRO_CUSTO_RM</option>
                <option>HISTORICO_RM</option>
              </select>
              <button
                type="button"
                className="filter-button"
                onClick={() => handleGetDeparas()}
              >
                Filtrar
              </button>
            </div>
            <aside className="depara-main-card-header-buttons">
              <Button variant="contained" onClick={() => setDialogCreate(true)}>
                Criar depara <FaPlus />
              </Button>
              <Dialog
                open={dialogCreate}
                onClose={() => setDialogCreate(false)}
                PaperProps={{
                  style: {
                    backgroundColor: " hsl(206, 100%, 12%)",
                  },
                }}
              >
                <CreateDepara
                  handleCloseModal={() => setDialogCreate(false)}
                  handleRefreshDeparas={() => handleGetDeparas()}
                />
              </Dialog>
              <Dialog
                open={dialogUpdate}
                onClose={() => setDialogUpdate(false)}
                PaperProps={{
                  style: {
                    backgroundColor: " hsl(206, 100%, 12%)",
                  },
                }}
              >
                <UpdateDepara
                  handleCloseModal={() => setDialogUpdate(false)}
                  deparaData={updateDeparaObject}
                  handleRefreshDeparas={() => handleGetDeparas()}
                />
              </Dialog>
            </aside>
          </header>
          <section className="depara-main-card-section">
            {deparasList?.data !== undefined &&
            deparasList?.data.length !== 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Tipo Depara</th>
                    <th>Código Empresa</th>
                    <th>Código MV</th>
                    <th>Descrição MV</th>
                    <th>Código RM</th>
                    <th>Descrição RM </th>
                    <th>Sistema Integrado </th>
                    <th colSpan={1}></th>
                  </tr>
                </thead>
                <tbody>
                  {deparasList?.data?.map((depara, index: number) => (
                    <tr key={index}>
                      <td>{depara.TP_DEPARA}</td>
                      <td>{depara.CD_MULTI_EMPRESA}</td>
                      <td>{depara.CD_DEPARA_MV}</td>
                      <td>{depara.DS_DEPARA_MV}</td>
                      <td>{depara.CD_DEPARA_INTEGRA}</td>
                      <td>{depara.DS_DEPARA_INTEGRA}</td>
                      <td>{depara.CD_SISTEMA_INTEGRA}</td>
                      <td>
                        <div className="depara-main-card-section-icons">
                          <p
                            data-tip="Editar Registro"
                            onClick={() => handleUpdateDepara(depara)}
                          >
                            <BsPencilSquare color="#D8A511" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                          {depara.SN_ATIVO === "S" ? (
                            <p
                              data-tip="Inativar Registro"
                              onClick={() =>
                                handleDeleteDepara(depara.CD_DTI_DEPARA, "N")
                              }
                            >
                              <img src={iconOcultar} alt="" />
                            </p>
                          ) : (
                            <p
                              data-tip="Registro Inativo"
                              onClick={() =>
                                handleDeleteDepara(depara.CD_DTI_DEPARA, "S")
                              }
                            >
                              <MdBlockFlipped color="#D8A511" />
                            </p>
                          )}
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h2>Sem registros</h2>
            )}
          </section>
          <footer>
            {deparasList?.data !== undefined && (
              <Pagination
                count={Math.ceil(deparasList.total / Number(deparasList.limit))}
                variant="outlined"
                shape="rounded"
                onChange={handlePaginate}
              />
            )}
          </footer>
        </div>
      </main>
    </div>
  );
}
