import dayjs from "dayjs";
import { RxCross1 } from "react-icons/rx";
import "./styles.scss";
import { useModalImport } from "./useModalImport";
interface ModalImportProps {
  handleCloseModal: () => void;
  handleGetAccountability: (page?: number) => Promise<void>;
}
export function ModalImport({
  handleCloseModal,
  handleGetAccountability,
}: ModalImportProps) {
  const { setImportConfig, handleImport } = useModalImport({
    handleCloseModal: handleCloseModal,
    handleGetAccountability: handleGetAccountability,
  });
  return (
    <div className="modal-import-container">
      <header>
        <RxCross1 onClick={() => handleCloseModal()} />
      </header>
      <main>
        <div className="modal-import-input-card">
          <p>Data Competência</p>
          <input
            type="date"
            onBlur={(e) =>
              setImportConfig({
                dataPrestacao: dayjs(e.target.value).format("YYYY-MM-DD"),
              })
            }
          />
        </div>
      </main>
      <footer>
        <button type="submit" onClick={() => handleImport()}>
          Confirmar
        </button>
        <button onClick={() => handleCloseModal()}>Cancelar</button>
      </footer>
    </div>
  );
}
