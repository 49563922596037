import { z } from "zod";

export const EmployeesSchema = z.object({
  nome: z.string().nullable(),
  cpf: z.string().nullable(),
  cns: z.string().nullable(),
  valorSalarioBase: z.number().nullable(),
  unidadeId: z.string().nullable(),
  dataAdmissao: z.string().nullable(),
  dataDemissao: z.string().nullable(),
  linhaServicoId: z.string().nullable(),
  jornada: z.number().nullable(),
  cargoId: z.string().nullable(),
  regimeContratacao: z.string().nullable(),
  tipoAtividade: z.string().nullable(),
  matricula: z.string().nullable(),
  dataNascimento: z.string().nullable(),
  genero: z.string().nullable(),
  tipoColaborador: z.string().nullable(),
  tipoFolha: z.string().nullable(),
  cargaHorariaSemanal: z.number().nullable(),
  prestacaoContaId: z.number().optional(),
  parceriaId: z.number().nullable().optional(),
});

export type EmployeesSchemaProps = z.infer<typeof EmployeesSchema>;
