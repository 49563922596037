import { ImportConfig } from "../../../components/CustomScreens/totvs/BankAccount/ModalImport/useModalImport";
import { PositionSalarySchemaProps } from "../../../components/CustomScreens/totvs/PositionSalary/ModalPositionSalary/schema";
import { ContentProps } from "../../../components/CustomScreens/totvs/PositionSalary/usePositionSalary";
import { customScreenApi } from "../../../services/api";

export const getPositionSalary = async (
  page?: number,
  descricaoCargo?: string,
  tpStatus?: string
) => {
  const response = await customScreenApi.get(`/cargosSalarios`, {
    params: {
      page: page || 0,
      size: 7,
      descricaoCargo: descricaoCargo || "",
      tpStatus: tpStatus || "",
    },
  });
  return response;
};

export const postPositionSalary = async (data: PositionSalarySchemaProps) => {
  const response = await customScreenApi.post("/cargosSalarios", {
    ...data,
    tpStatus: "A",
    parceriaId: sessionStorage.getItem("parceriaId"),
  });
  return response;
};
export const putPositionSalary = async (
  data: PositionSalarySchemaProps,
  selectedPositionSalary: ContentProps
) => {
  const response = await customScreenApi.put("/cargosSalarios", {
    ...data,
    cdCargoSalario: selectedPositionSalary.cdCargoSalario,
    tpStatus: selectedPositionSalary.tpStatus,
  });
  return response;
};

export const deletePositionSalary = async (cdCargoSalario: number) => {
  const response = await customScreenApi.delete(
    `/cargosSalarios/${cdCargoSalario}`
  );
  return response;
};

export const putImportPositionSalary = async (listImports: any) => {
  const response = await customScreenApi.put(
    `/cargosSalarios/importar`,
    {},
    {
      headers: {
        cdCargoSalario: listImports,
      },
    }
  );
  return response;
};
export const postImportPositionSalary = async (importObject: ImportConfig) => {
  const response = await customScreenApi.post(
    `/cargosSalarios/buscaRegistroTotvs`,
    {
      ...importObject,
      parceriaId: sessionStorage.getItem("parceriaId"),
    }
  );
  return response;
};
