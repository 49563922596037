import { UseFormReturn } from "react-hook-form";
import { CustomController } from "../../../../../../utils/FormController/FormController";
import { ContentProps } from "../../usePayroll";

interface BenefitsFieldsProps {
  methods: UseFormReturn;
  selectedPayroll: ContentProps;
}
export function BenefitsFields({
  methods,
  selectedPayroll,
}: BenefitsFieldsProps) {
  return (
    <>
      <div className="input-group-normal">
        <p>Vale Transporte</p>
        <CustomController
          name="valeTransporte"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.valeTransporte !== null &&
              selectedPayroll.valeTransporte !== undefined
              ? selectedPayroll.valeTransporte
              : null
          }
        //defaultValue={selectedPayroll.valeTransporte || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Vale Alimentação</p>
        <CustomController
          name="valeAlimentacao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.valeAlimentacao !== null &&
              selectedPayroll.valeAlimentacao !== undefined
              ? selectedPayroll.valeAlimentacao
              : null
          }
        //defaultValue={selectedPayroll.valeAlimentacao || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Vale Refeição</p>
        <CustomController
          name="valeRefeicao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.valeRefeicao !== null &&
              selectedPayroll.valeRefeicao !== undefined
              ? selectedPayroll.valeRefeicao
              : null
          }
        //defaultValue={selectedPayroll.valeRefeicao || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Cesta básica</p>
        <CustomController
          name="cestaBasica"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.cestaBasica !== null &&
              selectedPayroll.cestaBasica !== undefined
              ? selectedPayroll.cestaBasica
              : null
          }
        //defaultValue={selectedPayroll.cestaBasica || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Auxilio creche</p>
        <CustomController
          name="auxilioCreche"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.auxilioCreche !== null &&
              selectedPayroll.auxilioCreche !== undefined
              ? selectedPayroll.auxilioCreche
              : null
          }
        //defaultValue={selectedPayroll.auxilioCreche || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Outros benefícios</p>
        <CustomController
          name="outrosBeneficios"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.outrosBeneficios !== null &&
              selectedPayroll.outrosBeneficios !== undefined
              ? selectedPayroll.outrosBeneficios
              : null
          }
        //defaultValue={selectedPayroll.outrosBeneficios || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Auxílios</p>
        <CustomController
          name="auxilios"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.auxilios !== null &&
              selectedPayroll.auxilios !== undefined
              ? selectedPayroll.auxilios
              : null
          }
        // defaultValue={selectedPayroll.auxilios || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Arredondamento</p>
        <CustomController
          name="arredondamento"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.arredondamento !== null &&
              selectedPayroll.arredondamento !== undefined
              ? selectedPayroll.arredondamento
              : null
          }
        //defaultValue={selectedPayroll.arredondamento || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Adiantamento Salario</p>
        <CustomController
          name="adiantamentoSalario"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.adiantamentoSalario !== null &&
              selectedPayroll.adiantamentoSalario !== undefined
              ? selectedPayroll.adiantamentoSalario
              : null
          }
        //defaultValue={selectedPayroll.adiantamentoSalario || null}
        />
      </div>{" "}
    </>
  );
}
