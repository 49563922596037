import { Button, Dialog, LinearProgress, Pagination } from "@mui/material";
import { BsPencilSquare } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import iconOcultar from "../../../../../assets/iconOcultar.svg";
import { Header } from "../../../../Header";
import { ModalAccountPlan } from "./ModalAccountPlan";
import "./styles.scss";
import { ContentProps, useAccountPlan } from "./useAccountPlan";

export function AccountPlan() {
  const {
    handleGetAccountPlan,
    modalState,
    setModalState,
    accountPlan,
    handlePaginate,
    selectedAccountPlan,
    setSelectedAccountPlan,
    handleDeleteAccountPlan,
    handleEditModal,
    isLoading,
  } = useAccountPlan();
  return (
    <div className="AccountPlan-container">
      <Header
        message={"Dashboard/Lançamentos Financeiros/Plano Conta"}
        showAtualizacao={true}
        backScreen={true}
        url="LancamentosFinanceiros"
      />
      <main>
        <div className="AccountPlan-main-card">
          <header className="AccountPlan-main-card-header">
            <h2>Plano Conta</h2>
            <div className="header-buttons">
              <aside>
                <Button variant="outlined" onClick={() => setModalState(true)}>
                  Novo Registro <FaPlus />
                </Button>
              </aside>
            </div>
          </header>
          <section className="AccountPlan-main-card-section">
            <Dialog
              open={modalState}
              onClose={() => (
                setModalState(false), setSelectedAccountPlan({} as ContentProps)
              )}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalAccountPlan
                handleCloseModal={() => (
                  setModalState(false),
                  setSelectedAccountPlan({} as ContentProps)
                )}
                handleGetAccountPlan={() => handleGetAccountPlan()}
                selectedAccountPlan={selectedAccountPlan}
              />
            </Dialog>
            {!isLoading ? (
              <table>
                {accountPlan?.content?.length !== 0 ? (
                  <thead>
                    <tr>
                      <th>Número pagamento</th>
                      <th>Id tipo despesa </th>
                      <th>Valor</th>
                      <th colSpan={1}></th>
                    </tr>
                  </thead>
                ) : (
                  <p>Nenhum Registro Encontrado</p>
                )}
                <tbody>
                  {accountPlan?.content?.map((account, index) => (
                    <tr key={index}>
                      <td>{account.numPagamento}</td>
                      <td>{account.tipoDespesaId}</td>
                      <td>{account.valor}</td>
                      <td>
                        <div className="AccountPlan-main-card-section-icons">
                          <p
                            data-tip="Editar Registro"
                            onClick={() => handleEditModal(account)}
                          >
                            <BsPencilSquare color="#D8A511" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                          <p
                            data-tip="Excluir Registro"
                            onClick={() =>
                              handleDeleteAccountPlan(account.cdPlanoConta)
                            }
                          >
                            <img src={iconOcultar} alt="" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <LinearProgress />
            )}
          </section>
          <footer>
            <Pagination
              count={accountPlan.totalPages}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginate}
            />
          </footer>
        </div>
      </main>
    </div>
  );
}
