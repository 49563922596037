import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  deleteAggregateUnits,
  getAggregateUnits,
} from "../../../../../Controllers/CustomScreens/FinancialRelease/AggregateUnitsController/AggregateUnitsController";

interface AggregateUnitsProps {
  content: ContentProps[];
  totalPages: number;
}
export interface ContentProps {
  cdUnidadesAgregadas: number;
  unidadeId: string;
  materialEquipamentoId: string;
  linhaServicoId: string;
  quantidade: number;
  valor: number;
  numPagamento: number;
  tipoDespesaId: string;
}
export const useAggregateUnits = () => {
  const { numPagamento, prestacaoContaId } = useParams();

  const [modalState, setModalState] = useState<boolean>(false);

  const [AggregateUnits, setAggregateUnits] = useState<AggregateUnitsProps>(
    {} as AggregateUnitsProps
  );
  const [selectedAggregateUnits, setSelectedAggregateUnits] =
    useState<ContentProps>({} as ContentProps);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showModalExclude, setShowModalExclude] = useState<boolean>(false);
  const [aggregateToDelete, setAggregateToDelete] = useState(null);
  const [page, setPage] = useState<number>(0);
  async function handleGetAggregateUnits(page?: number) {
    setIsLoading(true);
    try {
      const { data } = await getAggregateUnits(
        Number(numPagamento),
        Number(prestacaoContaId),
        page
      );
      setAggregateUnits(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  function handleEditModal(units: ContentProps) {
    setSelectedAggregateUnits(units);
    setModalState(true);
  }

  async function handleDeleteAggregateUnits(cdUnidadesAgregadas: number) {
    try {
      await deleteAggregateUnits(cdUnidadesAgregadas);
      handleGetAggregateUnits();
      setAggregateToDelete(null);
      setShowModalExclude(false);
      toast.success("Conta deletada com sucesso");
    } catch (error) {
      toast.error("Erro ao deletar");
    }
  }
  useEffect(() => {
    handleGetAggregateUnits();
  }, []);

  async function handlePaginate(
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    handleGetAggregateUnits(value - 1);
    setPage(value - 1);
  }

  return {
    handleGetAggregateUnits,
    setModalState,
    modalState,
    AggregateUnits,
    handlePaginate,
    selectedAggregateUnits,
    setSelectedAggregateUnits,
    handleEditModal,
    handleDeleteAggregateUnits,
    isLoading,
    aggregateToDelete,
    setAggregateToDelete,
    showModalExclude,
    setShowModalExclude,
    page,
  };
};
