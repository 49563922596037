export const priceFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const cepFormatter = (cep: string) => {
  return cep.replace(/^(\d{5})(\d{3})/, "$1-$2");
};

export const phoneFormatter = (phone: string) => {
  return phone.replace(/^(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
};

export const cpfFormatter = (cpf: string) => {
  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export async function convertFileToBase64(file: File): Promise<string> {
  if (file !== undefined) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }
  return "";
}
