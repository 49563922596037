import { z } from "zod";

export const FinancialReleaseSchema = z.object({
  lancamento: z.object({
    // arquivoCertidao: z.string().nullable(),
    //arquivoCertidaoExtensao: z.string().nullable(),
    // arquivoCertidaoHash: z.string().nullable(),
    arquivoNf: z.string().nullable().optional(),
    //arquivoNfExtensao: z.string().nullable(),
    //arquivoXmlDanfe: z.string().nullable(),
    //arquivoXmlDanfeExtensao: z.string().nullable(),
    //arquivoXmlDanfeHash: z.string().nullable(),
    chaveNfe: z.string().nullable(),
    classificacaoFornecedorId: z.string().nullable(),
    classificacaoInvestimentoId: z.string().nullable(),
    classificacaoPgtoId: z.string().nullable(),
    clss: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    competencia: z.string().nullable(),
    confins: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    contaContabilBancaria: z.string().nullable(),
    //contaContabilId: z.number().nullable(),
    //contratoTerceirizadoId: z.string().nullable(),
    dataEmissao: z.string().nullable(),
    dataPagamento: z.string().nullable(),
    desconto: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    docRetencaoFonte: z
      .string()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    documentosCnpjCpf: z.string().nullable(),
    fonteRecursoCod: z.string().nullable(),
    // fornecedorId: z
    //   .number()
    //   .nullable()
    //   .transform((v) => (v === null ? 0 : v)),
    fornecedorcnpjcpf: z.string().nullable(),
    frete: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    historico: z.string().nullable(),
    icmsst: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    inss: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    ipi: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    irrf: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    iss: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    jurosMulta: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    nfDoc: z.string().nullable(),
    numDocumento: z.string().nullable(),
    numPagamento: z.string().min(1, "Este campo é obrigatório!"),
    numSerie: z.string().nullable(),
    observacaoNf: z.string().nullable(),
    pcc: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    pispasep: z
      .number()
      .nullable()
      .transform((v) => (v === null ? 0 : v)),
    prestacaoContaId: z.number().nullable().optional(),
    tipoDocId: z.string().nullable(),
    tipoFornecedorId: z.string().nullable(),
    tipoOperacaoId: z.string().nullable(),
    //transmitido: z.string().nullable(),
    valor: z.number().nullable(),
    valorDoc: z.number().nullable(),
    vencimento: z.string().nullable(),
    parceriaId: z.number().nullable().optional(),
    dsErro: z.string().nullable().optional(),
  }),
  arquivo: z.object({
    arquivoBase64: z.string().nullable().optional(),
    nomeArquivo: z.string().nullable().optional(),
  }),
});

export type FinancialReleaseSchemaProps = z.infer<
  typeof FinancialReleaseSchema
>;
