import { useState } from "react";
import { toast } from "react-toastify";
import { postImportAccountability } from "../../../../../Controllers/CustomScreens/Accountability/AccountabilityController";

interface ImportConfig {
  dataPrestacao: string;
}

interface UseModalImportProps {
  handleCloseModal: () => void;
  handleGetAccountability: (page?: number) => Promise<void>;
}
export const useModalImport = ({
  handleCloseModal,
  handleGetAccountability,
}: UseModalImportProps) => {
  const [importConfig, setImportConfig] = useState<ImportConfig>();

  async function handleImport() {
    try {
      await postImportAccountability(importConfig.dataPrestacao);
      toast.success("Prestação de conta criada com sucesso");
      handleCloseModal();
      handleGetAccountability(0);
    } catch (error) {
      if (error.response && error.response.data) {
        toast.error(error.response.data);
      } else {
        toast.error("Ocorreu um erro ao gerar a prestação");
      }
    }
  }
  return {
    setImportConfig,
    handleImport,
  };
};
