import { RxCross1 } from "react-icons/rx";
import { CustomController } from "../../../../../../utils/FormController/FormController";
import { ContentProps } from "../useWithheldTaxes";
import "./styles.scss";
import { useModalWithheldTaxes } from "./useModalWithheldTaxes";
interface ModalRegisterComponentProps {
  handleCloseModal: () => void;
  selectedWithheldTaxes: ContentProps;
  handleGetWithheldTaxes: () => void;
}
export function ModalWithheldTaxes({
  handleCloseModal,
  selectedWithheldTaxes,
  handleGetWithheldTaxes,
}: ModalRegisterComponentProps) {
  const { handleSubmitForm, methods } = useModalWithheldTaxes({
    handleCloseModal: handleCloseModal,
    selectedWithheldTaxes: selectedWithheldTaxes,
    handleGetWithheldTaxes: handleGetWithheldTaxes,
  });
  return (
    <div className="modal-register-container-withheld">
      <header>
        <RxCross1 onClick={() => handleCloseModal()} />
      </header>
      <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <main>
          <div className="input-group-small">
            <p>Id fornecedor</p>
            <CustomController
              name="fornecedorId"
              type="text"
              control={methods.control}
              defaultValue={selectedWithheldTaxes.fornecedorId || null}
            />
          </div>{" "}
          <div className="input-group-normal">
            <p>Fornecedor cnpj cpf</p>
            <CustomController
              name="fornecedorcnpjcpf"
              type="text"
              control={methods.control}
              defaultValue={selectedWithheldTaxes.fornecedorcnpjcpf || null}
            />
          </div>{" "}
          <div className="input-group-small">
            <p>Id linha serviço</p>
            <CustomController
              name="linhaServicoId"
              type="text"
              control={methods.control}
              defaultValue={selectedWithheldTaxes.linhaServicoId || null}
            />
          </div>
          <div className="input-group-small">
            <p>Id nota fiscal</p>
            <CustomController
              name="notaFiscalId"
              type="text"
              control={methods.control}
              defaultValue={selectedWithheldTaxes.notaFiscalId || null}
            />
          </div>
          <div className="input-group-small">
            <p>Número pagamento</p>
            <input type="text" {...methods.register("numPagamento")} disabled />
          </div>
          <div className="input-group-normal">
            <p>Valor unidade</p>
            <CustomController
              name="valorUnidade"
              type="number"
              control={methods.control}
              defaultValue={selectedWithheldTaxes.valorUnidade || null}
            />
          </div>
          <div className="input-group-small">
            <p>Id tributo</p>
            <CustomController
              name="tributoId"
              type="text"
              control={methods.control}
              defaultValue={selectedWithheldTaxes.tributoId || null}
            />
          </div>
          <div className="input-group-small">
            <p>Id unidade</p>
            <CustomController
              name="unidadeId"
              type="text"
              control={methods.control}
              defaultValue={selectedWithheldTaxes.unidadeId || null}
            />
          </div>
          <div className="input-group-normal">
            <p>Valor linha serviço</p>
            <CustomController
              name="valorLinhaServico"
              type="number"
              control={methods.control}
              defaultValue={selectedWithheldTaxes.valorLinhaServico || null}
            />
          </div>
        </main>
        <footer>
          <button type="submit" disabled={methods.formState.isSubmitting}>
            Confirmar
          </button>

          <button onClick={() => handleCloseModal()} type="button">
            Cancelar
          </button>
        </footer>
      </form>
    </div>
  );
}
