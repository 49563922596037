import { useState } from "react";
import { toast } from "react-toastify";
import {
  getPayroll,
  postImportPayroll,
} from "../../../../../Controllers/CustomScreens/Payroll/PayrollController";
import { ImportConfig } from "../../BankAccount/ModalImport/useModalImport";
import { InstallmentListProps, PayrollProps } from "../usePayroll";

interface UseModalImportProps {
  handleCloseModal: () => void;
  setNewList: React.Dispatch<React.SetStateAction<PayrollProps>>;
  installmentList?: InstallmentListProps[];
}
export const useModalImport = ({
  handleCloseModal,
  setNewList,
  installmentList,
}: UseModalImportProps) => {
  const [importConfig, setImportConfig] = useState<ImportConfig>();

  async function handleNewList() {
    try {
      const { data } = await getPayroll(
        0,
        "",
        "",
        "",
        importConfig.prestacaoContaId || installmentList[0].prestacaoContaId
      );
      setNewList(data);
    } catch (error) { }
  }
  async function handleImport() {
    try {
      await postImportPayroll(importConfig);
      toast.success("Importação realizada com sucesso!");
      await handleNewList();
      handleCloseModal();
    } catch (error) {
      toast.error("Verifique e preencha todos os campos!");
    }
  }
  return {
    setImportConfig,
    importConfig,
    handleImport,
  };
};
