import Modal from "react-modal";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DataErrorsProvider } from "./contexts/ErrorContext";
import { DataErrorsProviderAntigo } from "./contexts/ErrorDetailsAntigo/ErrorContextAntigo";
import { DataProvider } from "./contexts/PointsContext";
import { Carousel } from "./pages/Dashboard";
import { UserLogin } from "./pages/Login";
import { Metrics } from "./pages/Metrics";
import { RecuperaSenha } from "./pages/RecuperaSenha";
import { ErrorDetails } from "./pages/errorDetails";
import { ErrorDetailsAntigo } from "./pages/errorDetailsAntigo";

import { ToastContainer } from "react-toastify";
import "./App.scss";
import Accountability from "./components/CustomScreens/totvs/Accountability";
import BankAccount from "./components/CustomScreens/totvs/BankAccount";
import Employees from "./components/CustomScreens/totvs/Employees";
import FinancialRelease from "./components/CustomScreens/totvs/FinancialRelease";
import { AccountPlan } from "./components/CustomScreens/totvs/FinancialRelease/AccountPlan";
import { AggregateUnits } from "./components/CustomScreens/totvs/FinancialRelease/AggregateUnits";
import { WithheldTaxes } from "./components/CustomScreens/totvs/FinancialRelease/WithheldTaxes";
import Payroll from "./components/CustomScreens/totvs/Payroll";
import PositionSalary from "./components/CustomScreens/totvs/PositionSalary";
import { DeparasUnifacisa } from "./components/CustomScreens/unifacisa/deparas";
import { LotesUnifacisa } from "./components/CustomScreens/unifacisa/lotes";
import { AuthProvider } from "./contexts/Auth/ProviderContext";
import { RequireAuth } from "./contexts/Auth/RequireAuth";
import { FinanceReleaseProvider } from "./contexts/CustomScreens/totvs/FinancialRelease";
import { DataMetricsProvider } from "./contexts/MetricsContext";
import "./styles/global.scss";

Modal.setAppElement("#root");

function App() {
  return (
    <>
      <AuthProvider>
        <ToastContainer limit={5} />
        <BrowserRouter>
          <>
            <Routes>
              <Route path="/login" element={<UserLogin />} />
              <Route path="/recuperaSenha" element={<RecuperaSenha />} />

              <Route
                path="/Detalhes"
                element={
                  <DataErrorsProvider>
                    <ErrorDetails />
                  </DataErrorsProvider>
                }
              />
              <Route
                path="/DetalhesAntigo/:tpStatus"
                element={
                  <DataErrorsProviderAntigo>
                    <ErrorDetailsAntigo />
                  </DataErrorsProviderAntigo>
                }
              />
              <Route
                path="/Metricas"
                element={
                  <RequireAuth>
                    <DataMetricsProvider>
                      <Metrics />
                    </DataMetricsProvider>
                  </RequireAuth>
                }
              />
              <Route
                path="/ContasBancarias"
                element={
                  <RequireAuth>
                    <BankAccount />
                  </RequireAuth>
                }
              />
              <Route
                path="/PrestacaodeContas"
                element={
                  <RequireAuth>
                    <Accountability />
                  </RequireAuth>
                }
              />
              <Route
                path="/LancamentosFinanceiros"
                element={
                  <RequireAuth>
                    <FinanceReleaseProvider>
                      <FinancialRelease />
                    </FinanceReleaseProvider>
                  </RequireAuth>
                }
              />
              <Route
                path="/FolhadePagamento"
                element={
                  <RequireAuth>
                    <Payroll />
                  </RequireAuth>
                }
              />
              <Route
                path="/CargoseSalarios"
                element={
                  <RequireAuth>
                    <PositionSalary />
                  </RequireAuth>
                }
              />
              <Route
                path="/Funcionarios"
                element={
                  <RequireAuth>
                    <Employees />
                  </RequireAuth>
                }
              />
              {/* <Route
                path="/LoteUnifacisa"
                element={
                  <RequireAuth>
                    <LotesUnifacisa />
                  </RequireAuth>
                }
              /> */}
              <Route
                path="/LancamentosFinanceiros/TributosRetidos/:numPagamento/:prestacaoContaId"
                element={
                  <RequireAuth>
                    <FinanceReleaseProvider>
                      <WithheldTaxes />
                    </FinanceReleaseProvider>
                  </RequireAuth>
                }
              />
              <Route
                path="/LancamentosFinanceiros/AggregateUnits/:numPagamento/:prestacaoContaId"
                element={
                  <RequireAuth>
                    <FinanceReleaseProvider>
                      <AggregateUnits />
                    </FinanceReleaseProvider>
                  </RequireAuth>
                }
              />
              <Route
                path="/LancamentosFinanceiros/AccountPlan/:numPagamento"
                element={
                  <RequireAuth>
                    <FinanceReleaseProvider>
                      <AccountPlan />
                    </FinanceReleaseProvider>
                  </RequireAuth>
                }
              />
              <Route
                path="/LoteUnifacisa"
                element={
                  <RequireAuth>
                    <LotesUnifacisa />
                  </RequireAuth>
                }
              />
              <Route
                path="/De-ParaUnifacisa"
                element={
                  <RequireAuth>
                    <DeparasUnifacisa />
                  </RequireAuth>
                }
              />
            </Routes>
          </>

          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <DataErrorsProvider>
                    <DataProvider>
                      <Carousel />
                    </DataProvider>
                  </DataErrorsProvider>
                </RequireAuth>
              }
            />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
