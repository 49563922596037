import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getInstallment } from "../../../../Controllers/CustomScreens/BankAccount/BankAccountController";
import {
  deletePositionSalary,
  getPositionSalary,
  putImportPositionSalary,
} from "../../../../Controllers/CustomScreens/PositionSalary/PositionSalaryController";

export interface PositionSalaryProps {
  content: ContentProps[];
  totalPages: number;
}

export interface ContentProps {
  cdCargoSalario: number;
  codigoCbo: string;
  comentarios: string;
  convencaoColetiva: string;
  descricaoCargo: string;
  nivel: string;
  numeroHorasSemanais: number;
  numeroHorasMes: number;
  sindicato: string;
  valorSalario: string;
  tpStatus: string;
  dsErro: string;
  parceriaId: number;
  prestacaoContaId: number;
}
interface ErrorsImportRegistersProps {
  erros: string[];
  id: number;
  mensagem: string;
}

interface FilterRegisterProps {
  tpStatus?: string;
  descricaoCargo?: string;
}
export interface InstallmentListProps {
  prestacaoContaId: number;
  vigencia: string;
}
export const usePositionSalary = () => {
  const [modalState, setModalState] = useState<boolean>(false);
  const [positionSalary, setPositionSalary] = useState<PositionSalaryProps>(
    {} as PositionSalaryProps
  );
  const [selectedInstallment, setSelectedInstallment] = useState<number>(0);

  const [selectedPositionSalary, setSelectedPositionSalary] =
    useState<ContentProps>({} as ContentProps);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedImportRegisters, setSelectedImportRegisters] = useState<
    number[]
  >([]);

  const [page, setPage] = useState(0);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [filterRegisters, setFilterRegisters] = useState<FilterRegisterProps>(
    {} as FilterRegisterProps
  );
  const [showModalExclude, setShowModalExclude] = useState<boolean>(false);
  const [positionToDelete, setPositionToDelete] = useState(null);
  const [showModalImport, setShowModalImport] = useState(false);

  async function handleGetPositionSalary(
    page?: number,
    descricaoCargo?: string,
    tpStatus?: string
  ) {
    setIsLoading(true);
    try {
      const { data } = await getPositionSalary(page, descricaoCargo, tpStatus);
      setPositionSalary(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }
  const [installmentList, setInstallmentList] = useState<
    InstallmentListProps[]
  >([]);

  async function getInstallmentList() {
    try {
      const { data } = await getInstallment();
      setInstallmentList(data);
      setSelectedInstallment(data[0].prestacaoContaId);
      handleGetPositionSalary(
        0,
        filterRegisters.descricaoCargo,
        filterRegisters.tpStatus
      );
    } catch (err) {}
  }
  useEffect(() => {
    getInstallmentList();
  }, []);

  function handleEditModal(salary: ContentProps) {
    setSelectedPositionSalary(salary);
    setModalState(true);
  }

  async function handleDeletePositionSalary(cdCargoSalario: number) {
    try {
      await deletePositionSalary(cdCargoSalario);
      handleGetPositionSalary();
      setPositionToDelete(null);
      setShowModalExclude(false);
      toast.success("Registro deletado com sucesso");
    } catch (error) {
      toast.error("Erro ao deletar");
    }
  }
  useEffect(() => {
    handleGetPositionSalary();
  }, []);

  async function handlePaginate(
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    setPage(value - 1);
    handleGetPositionSalary(value - 1);
  }

  function handleImportRegisters(importRegisters?: number) {
    if (selectedImportRegisters.some((item) => item === importRegisters)) {
      // Se o objeto já está no array, remova-o
      setSelectedImportRegisters(
        selectedImportRegisters.filter((item) => item !== importRegisters)
      );
    } else {
      // Se o objeto não está no array, adicione-o
      setSelectedImportRegisters([...selectedImportRegisters, importRegisters]);
    }
  }

  async function handleSubmitImport() {
    try {
      const { data } = await putImportPositionSalary(
        allSelected ? [] : selectedImportRegisters
      );
      if (data === undefined) {
        toast.success("Cargos e salários importadas com sucesso!!");
        await handleGetPositionSalary(page);
        setSelectedImportRegisters([]);
        setAllSelected(false);
      } else {
        data.map((item: ErrorsImportRegistersProps) =>
          toast.warn(
            `Atenção, o registro ${
              item.mensagem
            } necessita do(s) campo(s): ${item.erros.map((error) => error)}`,
            {
              autoClose: false,
            }
          )
        );
        await handleGetPositionSalary(page);
        setSelectedImportRegisters([]);
        setAllSelected(false);
      }
    } catch (error) {
      toast.error("Erro ao importar cargos e salários");
    }
  }
  return {
    modalState,
    setModalState,
    positionSalary,
    handleEditModal,
    handleGetPositionSalary,
    handleDeletePositionSalary,
    selectedPositionSalary,
    setSelectedPositionSalary,
    handlePaginate,
    isLoading,
    handleImportRegisters,
    selectedImportRegisters,
    handleSubmitImport,
    page,
    allSelected,
    setAllSelected,
    setFilterRegisters,
    filterRegisters,
    showModalExclude,
    setShowModalExclude,
    positionToDelete,
    setPositionToDelete,
    showModalImport,
    setShowModalImport,
    installmentList,
    selectedInstallment,
    setSelectedInstallment,
    setPositionSalary,
  };
};
