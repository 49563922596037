import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getInstallment } from "../../../../Controllers/CustomScreens/BankAccount/BankAccountController";
import {
  deleteFinancialRelease,
  getBase64File,
  getFinancialRelease,
  putImportFinancialRelease,
} from "../../../../Controllers/CustomScreens/FinancialRelease/FinancialReleaseController";
import { FinanceReleaseContext } from "../../../../contexts/CustomScreens/totvs/FinancialRelease";

export interface FinancialReleaseProps {
  content: ContentProps[];
  totalPages: number;
}
export interface ContentProps {
  dsNomeArquivoNf: string;
  contemArquivo: boolean;
  dsErro: string;
  tpStatus: string;
  //arquivoCertidao: string;
  //arquivoCertidaoExtensao: string;
  //arquivoCertidaoHash: string;
  arquivoNf: string;
  //arquivoNfExtensao: string;
  //arquivoXmlDanfe: string;
  //arquivoXmlDanfeExtensao: string;
  // arquivoXmlDanfeHash: string;
  cdDocFinanceiro: number;
  chaveNfe: string;
  classificacaoFornecedorId: string;
  classificacaoInvestimentoId: string;
  classificacaoPgtoId: string;
  clss: number;
  competencia: string;
  confins: number;
  contaContabilBancaria: string;
  //contaContabilId: number;
  //contratoTerceirizadoId: string;
  dataEmissao: string;
  dataPagamento: string;
  desconto: number;
  docRetencaoFonte: boolean;
  documentosCnpjCpf: string;
  fonteRecursoCod: string;
  //fornecedorId: number;
  fornecedorcnpjcpf: string;
  frete: number;
  historico: string;
  icmsst: number;
  inss: number;
  ipi: number;
  irrf: number;
  iss: number;
  jurosMulta: number;
  nfDoc: string;
  numDocumento: string;
  numPagamento: string;
  numSerie: string;
  observacaoNf: string;
  pcc: number;
  pispasep: number;
  prestacaoContaId: number;
  tipoDocId: string;
  tipoFornecedorId: string;
  tipoOperacaoId: string;
  //transmitido: boolean;
  valor: number;
  valorDoc: number;
  vencimento: string;
  parceriaId: number;
}

interface ErrorsImportRegistersProps {
  erros: string[];
  id: number;
  mensagem: string;
}
export interface FilterRegisterProps {
  tpStatus?: string;
  numDocumento?: string;
  valor?: string;
  dataPagamento?: string;
  prestacaoContaId: number;
}
export interface InstallmentListProps {
  prestacaoContaId: number;
  vigencia: string;
}
export const useFinancialRelease = () => {
  const { page, setPage } = useContext(FinanceReleaseContext);
  const [modalState, setModalState] = useState<boolean>(false);
  const [financialRelease, setFinancialRelease] =
    useState<FinancialReleaseProps>({} as FinancialReleaseProps);
  console.log("page", page);
  console.log("financialRelease", financialRelease);
  const [selectedFinancialRelease, setSelectedFinancialRelease] =
    useState<ContentProps>({} as ContentProps);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [selectedImportRegisters, setSelectedImportRegisters] = useState<
    number[]
  >([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);

  const [filterRegisters, setFilterRegisters] = useState<FilterRegisterProps>(
    {} as FilterRegisterProps
  );
  const [installmentList, setInstallmentList] = useState<
    InstallmentListProps[]
  >([]);
  const [showModalExclude, setShowModalExclude] = useState<boolean>(false);
  const [financeToDelete, setFinanceToDelete] = useState(null);
  const [showModalImport, setShowModalImport] = useState(false);
  const [selectedInstallment, setSelectedInstallment] = useState<number>(0);
  async function handleGetFinancialRelease(
    page?: number,
    tpStatus?: string,
    numDocumento?: string,
    valor?: string,
    dataPagamento?: string,
    prestacaoContaId?: number
  ) {
    setIsLoading(true);

    try {
      const { data } = await getFinancialRelease(
        page,
        tpStatus,
        numDocumento,
        valor,
        dataPagamento,
        prestacaoContaId
      );
      setFinancialRelease(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  function handleEditModal(finance: ContentProps) {
    setSelectedFinancialRelease(finance);
    setModalState(true);
  }

  async function handleDeleteFinancialRelease(tblDtiCdBanco: number) {
    try {
      await deleteFinancialRelease(tblDtiCdBanco);
      handleGetFinancialRelease(
        page || 0,
        filterRegisters.tpStatus,
        filterRegisters.numDocumento,
        filterRegisters.valor,
        filterRegisters.dataPagamento,
        filterRegisters.prestacaoContaId || installmentList[0].prestacaoContaId
      );
      setFinanceToDelete(null);
      setShowModalExclude(false);
      toast.success("Conta deletada com sucesso");
    } catch (error) {
      toast.error("Erro ao deletar");
    }
  }

  async function getInstallmentList() {
    try {
      const { data } = await getInstallment();
      setInstallmentList(data);
      setSelectedInstallment(data[0].prestacaoContaId);
      handleGetFinancialRelease(
        page || 0,
        filterRegisters.tpStatus,
        filterRegisters.numDocumento,
        filterRegisters.valor,
        filterRegisters.dataPagamento,
        data[0].prestacaoContaId
      );
    } catch (err) {}
  }
  useEffect(() => {
    getInstallmentList();
  }, []);

  async function handlePaginate(
    event: React.ChangeEvent<unknown>,
    value: number
  ) {
    setPage(value - 1);
    handleGetFinancialRelease(
      value - 1,
      filterRegisters.tpStatus,
      filterRegisters.numDocumento,
      filterRegisters.valor,
      filterRegisters.dataPagamento,
      filterRegisters.prestacaoContaId || installmentList[0].prestacaoContaId
    );
  }

  function handleImportRegisters(importRegisters?: number) {
    if (selectedImportRegisters.some((item) => item === importRegisters)) {
      // Se o objeto já está no array, remova-o
      setSelectedImportRegisters(
        selectedImportRegisters.filter((item) => item !== importRegisters)
      );
    } else {
      // Se o objeto não está no array, adicione-o
      setSelectedImportRegisters([...selectedImportRegisters, importRegisters]);
    }
  }
  async function handleSubmitImport() {
    try {
      const { data } = await putImportFinancialRelease(
        allSelected ? [] : selectedImportRegisters
      );
      if (data === undefined) {
        toast.success("Lançamentos financeiros importadas com sucesso!!");
        await handleGetFinancialRelease(
          page,
          filterRegisters.tpStatus,
          filterRegisters.numDocumento,
          filterRegisters.valor,
          filterRegisters.dataPagamento,
          filterRegisters.prestacaoContaId ||
            installmentList[0].prestacaoContaId
        );
        setSelectedImportRegisters([]);
        setAllSelected(false);
      } else {
        data.map((item: ErrorsImportRegistersProps) =>
          toast.warn(
            `Atenção, o registro ${
              item.mensagem
            } necessita do(s) campo(s): ${item.erros.map((error) => error)}`,
            {
              autoClose: false,
            }
          )
        );
        await handleGetFinancialRelease(
          page,
          filterRegisters.tpStatus,
          filterRegisters.numDocumento,
          filterRegisters.valor,
          filterRegisters.dataPagamento,
          filterRegisters.prestacaoContaId ||
            installmentList[0].prestacaoContaId
        );
        setSelectedImportRegisters([]);
        setAllSelected(false);
      }
    } catch (error) {
      toast.error("Erro ao importar lançamentos financeiros");
    }
  }

  async function handleGetBase64(cdDocFinanceiro: number, fileName: string) {
    try {
      const { data } = await getBase64File(cdDocFinanceiro);
      const downloadLink = document.createElement("a");

      downloadLink.href = data;
      downloadLink.download = fileName;
      downloadLink.click();
    } catch (err) {}
  }

  return {
    installmentList,
    allSelected,
    setAllSelected,
    modalState,
    setModalState,
    financialRelease,
    handleEditModal,
    handleGetFinancialRelease,
    handleDeleteFinancialRelease,
    selectedFinancialRelease,
    setSelectedFinancialRelease,
    handlePaginate,
    isLoading,
    handleImportRegisters,
    selectedImportRegisters,
    page,
    handleSubmitImport,
    setFilterRegisters,
    filterRegisters,
    handleGetBase64,
    showModalExclude,
    setShowModalExclude,
    financeToDelete,
    setFinanceToDelete,
    showModalImport,
    setShowModalImport,
    selectedInstallment,
    setSelectedInstallment,
    setFinancialRelease,
  };
};
