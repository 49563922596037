import { UseFormReturn } from "react-hook-form";
import { CustomController } from "../../../../../../utils/FormController/FormController";
import { ContentProps } from "../../usePayroll";

interface CompanyFieldsProps {
  methods: UseFormReturn;
  selectedPayroll: ContentProps;
}
export function CompanyFields({
  methods,
  selectedPayroll,
}: CompanyFieldsProps) {
  return (
    <>
      <div className="input-group-small">
        <p>Base PIS S/ Folha</p>
        <CustomController
          name="basePisSemFolha"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.basePisSemFolha !== null &&
              selectedPayroll.basePisSemFolha !== undefined
              ? selectedPayroll.basePisSemFolha
              : null
          }
        //defaultValue={selectedPayroll.basePisSemFolha || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Base pis decimo terceiro folha</p>
        <CustomController
          name="basePisDecimoTerceiroSemFolha"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.basePisDecimoTerceiroSemFolha !== null &&
              selectedPayroll.basePisDecimoTerceiroSemFolha !== undefined
              ? selectedPayroll.basePisDecimoTerceiroSemFolha
              : null
          }
        // defaultValue={selectedPayroll.basePisDecimoTerceiroSemFolha || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Base pis férias folha</p>
        <CustomController
          name="basePisFeriasSemFolha"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.basePisFeriasSemFolha !== null &&
              selectedPayroll.basePisFeriasSemFolha !== undefined
              ? selectedPayroll.basePisFeriasSemFolha
              : null
          }
        //defaultValue={selectedPayroll.basePisFeriasSemFolha || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Base pis rescisão folha</p>
        <CustomController
          name="basePisRescisaoSemFolha"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.basePisRescisaoSemFolha !== null &&
              selectedPayroll.basePisRescisaoSemFolha !== undefined
              ? selectedPayroll.basePisRescisaoSemFolha
              : null
          }
        //defaultValue={selectedPayroll.basePisRescisaoSemFolha || null}
        />
      </div>
      <div className="input-group-small">
        <p>Base FGTS</p>
        <CustomController
          name="baseFgts"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseFgts !== null &&
              selectedPayroll.baseFgts !== undefined
              ? selectedPayroll.baseFgts
              : null
          }
        //defaultValue={selectedPayroll.baseFgts || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Base FGTS 13º</p>
        <CustomController
          name="baseFgtsDecimoTerceiro"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseFgtsDecimoTerceiro !== null &&
              selectedPayroll.baseFgtsDecimoTerceiro !== undefined
              ? selectedPayroll.baseFgtsDecimoTerceiro
              : null
          }
        //defaultValue={selectedPayroll.baseFgtsDecimoTerceiro || null}
        />
      </div>
      <div className="input-group-small">
        <p>Base FGTS Férias</p>
        <CustomController
          name="baseFgtsFerias"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseFgtsFerias !== null &&
              selectedPayroll.baseFgtsFerias !== undefined
              ? selectedPayroll.baseFgtsFerias
              : null
          }
        //defaultValue={selectedPayroll.baseFgtsFerias || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Base FGTS Rescisão</p>
        <CustomController
          name="baseFgtsRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseFgtsRescisao !== null &&
              selectedPayroll.baseFgtsRescisao !== undefined
              ? selectedPayroll.baseFgtsRescisao
              : null
          }
        // defaultValue={selectedPayroll.baseFgtsRescisao || null}
        />
      </div>{" "}
      <div className="input-group-normal">
        <p>Base FGTS Demitidos</p>
        <CustomController
          name="baseFgtsDemitidos"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseFgtsDemitidos !== null &&
              selectedPayroll.baseFgtsDemitidos !== undefined
              ? selectedPayroll.baseFgtsDemitidos
              : null
          }
        //defaultValue={selectedPayroll.baseFgtsDemitidos || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Base FGTS 13º Demitidos</p>
        <CustomController
          name="baseFgtsDecimoTerceiroDemitidos"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseFgtsDecimoTerceiroDemitidos !== null &&
              selectedPayroll.baseFgtsDecimoTerceiroDemitidos !== undefined
              ? selectedPayroll.baseFgtsDecimoTerceiroDemitidos
              : null
          }
        // defaultValue={selectedPayroll.baseFgtsDecimoTerceiroDemitidos || null}
        />
      </div>
      <div className="input-group-small">
        <p>Base IRRF</p>
        <CustomController
          name="baseIrrf"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseIrrf !== null &&
              selectedPayroll.baseIrrf !== undefined
              ? selectedPayroll.baseIrrf
              : null
          }
        //defaultValue={selectedPayroll.baseIrrf || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Base IRRF 13º</p>
        <CustomController
          name="baseIrrfDecimoTerceiro"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseIrrfDecimoTerceiro !== null &&
              selectedPayroll.baseIrrfDecimoTerceiro !== undefined
              ? selectedPayroll.baseIrrfDecimoTerceiro
              : null
          }
        // defaultValue={selectedPayroll.baseIrrfDecimoTerceiro || null}
        />
      </div>
      <div className="input-group-small">
        <p>Base IRRF Férias</p>
        <CustomController
          name="baseIrrfFerias"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseIrrfFerias !== null &&
              selectedPayroll.baseIrrfFerias !== undefined
              ? selectedPayroll.baseIrrfFerias
              : null
          }
        // defaultValue={selectedPayroll.baseIrrfFerias || null}
        />
      </div>
      <div className="input-group-small">
        <p>Base IRRF Rescisão</p>
        <CustomController
          name="baseIrrfRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseIrrfRescisao !== null &&
              selectedPayroll.baseIrrfRescisao !== undefined
              ? selectedPayroll.baseIrrfRescisao
              : null
          }
        //defaultValue={selectedPayroll.baseIrrfRescisao || null}
        />
      </div>
      {/* <div className="input-group-small">
        <p>Segurados</p>
        <CustomController
          name="segurados"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.segurados !== null &&
            selectedPayroll.segurados !== undefined
              ? selectedPayroll.segurados
              : null
          }
        />
      </div> */}
      <div className="input-group-normal">
        <p>Base INSS 13º Salário</p>
        <CustomController
          name="baseInssDecimoTerceiro"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseInssDecimoTerceiro !== null &&
              selectedPayroll.baseInssDecimoTerceiro !== undefined
              ? selectedPayroll.baseInssDecimoTerceiro
              : null
          }
        // defaultValue={selectedPayroll.baseInssDecimoTerceiro || null}
        />
      </div>
      <div className="input-group-small">
        <p>Base INSS</p>
        <CustomController
          name="baseInss"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseInss !== null &&
              selectedPayroll.baseInss !== undefined
              ? selectedPayroll.baseInss
              : null
          }
        //defaultValue={selectedPayroll.baseInss || null}
        />
      </div>{" "}
      <div className="input-group-small">
        <p>Base INSS Férias</p>
        <CustomController
          name="baseInssFerias"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseInssFerias !== null &&
              selectedPayroll.baseInssFerias !== undefined
              ? selectedPayroll.baseInssFerias
              : null
          }
        // defaultValue={selectedPayroll.baseInssFerias || null}
        />
      </div>
      <div className="input-group-small">
        <p>INSS (patronal)</p>
        <CustomController
          name="inssPatronal"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.inssPatronal !== null &&
              selectedPayroll.inssPatronal !== undefined
              ? selectedPayroll.inssPatronal
              : null
          }
        //defaultValue={selectedPayroll.inssPatronal || null}
        />
      </div>
      <div className="input-group-small">
        <p>INSS Terceiros</p>
        <CustomController
          name="inssTerceiros"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.inssTerceiros !== null &&
              selectedPayroll.inssTerceiros !== undefined
              ? selectedPayroll.inssTerceiros
              : null
          }
        //defaultValue={selectedPayroll.inssTerceiros || null}
        />
      </div>
      <div className="input-group-small">
        <p>RAT/FAP</p>
        <CustomController
          name="ratfap"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.ratfap !== null &&
              selectedPayroll.ratfap !== undefined
              ? selectedPayroll.ratfap
              : null
          }
        // defaultValue={selectedPayroll.ratfap || null}
        />
      </div>
      <div className="input-group-small">
        <p>Deduções GPS</p>
        <CustomController
          name="deducoesGps"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.deducoesGps !== null &&
              selectedPayroll.deducoesGps !== undefined
              ? selectedPayroll.deducoesGps
              : null
          }
        //defaultValue={selectedPayroll.deducoesGps || null}
        />
      </div>
      <div className="input-group-small">
        <p>GRRF</p>
        <CustomController
          name="grrf"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.grrf !== null && selectedPayroll.grrf !== undefined
              ? selectedPayroll.grrf
              : null
          }
        //defaultValue={selectedPayroll.grrf || null}
        />
      </div>
      <div className="input-group-small">
        <p>FGTS</p>
        <CustomController
          name="fgts"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.fgts !== null && selectedPayroll.fgts !== undefined
              ? selectedPayroll.fgts
              : null
          }
        // defaultValue={selectedPayroll.fgts || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Encargos Sobre Rescisão</p>
        <CustomController
          name="encargosSobreRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.encargosSobreRescisao !== null &&
              selectedPayroll.encargosSobreRescisao !== undefined
              ? selectedPayroll.encargosSobreRescisao
              : null
          }
        //defaultValue={selectedPayroll.encargosSobreRescisao || null}
        />
      </div>
      <div className="input-group-small">
        <p>FGTS 13º</p>
        <CustomController
          name="fgtsDecimoTerceiro"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.fgtsDecimoTerceiro !== null &&
              selectedPayroll.fgtsDecimoTerceiro !== undefined
              ? selectedPayroll.fgtsDecimoTerceiro
              : null
          }
        //defaultValue={selectedPayroll.fgtsDecimoTerceiro || null}
        />
      </div>
      <div className="input-group-small">
        <p>FGTS Férias</p>
        <CustomController
          name="fgtsFerias"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.fgtsFerias !== null &&
              selectedPayroll.fgtsFerias !== undefined
              ? selectedPayroll.fgtsFerias
              : null
          }
        //defaultValue={selectedPayroll.fgtsFerias || null}
        />
      </div>
      <div className="input-group-small">
        <p>FGTS Rescisão</p>
        <CustomController
          name="fgtsRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.fgtsRescisao !== null &&
              selectedPayroll.fgtsRescisao !== undefined
              ? selectedPayroll.fgtsRescisao
              : null
          }
        //defaultValue={selectedPayroll.fgtsRescisao || null}
        />
      </div>
      <div className="input-group-small">
        <p>PIS S/ Folha</p>
        <CustomController
          name="pisSemFolha"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.pisSemFolha !== null &&
              selectedPayroll.pisSemFolha !== undefined
              ? selectedPayroll.pisSemFolha
              : null
          }
        //defaultValue={selectedPayroll.pisSemFolha || null}
        />
      </div>
      {/* <div className="input-group-small">
        <p>Id Área Atuação</p>
        <CustomController
          name="areaAtuacaoId"
          type="text"
          control={methods.control}
          defaultValue={
            selectedPayroll.areaAtuacaoId !== null &&
            selectedPayroll.areaAtuacaoId !== undefined
              ? selectedPayroll.areaAtuacaoId
              : null
          }
          //defaultValue={selectedPayroll.areaAtuacaoId || null}
        />
      </div> */}
      {/* <div className="input-group-small">
        <p>Base S/ Vinculo</p>
        <CustomController
          name="baseSemVinculos"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseSemVinculos !== null &&
            selectedPayroll.baseSemVinculos !== undefined
              ? selectedPayroll.baseSemVinculos
              : null
          }
        />
      </div> */}
      <div className="input-group-normal">
        <p>Adicional 13º Férias Prop/Resc</p>
        <CustomController
          name="adcDecimoTerceiroFeriasPropResc"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.adcDecimoTerceiroFeriasPropResc !== null &&
              selectedPayroll.adcDecimoTerceiroFeriasPropResc !== undefined
              ? selectedPayroll.adcDecimoTerceiroFeriasPropResc
              : null
          }
        // defaultValue={selectedPayroll.adcDecimoTerceiroFeriasPropResc || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Base INSS Rescisão</p>
        <CustomController
          name="baseInssRescisao"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseInssRescisao !== null &&
              selectedPayroll.baseInssRescisao !== undefined
              ? selectedPayroll.baseInssRescisao
              : null
          }
        //defaultValue={selectedPayroll.baseInssRescisao || null}
        />
      </div>{" "}
      <div className="input-group-normal">
        <p>Base IRRF PLR Anual</p>
        <CustomController
          name="baseIrrfPlrAnual"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.baseIrrfPlrAnual !== null &&
              selectedPayroll.baseIrrfPlrAnual !== undefined
              ? selectedPayroll.baseIrrfPlrAnual
              : null
          }
        //  defaultValue={selectedPayroll.baseIrrfPlrAnual || null}
        />
      </div>
      <div className="input-group-small">
        <p>Especificação</p>
        <CustomController
          name="especificacao"
          type="text"
          control={methods.control}
          defaultValue={
            selectedPayroll.especificacao !== null &&
              selectedPayroll.especificacao !== undefined
              ? selectedPayroll.especificacao
              : null
          }
        //defaultValue={selectedPayroll.especificacao || null}
        />
      </div>
      {/* <div className="input-group-small">
        <p>Id Prestação Conta</p>
        <CustomController
          name="prestacaoContaId"
          type="number"
          control={methods.control}
          defaultValue={selectedPayroll.prestacaoContaId || null}
        />
      </div> */}
      <div className="input-group-normal">
        <p>Abono Pecuniário</p>
        <CustomController
          name="abonoPecuniario"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.abonoPecuniario !== null &&
              selectedPayroll.abonoPecuniario !== undefined
              ? selectedPayroll.abonoPecuniario
              : null
          }
        //defaultValue={selectedPayroll.abonoPecuniario || null}
        />
      </div>
    </>
  );
}
