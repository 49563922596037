import { zodResolver } from "@hookform/resolvers/zod";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  getDeparaClassificacaoPagamentoFinancialRelease,
  getDeparaFonteRecursoFinancialRelease,
  getDeparaTipoDocumentoFinancialRelease,
  getDeparaTipoFornecedorFinancialRelease,
  getDeparaTipoOperacaoFinancialRelease,
  postFinancialRelease,
  putFinancialRelease,
} from "../../../../../Controllers/CustomScreens/FinancialRelease/FinancialReleaseController";
import { getBase64 } from "../../../../../utils/Converters/Base64Converter";
import { ContentProps } from "../useFinancialRelease";
import { FinancialReleaseSchema, FinancialReleaseSchemaProps } from "./schema";

interface UseModalFinancialReleaseProps {
  handleCloseModal: () => void;
  handleGetFinancialRelease: () => void;
  selectedFinancialRelease: ContentProps;
  prestacaoContaId: number;
}
interface DeparaProps {
  cdDepara: number;
  cdDeparaOrigem: string;
  cdDeparaSicap: string;
  dsDeparaOrigem: string;
  dsDeparaSicap: string;
  parceriaId: number;
  tpDepara: string;
}
export const useModalFinancialRelease = ({
  handleCloseModal,
  handleGetFinancialRelease,
  selectedFinancialRelease,
  prestacaoContaId,
}: UseModalFinancialReleaseProps) => {
  const methods = useForm<FinancialReleaseSchemaProps>({
    resolver: zodResolver(FinancialReleaseSchema),
    defaultValues: {
      lancamento: {
        numPagamento:
          selectedFinancialRelease.numPagamento === null
            ? ""
            : selectedFinancialRelease.numPagamento,
        dataEmissao:
          selectedFinancialRelease.dataEmissao === null ||
            selectedFinancialRelease?.dataEmissao === undefined
            ? ""
            : dayjs(selectedFinancialRelease.dataEmissao).format("YYYY-MM-DD"),
        dataPagamento:
          selectedFinancialRelease.dataPagamento === null ||
            selectedFinancialRelease?.dataPagamento === undefined
            ? ""
            : dayjs(selectedFinancialRelease.dataPagamento).format(
              "YYYY-MM-DD"
            ),
        vencimento:
          selectedFinancialRelease.vencimento === null ||
            selectedFinancialRelease.vencimento === undefined
            ? ""
            : dayjs(selectedFinancialRelease.vencimento).format("YYYY-MM-DD"),
        docRetencaoFonte:
          selectedFinancialRelease.docRetencaoFonte === true ? "true" : "false",
        classificacaoPgtoId:
          selectedFinancialRelease.classificacaoPgtoId === "" ||
            selectedFinancialRelease.classificacaoPgtoId === undefined
            ? ""
            : selectedFinancialRelease.classificacaoPgtoId,
        fonteRecursoCod:
          selectedFinancialRelease.fonteRecursoCod === "" ||
            selectedFinancialRelease.fonteRecursoCod === undefined
            ? ""
            : selectedFinancialRelease.fonteRecursoCod,
        tipoDocId:
          selectedFinancialRelease.tipoDocId === "" ||
            selectedFinancialRelease.tipoDocId === undefined
            ? ""
            : selectedFinancialRelease.tipoDocId,
        tipoFornecedorId:
          selectedFinancialRelease.tipoFornecedorId === "" ||
            selectedFinancialRelease.tipoFornecedorId === undefined
            ? ""
            : selectedFinancialRelease.tipoFornecedorId,
        tipoOperacaoId:
          selectedFinancialRelease.tipoOperacaoId === "" ||
            selectedFinancialRelease.tipoOperacaoId === undefined
            ? ""
            : selectedFinancialRelease.tipoOperacaoId,
        parceriaId:
          selectedFinancialRelease.parceriaId !== undefined ||
            selectedFinancialRelease.parceriaId !== null
            ? selectedFinancialRelease.parceriaId
            : null,
        dsErro:
          selectedFinancialRelease.dsErro !== undefined ||
            selectedFinancialRelease.dsErro !== null ||
            selectedFinancialRelease.dsErro !== ""
            ? selectedFinancialRelease.dsErro
            : "",
        prestacaoContaId:
          selectedFinancialRelease.prestacaoContaId !== undefined &&
            selectedFinancialRelease.prestacaoContaId !== null
            ? selectedFinancialRelease.prestacaoContaId
            : prestacaoContaId,
      },
      arquivo: {
        arquivoBase64: null,
        nomeArquivo:
          selectedFinancialRelease.dsNomeArquivoNf !== undefined ||
            selectedFinancialRelease.dsNomeArquivoNf !== null ||
            selectedFinancialRelease.dsNomeArquivoNf !== ""
            ? selectedFinancialRelease.dsNomeArquivoNf
            : null,
      },
    },
  });

  const [deparaTipoOperacao, setDeparaTipoOperacao] = useState<DeparaProps[]>(
    []
  );
  const [deparaTipoDocumento, setDeparaTipoDocumento] = useState<DeparaProps[]>(
    []
  );
  const [deparaClassificacaoPagamento, setDeparaClassificacaoPagamento] =
    useState<DeparaProps[]>([]);
  const [deparaTipoFornecedor, setDeparaTipoFornecedor] = useState<
    DeparaProps[]
  >([]);
  const [deparaFonteRecurso, setDeparaFonteRecurso] = useState<DeparaProps[]>(
    []
  );
  const [fileName, setFileName] = useState(null);
  async function handleGetDeparaTipoOperacao() {
    try {
      const { data } = await getDeparaTipoOperacaoFinancialRelease();
      setDeparaTipoOperacao(data);
    } catch (error) { }
  }
  async function handleGetDeparaTipoDocumento() {
    try {
      const { data } = await getDeparaTipoDocumentoFinancialRelease();
      setDeparaTipoDocumento(data);
    } catch (error) { }
  }
  async function handleGetDeparaClassificacaoPagamento() {
    try {
      const { data } = await getDeparaClassificacaoPagamentoFinancialRelease();
      setDeparaClassificacaoPagamento(data);
    } catch (error) { }
  }
  async function handleGetDeparaTipoFornecedor() {
    try {
      const { data } = await getDeparaTipoFornecedorFinancialRelease();
      setDeparaTipoFornecedor(data);
    } catch (error) { }
  }
  async function handleGetDeparaFonteRecurso() {
    try {
      const { data } = await getDeparaFonteRecursoFinancialRelease();
      const fonteRecurso = data.filter(
        (item) => item.tpDepara === "fonteRecurso"
      );
      setDeparaFonteRecurso(fonteRecurso);
    } catch (error) { }
  }

  useEffect(() => {
    handleGetDeparaTipoOperacao();
    handleGetDeparaTipoDocumento();
    handleGetDeparaClassificacaoPagamento();
    handleGetDeparaTipoFornecedor();
    handleGetDeparaFonteRecurso();
  }, []);

  async function handleSubmitForm(form: FinancialReleaseSchemaProps) {
    if (selectedFinancialRelease.cdDocFinanceiro === undefined) {
      try {
        await postFinancialRelease(form);
        methods.reset();
        handleCloseModal();
        handleGetFinancialRelease();
        toast.success("Lançamento financeiro cadastrado com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao cadastrar lançamento financeiro, por favor verifique os campos preenchidos!"
        );
      }
    } else {
      try {

        await putFinancialRelease(form, selectedFinancialRelease);
        methods.reset();
        handleCloseModal();
        handleGetFinancialRelease();
        toast.success("Lançamento financeiro editado com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao editar lançamento financeiro, por favor verifique os campos preenchidos!"
        );
      }
    }
  }

  async function handleFileUpload(form: FileList) {
    await getBase64(form[0])
      .then((result) => {
        methods.setValue("arquivo.arquivoBase64", String(result));
        methods.setValue("arquivo.nomeArquivo", form[0].name);
      })
      .catch((error) => {
        return;
      });
    setFileName(form[0].name);

    return;
  }

  return {
    handleSubmitForm,
    methods,
    deparaTipoOperacao,
    deparaTipoDocumento,
    deparaClassificacaoPagamento,
    deparaTipoFornecedor,
    deparaFonteRecurso,
    handleFileUpload,
    fileName,
  };
};
