import { z } from "zod";

export const CreateDeparaSchema = z.object({
  tp_depara: z.string().nullable().default(""),
  cd_multi_empresa: z.string().default(""),
  cd_depara_mv: z.string().min(1).default(""),
  ds_depara_mv: z.string().default(""),
  cd_depara_integra: z.string().min(1).default(""),
  ds_depara_integra: z.string().default(""),
  cd_sistema_integra: z.string().default("RM"),
});

export type CreateDeparaSchemaProps = z.infer<typeof CreateDeparaSchema>;
