import { z } from "zod";

export const PositionSalarySchema = z.object({
  descricaoCargo: z.string().nullable(),
  valorSalario: z.string().nullable(),
  convencaoColetiva: z.string().nullable(),
  numeroHorasSemanais: z.number().nullable(),
  numeroHorasMes: z.number().nullable(),
  nivel: z.string().nullable(),
  sindicato: z.string().nullable(),
  comentarios: z.string().nullable(),
  codigoCbo: z.string().nullable(),
  parceriaId: z.number().nullable().optional(),
  prestacaoContaId: z.number().optional(),
});

export type PositionSalarySchemaProps = z.infer<typeof PositionSalarySchema>;
