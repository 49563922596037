import { useContext, useEffect } from "react";

import { CarouselItem } from "../../components/Carousel";
import { Header } from "../../components/Header";
import { SideBar } from "../../components/SideBar";
import { PointsContext } from "../../contexts/PointsContext";

import logo from "../../assets/LogoArgo.svg";

import { GlobalContext } from "../../contexts/GlobalContext";
import "./styles.scss";

export function Carousel() {
  const { points, loading, nrDiasAnalise } = useContext(PointsContext);
  const { handleGetChartData, dadosGrafico } = useContext(GlobalContext);

  useEffect(() => {
    if (dadosGrafico.length === 0) {
      handleGetChartData();
    }
  }, []);

  return (
    <>
      <SideBar />

      <Header dayValue={nrDiasAnalise} showLogo />

      {loading === true && (
        <div className="divLoading">
          <h1 className="loading">Carregando...</h1>
        </div>
      )}
      <div className="dashboard">
        <div className="carousel" id="carousel">
          {points.map((point) =>
            Object.keys(point).map((key) => (
              <div className="column-empresa">
                {point[key].map((item) => (
                  <div className="item-carousel">
                    <CarouselItem
                      key={item.cdPontoIntegracaoSumario}
                      cdPontoIntegracaoSumario={item.cdPontoIntegracaoSumario}
                      cdEmpresaPontoIntegracao={
                        item.empresaPontoIntegracao.cdEmpresaPontoIntegracao
                      }
                      nmNome={item.empresaPontoIntegracao.nmNome}
                      // nomeEmpresa={point.nomeEmpresa}
                      nrErro={item.nrErro}
                      nrSucesso={item.nrSucesso}
                      nrAguardando={item.nrAguardando}
                      dhExecucao={item.dhExecucao}
                      nrDiasAnalise={item.nrDiasAnalise}
                      snDetalheAguardando={
                        item.empresaPontoIntegracao.snDetalheAguardando
                      }
                      snDetalheSucesso={
                        item.empresaPontoIntegracao.snDetalheSucesso
                      }
                    />
                  </div>
                ))}
              </div>
            ))
          )}
          {points.length === 0 && <img src={logo} alt="" />}
        </div>
      </div>
    </>
  );
}
