import { ImportConfig } from "../../../components/CustomScreens/totvs/BankAccount/ModalImport/useModalImport";
import { EmployeesSchemaProps } from "../../../components/CustomScreens/totvs/Employees/ModalEmployees/schema";
import { ContentProps } from "../../../components/CustomScreens/totvs/Employees/useEmployees";
import { customScreenApi } from "../../../services/api";

export const getEmployees = async (
  page?: number,
  cpf?: string,
  matricula?: string,
  unidade?: string
) => {
  const response = await customScreenApi.get(`/funcionarios`, {
    params: {
      page: page || 0,
      size: 7,
      cpf: cpf || "",
      matricula: matricula || "",
      unidade: unidade || "",
    },
  });
  return response;
};

export const postEmployees = async (data: EmployeesSchemaProps) => {
  const response = await customScreenApi.post("/funcionarios", {
    ...data,
    dataAdmissao:
      data.dataAdmissao === "" ? "" : `${data.dataAdmissao}T00:00:00`,
    dataDemissao:
      data.dataDemissao === "" ? "" : `${data.dataDemissao}T00:00:00`,
    dataNascimento:
      data.dataNascimento === "" ? "" : `${data.dataNascimento}T00:00:00`,
    tpStatus: "A",
    parceriaId: sessionStorage.getItem("parceriaId"),
  });
  return response;
};
export const putEmployees = async (
  data: EmployeesSchemaProps,
  selectedEmployees: ContentProps
) => {
  const response = await customScreenApi.put("/funcionarios", {
    ...data,
    cdFuncionario: selectedEmployees.cdFuncionario,
    tpStatus: selectedEmployees.tpStatus,
    dataAdmissao:
      data.dataAdmissao === "" ? "" : `${data.dataAdmissao}T00:00:00`,
    dataDemissao:
      data.dataDemissao === "" ? "" : `${data.dataDemissao}T00:00:00`,
    dataNascimento:
      data.dataNascimento === "" ? "" : `${data.dataNascimento}T00:00:00`,
  });
  return response;
};

export const deleteEmployees = async (cdFuncionario: number) => {
  const response = await customScreenApi.delete(
    `/funcionarios/${cdFuncionario}`
  );
  return response;
};
export const putImportEmployees = async (listImports: any) => {
  const response = await customScreenApi.put(
    `/funcionarios/importar
    `,
    {},
    {
      headers: {
        cdFuncionarios: listImports,
      },
    }
  );
  return response;
};
export const getDeparaGeneroEmployees = async () => {
  const response = await customScreenApi.get("/depara/declaracaoGenero");
  return response;
};
export const getDeparaTipoAtividadeEmployees = async () => {
  const response = await customScreenApi.get("/depara/atividade");
  return response;
};
export const getDeparaTipoColaboradorEmployees = async () => {
  const response = await customScreenApi.get("/depara/colaborador");
  return response;
};
export const getDeparaTipoFolhaEmployees = async () => {
  const response = await customScreenApi.get("/depara/folha");
  return response;
};
export const getDeparaRegimeContratacaoEmployees = async () => {
  const response = await customScreenApi.get("/depara/regimeContratacao");
  return response;
};
export const postImportEmployees = async (importObject: ImportConfig) => {
  const response = await customScreenApi.post(
    `/funcionarios/buscaRegistroTotvs`,
    {
      ...importObject,
      parceriaId: sessionStorage.getItem("parceriaId"),
    }
  );
  return response;
};
