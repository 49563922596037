import { AccountPlanSchemaProps } from "../../../../components/CustomScreens/totvs/FinancialRelease/AccountPlan/ModalAccountPlan/schema";
import { customScreenApi } from "../../../../services/api";

export const getAccountPlan = async (numPagamento: number, page?: number) => {
  const response = await customScreenApi.get(
    `/lancamentosFinanceiros/planoContas/doc/${numPagamento}`,
    {
      params: {
        page: page || 0,
        size: 7,
      },
    }
  );
  return response;
};

export const postAccountPlan = async (data: AccountPlanSchemaProps) => {
  const response = await customScreenApi.post(
    "/lancamentosFinanceiros/planoContas",
    {
      ...data,
      numPagamento: data.numPagamento === "" ? null : Number(data.numPagamento),
    }
  );
  return response;
};

export const putAccountPlan = async (
  data: AccountPlanSchemaProps,
  cdPlanoConta: number
) => {
  const response = await customScreenApi.put(
    "/lancamentosFinanceiros/planoContas",
    {
      ...data,
      cdPlanoConta: cdPlanoConta,
    }
  );
  return response;
};

export const deleteAccountPlan = async (cdPlanoConta: number) => {
  const response = await customScreenApi.delete(
    `/lancamentosFinanceiros/planoContas/${cdPlanoConta}`
  );
  return response;
};
