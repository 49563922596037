import { Dialog, Pagination } from "@mui/material";
import Button from "@mui/material/Button";

import dayjs from "dayjs";
import { FaPlus } from "react-icons/fa";
import { Header } from "../../../Header";
import { ModalImport } from "./ModalImport";
import "./styles.scss";
import { useAccountability } from "./useAccountability";

export default function Accountability() {
  const {
    accountability,
    handlePaginate,
    handleGetAccountability,
    showModalImport,
    setShowModalImport,
  } = useAccountability();
  return (
    <div className="Accountability-container">
      <Header
        message={"Dashboard/Prestação de Contas"}
        showAtualizacao={true}
      />
      <main>
        <div className="Accountability-main-card">
          <header className="Accountability-main-card-header">
            <h2>Prestação de Contas</h2>

            <aside>
              <Button
                variant="contained"
                onClick={() => setShowModalImport(true)}
              >
                Abrir <FaPlus />
              </Button>
              {/* <Button
                variant="contained"
                onClick={() => handleFinishAccountability()}
                disabled={selectedAccountability.cdPrestacao === undefined}
              >
                FINALIZAR <IoClose />
              </Button> */}
            </aside>
            <Dialog
              open={showModalImport}
              onClose={() => setShowModalImport(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalImport
                handleCloseModal={() => setShowModalImport(false)}
                handleGetAccountability={handleGetAccountability}
              />
            </Dialog>
          </header>
          <section className="Accountability-main-card-section">
            <table>
              <thead>
                <tr>
                  {/* <th colSpan={1}></th>
                  <th>Status</th> */}
                  <th>Ds Competência</th>
                  <th>Ds Descrição</th>
                  <th>Prestação Conta Id</th>
                </tr>
              </thead>
              <tbody>
                {accountability?.content?.map((account, index) => (
                  <tr key={index}>
                    {/* <td>
                      {account.tpStatus !== "F" && (
                        <Checkbox
                          color="primary"
                          size="small"
                          onChange={() => {
                            handleSelectFinishAccountability(account);
                          }}
                          checked={
                            selectedAccountability.cdPrestacao ===
                            account.cdPrestacao
                          }
                        />
                      )}
                    </td>
                    <td>
                      {account.tpStatus === "A" && (
                        <div className="circle-status">
                          <span
                            data-tip="Aguardando"
                            className="circle-aguardando"
                          ></span>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      )}
                      {account.tpStatus === "I" && (
                        <div className="circle-status">
                          <span
                            data-tip="Importado"
                            className="circle-importado"
                          ></span>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      )}
                      {account.tpStatus === "T" && (
                        <div className="circle-status">
                          <span
                            data-tip="Transferido"
                            className="circle-transferido"
                          ></span>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      )}
                      {account.tpStatus === "E" && (
                        <div className="circle-status">
                          <span
                            data-tip={
                              account?.dsErro?.length < 100
                                ? account?.dsErro
                                : account?.dsErro?.substring(0, 100) + "..."
                            }
                            className="circle-error"
                          ></span>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      )}
                      {account.tpStatus === "F" && (
                        <div className="circle-status">
                          <span
                            data-tip="Finalizado"
                            className="circle-finalizado"
                          ></span>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      )}
                    </td> */}

                    <td>
                      {account.dsCompetencia !== null
                        ? dayjs(
                            new Date(
                              account.dsCompetencia[0], // Ano
                              account.dsCompetencia[1] - 1, // Mês (precisa subtrair 1, pois começa do 0)
                              account.dsCompetencia[2], // Dia
                              account.dsCompetencia[3], // Hora
                              account.dsCompetencia[4] // Minutos
                            )
                          ).format("DD/MM/YYYY")
                        : ""}
                    </td>

                    <td>{account.dsDescricao}</td>
                    <td>{account.prestacaoContaId}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </section>
          <footer>
            <Pagination
              count={accountability.totalPages}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginate}
            />
          </footer>
        </div>
      </main>
    </div>
  );
}
