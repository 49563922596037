import { CreateDeparaSchemaProps } from "../../../components/CustomScreens/unifacisa/deparas/dialogs/createDepara/schema";
import { UpdateDeparaSchemaProps } from "../../../components/CustomScreens/unifacisa/deparas/dialogs/updateDepara/schema";
import { customScreenUnifacisaApi } from "../../../services/api";

export const getAllDeparas = async (
  tp_depara?: string,
  cd_multi_empresa?: number,
  cd_depara_mv?: string,
  cd_depara_integra?: string,
  page?: number
) => {
  const response = await customScreenUnifacisaApi.get("/listDepara", {
    headers: {
      tp_depara: tp_depara || "",
      cd_multi_empresa: cd_multi_empresa || "",
      cd_depara_mv: cd_depara_mv || "",
      cd_depara_integra: cd_depara_integra || "",
      page: page || 1,
      limit: 7,
      user: "PainelUnifacisaUser",
      password: "Un1f@c1$@Dt!u$eRd2t@1&Gr2",
    },
  });
  return response;
};

export const postNewDepara = async (data: CreateDeparaSchemaProps) => {
  const response = await customScreenUnifacisaApi.post("/insertDepara", {
    ...data,
    user: "PainelUnifacisaUser",
    password: "Un1f@c1$@Dt!u$eRd2t@1&Gr2",
  });
  return response;
};

export const deleteDepara = async (cd_dti_depara: number, sn_ativo: string) => {
  const response = await customScreenUnifacisaApi.post(
    "/deleteDepara",
    {
      cd_dti_depara: cd_dti_depara,
      sn_ativo: sn_ativo,
    },
    {
      headers: {
        user: "PainelUnifacisaUser",
        password: "Un1f@c1$@Dt!u$eRd2t@1&Gr2",
      },
    }
  );
  return response;
};

export const updateDepara = async (data: UpdateDeparaSchemaProps) => {
  const response = await customScreenUnifacisaApi.post(
    "/updateDepara",
    {
      ...data,
    },
    {
      headers: {
        user: "PainelUnifacisaUser",
        password: "Un1f@c1$@Dt!u$eRd2t@1&Gr2",
      },
    }
  );
  return response;
};
