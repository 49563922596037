import {
  Checkbox,
  CircularProgress,
  Dialog,
  LinearProgress,
  Pagination,
} from "@mui/material";
import Button from "@mui/material/Button";
import { BsPencilSquare } from "react-icons/bs";
import { VscDebugRestart } from "react-icons/vsc";
import iconOcultar from "../../../../assets/iconOcultar.svg";

import { FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import { Header } from "../../../Header";
import { ConfirmExclude } from "../ConfirmExclude";
import { ModalBankAccount } from "./ModalBankAccount";
import { ModalImport } from "./ModalImport";
import "./styles.scss";
import { ContentProps, useBankAccount } from "./useBankAccount";

export default function BankAccount() {
  const {
    modalState,
    setModalState,
    bankAccountList,
    handleEditModal,
    tblDtiCdBanco,
    handleGetBankAccount,
    handleDeleteBankAccount,
    selectedBankAccount,
    setSelectedBankAccount,
    handlePaginate,
    isLoading,
    handleImportRegisters,
    selectedImportRegisters,
    handleSubmitImport,
    page,
    allSelected,
    setAllSelected,
    setFilterRegisters,
    filterRegisters,
    installmentList,
    setShowModalExclude,
    showModalExclude,
    bankToDelete,
    setBankToDelete,
    showModalImport,
    setShowModalImport,
    selectedInstallment,
    setSelectedInstallment,
    setBankAccountList,
  } = useBankAccount();

  return (
    <div className="BankAccount-container">
      <Header message={"Dashboard/Contas Bancárias"} showAtualizacao={true} />
      <main>
        <div className="BankAccount-main-card">
          <h2>Contas Bancárias</h2>

          <header className="BankAccount-main-card-header">
            <aside className="BankAccount-main-card-filters">
              <input
                type="text"
                placeholder="Conta Bancária"
                onBlur={(e) => {
                  setFilterRegisters({
                    ...filterRegisters,
                    dsContaBancaria: e.target.value,
                  });
                }}
              />
              <input
                type="text"
                placeholder="Núm Conta Contábil"
                onBlur={(e) => {
                  setFilterRegisters({
                    ...filterRegisters,
                    contaContabil: e.target.value,
                  });
                }}
              />
              <select
                onChange={(e) => {
                  setFilterRegisters({
                    ...filterRegisters,
                    prestacaoContaId: Number(e.target.value),
                  });
                  setSelectedInstallment(Number(e.target.value));
                }}
              >
                {installmentList.map((installment) => (
                  <option value={installment.prestacaoContaId}>
                    {installment.vigencia}
                  </option>
                ))}
              </select>
              <button
                type="button"
                className="filter-button"
                onClick={() =>
                  handleGetBankAccount(
                    0,
                    filterRegisters.dsContaBancaria,
                    filterRegisters.contaContabil,
                    filterRegisters.prestacaoContaId ||
                    installmentList[0].prestacaoContaId
                  )
                }
              >
                Filtrar
              </button>
            </aside>
            <div className="header-buttons">
              <aside>
                <Button
                  variant="contained"
                  onClick={() => setShowModalImport(true)}
                >
                  Importar Totvs <FaPlus />
                </Button>
              </aside>
              <aside>
                <Button variant="outlined" onClick={() => setModalState(true)}>
                  Novo Registro <FaPlus />
                </Button>
              </aside>
              <aside>
                <Button
                  variant="contained"
                  onClick={() => handleSubmitImport()}
                  disabled={!allSelected && selectedImportRegisters.length <= 0}
                >
                  Enviar Sicap <FaPlus />
                </Button>
                <Checkbox
                  color="primary"
                  size="small"
                  title="Todos Registros"
                  checked={allSelected}
                  onChange={() => setAllSelected(!allSelected)}
                />{" "}
                Todos Registros
              </aside>
              <div className="header-buttons-restart">
                {isLoading ? (
                  <CircularProgress
                    style={{
                      color: "white",
                      animation: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                ) : (
                  <VscDebugRestart
                    data-tip="Atualizar"
                    onClick={() =>
                      handleGetBankAccount(
                        page,
                        filterRegisters.dsContaBancaria,
                        filterRegisters.contaContabil,
                        filterRegisters.prestacaoContaId ||
                        installmentList[0].prestacaoContaId
                      )
                    }
                  />
                )}
              </div>
            </div>
          </header>
          <section className="BankAccount-main-card-section">
            <Dialog
              open={modalState}
              onClose={() => (
                setModalState(false), setSelectedBankAccount({} as ContentProps)
              )}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalBankAccount
                handleCloseModal={() => (
                  setModalState(false),
                  setSelectedBankAccount({} as ContentProps)
                )}
                tblDtiCdBanco={tblDtiCdBanco ? tblDtiCdBanco : null}
                handleGetBankAccount={() =>
                  handleGetBankAccount(
                    page,
                    filterRegisters.dsContaBancaria,
                    filterRegisters.contaContabil,
                    filterRegisters.prestacaoContaId ||
                    installmentList[0].prestacaoContaId
                  )
                }
                selectedBankAccount={selectedBankAccount}
                prestacaoContaId={selectedInstallment}
              />
            </Dialog>
            <Dialog
              open={showModalExclude}
              onClose={() => setShowModalExclude(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ConfirmExclude
                handleCloseModal={() => setShowModalExclude(false)}
                handleSubmit={() =>
                  handleDeleteBankAccount(bankToDelete.tblDtiCdBanco)
                }
              />
            </Dialog>
            <Dialog
              open={showModalImport}
              onClose={() => setShowModalImport(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalImport
                handleCloseModal={() => setShowModalImport(false)}
                installmentList={installmentList}
                setNewList={setBankAccountList}
              />
            </Dialog>
            {!isLoading ? (
              <table>
                <thead>
                  <tr>
                    <th colSpan={1}></th>
                    <th>Id Registro</th>
                    <th>Status</th>
                    <th>Conta Bancária</th>
                    <th>Id</th>
                    <th>Conta aplicação</th>
                    <th>Conta contábil</th>
                    <th>Conta digito</th>
                    <th>Agencia</th>
                    <th>Agencia digito</th>
                    <th>Numero da conta</th>
                    <th>Saldo Anterior</th>
                    <th colSpan={1}></th>
                  </tr>
                </thead>
                <tbody>
                  {bankAccountList?.content?.map((bank) => (
                    <tr key={bank.tblDtiCdBanco}>
                      <td>
                        {(bank.tpStatus === "A" || bank.tpStatus === "E") && (
                          <Checkbox
                            color="primary"
                            size="small"
                            onChange={() => {
                              handleImportRegisters(bank.tblDtiCdBanco);
                            }}
                            checked={selectedImportRegisters.some(
                              (item) => item === bank.tblDtiCdBanco
                            )}
                          />
                        )}
                      </td>
                      <td>{bank.tblDtiCdBanco}</td>
                      <td>
                        {bank.tpStatus === "A" && (
                          <div className="circle-status">
                            <span
                              data-tip="Aguardando"
                              className="circle-aguardando"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {bank.tpStatus === "I" && (
                          <div className="circle-status">
                            <span
                              data-tip="Importado"
                              className="circle-importado"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {bank.tpStatus === "T" && (
                          <div className="circle-status">
                            <span
                              data-tip="Transferido"
                              className="circle-transferido"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {bank.tpStatus === "E" && (
                          <div className="circle-status">
                            <span
                              data-tip={
                                bank?.dsErro?.length < 100
                                  ? bank?.dsErro
                                  : bank?.dsErro?.substring(0, 100) + "..."
                              }
                              className="circle-error"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {bank.tpStatus === "F" && (
                          <div className="circle-status">
                            <span
                              data-tip="Finalizado"
                              className="circle-finalizado"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                      </td>
                      <td>{bank.dsContaBancaria}</td>
                      <td>{bank.id}</td>
                      <td>{bank.contaAplicacao === true ? "Sim" : "Não"}</td>
                      <td>{bank.contaContabil}</td>
                      <td>{bank.contaDigito}</td>
                      <td>{bank.dsAgencia}</td>
                      <td>{bank.dsAgenciaDigito}</td>
                      <td>{bank.numeroContaBancaria}</td>
                      <td>{bank.saldoAnterior}</td>
                      <td>
                        <div className="BankAccount-main-card-section-icons">
                          <p
                            data-tip="Editar Registro"
                            onClick={() => handleEditModal(bank)}
                          >
                            <BsPencilSquare color="#D8A511" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                          <p
                            data-tip="Excluir Registro"
                            onClick={() => {
                              setBankToDelete(bank);
                              setShowModalExclude(true);
                            }}
                          >
                            <img src={iconOcultar} alt="" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <LinearProgress />
            )}
          </section>
          <footer>
            <Pagination
              count={bankAccountList.totalPages}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginate}
            />
          </footer>
        </div>
      </main>
    </div>
  );
}
