import { RxCross1 } from "react-icons/rx";
import { CustomController } from "../../../../../../utils/FormController/FormController";
import { ContentProps } from "../useAggregateUnits";
import "./styles.scss";
import { useModalAggregateUnits } from "./useModalAggregateUnits";

interface ModalRegisterComponentProps {
  handleCloseModal: () => void;
  selectedAggregateUnits: ContentProps;
  handleGetAggregateUnits: () => void;
}
export function ModalAggregateUnits({
  handleCloseModal,
  selectedAggregateUnits,
  handleGetAggregateUnits,
}: ModalRegisterComponentProps) {
  const { handleSubmitForm, methods, deparaTipoDocumento } =
    useModalAggregateUnits({
      handleCloseModal: handleCloseModal,
      selectedAggregateUnits: selectedAggregateUnits,
      handleGetAggregateUnits: handleGetAggregateUnits,
    });

  return (
    <div className="modal-register-container-agregate">
      <header>
        <RxCross1 onClick={() => handleCloseModal()} />
      </header>
      <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <main>
          <div className="input-group-small">
            <p> Id linha serviço</p>
            <CustomController
              name="linhaServicoId"
              type="text"
              control={methods.control}
              defaultValue={selectedAggregateUnits.linhaServicoId || null}
            />
          </div>{" "}
          <div className="input-group-small">
            <p>Número pagamento</p>
            <CustomController
              name="numPagamento"
              type="text"
              control={methods.control}
              defaultValue={selectedAggregateUnits.numPagamento || null}
            />
          </div>
          <div className="input-group-small">
            <p>Quantidade</p>
            <CustomController
              name="quantidade"
              type="number"
              control={methods.control}
              defaultValue={selectedAggregateUnits.quantidade || null}
            />
          </div>
          <div className="input-group-small">
            <p>Id unidade</p>
            <CustomController
              name="unidadeId"
              type="text"
              control={methods.control}
              defaultValue={selectedAggregateUnits.unidadeId || null}
            />
          </div>
          <div className="input-group-small">
            <p>Valor</p>

            <CustomController
              name="valor"
              type="number"
              control={methods.control}
              defaultValue={selectedAggregateUnits.valor || null}
              hasStep
            />
          </div>
          <div className="input-group-small">
            <p>Tipo despesa</p>
            {/* <input {...methods.register("tipoDespesaId")} /> */}

            <select {...methods.register("tipoDespesaId")}>
              <option hidden value={selectedAggregateUnits.tipoDespesaId || ""}>
                {selectedAggregateUnits.tipoDespesaId !== null
                  ? deparaTipoDocumento.find(
                    (tipo) =>
                      tipo.cdDeparaSicap ===
                      selectedAggregateUnits.tipoDespesaId
                  )?.dsDeparaSicap || "Selecione"
                  : "Selecione"}
              </option>
              {deparaTipoDocumento.map((tipoOperacao) => (
                <option
                  key={tipoOperacao.cdDepara}
                  value={tipoOperacao.cdDeparaSicap}
                >
                  {tipoOperacao.dsDeparaSicap}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group-small">
            <p>Id material equipamento</p>
            <CustomController
              name="materialEquipamentoId"
              type="text"
              control={methods.control}
              defaultValue={
                selectedAggregateUnits.materialEquipamentoId || null
              }
            />
          </div>
        </main>
        <footer>
          <button type="submit" disabled={methods.formState.isSubmitting}>
            Confirmar
          </button>

          <button onClick={() => handleCloseModal()} type="button">
            Cancelar
          </button>
        </footer>
      </form>
    </div>
  );
}
