import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getDeparaAutoDeclaracaoGeneroPayroll,
  getDeparaAutoDeclaracaoRacialPayroll,
  getDeparaRegimeContratacaoPayroll,
  getDeparaTipoAtividadePayroll,
  getDeparaTipoColaboradorPayroll,
  getDeparaTipoCoordenadoriaPayroll,
  getDeparaTipoFolhaPayroll,
  getDeparaTipoJornadaPayroll,
  getNameByCPF,
  postPayroll,
  putPayroll,
} from "../../../../../Controllers/CustomScreens/Payroll/PayrollController";
import { cpfFormatter } from "../../../../../utils/Formatters/Formatters";
import { ContentProps } from "../usePayroll";
import { PayrollSchema, PayrollSchemaProps } from "./schema";
interface DeparaProps {
  cdDepara: number;
  cdDeparaOrigem: string;
  cdDeparaSicap: string;
  dsDeparaOrigem: string;
  dsDeparaSicap: string;
  parceriaId: number;
  tpDepara: string;
}

interface EmployeeData {
  atividade: string;
  cdFuncionario: number;
  cns: string;
  colaborador: string;
  cpf: string;
  dataAdmissao: string;
  dataDemissao: string;
  dataNascimento: string;
  folha: string;
  genero: string;
  jornada: number;
  nome: string;
  regimeContratacao: string;
}
interface UseModalPayrollProps {
  handleCloseModal?: () => void;
  handleGetPayroll?: () => void;
  selectedPayroll?: ContentProps;
  isEditing?: boolean;
  prestacaoContaId?: number;
}
export const useModalPayroll = ({
  handleCloseModal,
  handleGetPayroll,
  selectedPayroll,
  isEditing,
  prestacaoContaId,
}: UseModalPayrollProps) => {
  const [carrouselPage, setCarrouselPage] = useState<number>(0);
  const methods = useForm<PayrollSchemaProps>({
    resolver: zodResolver(PayrollSchema),
    // criteriaMode: "all",
    // mode: "all",
    defaultValues: {
      dataAdmissao: selectedPayroll?.dataAdmissao
        ? dayjs(
            new Date(
              selectedPayroll.dataAdmissao[0], // Ano
              selectedPayroll.dataAdmissao[1] - 1, // Mês (precisa subtrair 1, pois começa do 0)
              selectedPayroll.dataAdmissao[2], // Dia
              selectedPayroll.dataAdmissao[3], // Hora
              selectedPayroll.dataAdmissao[4] // Minutos
            )
          ).format("YYYY-MM-DD")
        : "",
      dataDesligamento: selectedPayroll?.dataDesligamento
        ? dayjs(
            new Date(
              selectedPayroll.dataDesligamento[0], // Ano
              selectedPayroll.dataDesligamento[1] - 1, // Mês (precisa subtrair 1, pois começa do 0)
              selectedPayroll.dataDesligamento[2], // Dia
              selectedPayroll.dataDesligamento[3], // Hora
              selectedPayroll.dataDesligamento[4] // Minutos
            )
          ).format("YYYY-MM-DD")
        : "",
      dataNascimento: selectedPayroll?.dataNascimento
        ? dayjs(
            new Date(
              selectedPayroll.dataNascimento[0], // Ano
              selectedPayroll.dataNascimento[1] - 1, // Mês (precisa subtrair 1, pois começa do 0)
              selectedPayroll.dataNascimento[2], // Dia
              selectedPayroll.dataNascimento[3], // Hora
              selectedPayroll.dataNascimento[4] // Minutos
            )
          ).format("YYYY-MM-DD")
        : "",
      parceriaId:
        selectedPayroll?.parceriaId !== undefined ||
        selectedPayroll?.parceriaId !== null
          ? selectedPayroll?.parceriaId
          : null,
      prestacaoContaId:
        selectedPayroll?.prestacaoContaId !== undefined &&
        selectedPayroll?.prestacaoContaId !== null
          ? selectedPayroll?.prestacaoContaId
          : prestacaoContaId,
      autoDeclaracaoGenero:
        selectedPayroll?.autoDeclaracaoGenero === "" ||
        selectedPayroll?.autoDeclaracaoGenero === undefined
          ? ""
          : selectedPayroll?.autoDeclaracaoGenero,
      autoDeclaracaoRacial:
        selectedPayroll?.autoDeclaracaoRacial === "" ||
        selectedPayroll?.autoDeclaracaoRacial === undefined
          ? ""
          : selectedPayroll?.autoDeclaracaoRacial,
      regimeContratacao:
        selectedPayroll?.regimeContratacao === "" ||
        selectedPayroll?.regimeContratacao === undefined
          ? ""
          : selectedPayroll?.regimeContratacao,
      tipoFolha:
        selectedPayroll?.tipoFolha === "" ||
        selectedPayroll?.tipoFolha === undefined
          ? ""
          : selectedPayroll?.tipoFolha,

      tipoColaborador:
        selectedPayroll?.tipoColaborador === "" ||
        selectedPayroll?.tipoColaborador === undefined
          ? ""
          : selectedPayroll?.tipoColaborador,
      tipoCoordenadoria:
        selectedPayroll?.tipoCoordenadoria === null ||
        selectedPayroll?.tipoCoordenadoria === undefined
          ? null
          : selectedPayroll?.tipoCoordenadoria,
      tipoAtividade:
        selectedPayroll?.tipoAtividade === null ||
        selectedPayroll?.tipoAtividade === undefined
          ? null
          : selectedPayroll?.tipoAtividade,
      tipoJornada:
        selectedPayroll?.tipoJornada === null ||
        selectedPayroll?.tipoJornada === undefined
          ? null
          : selectedPayroll?.tipoJornada,
    },
  });

  async function handleSubmitForm(form: PayrollSchemaProps) {
    if (selectedPayroll.cdFolhaPagamento === undefined) {
      try {
        await postPayroll(form);
        methods.reset();
        handleCloseModal();
        handleGetPayroll();
        toast.success("Folha de pagamento cadastrada com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao cadastrar Folha de pagamento, por favor verifique os campos preenchidos!"
        );
      }
    } else {
      try {
        await putPayroll(form, selectedPayroll);
        methods.reset();
        handleCloseModal();
        handleGetPayroll();
        toast.success("Folha de pagamento  editado com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao editar Folha de pagamento, por favor verifique os campos preenchidos!"
        );
      }
    }
  }

  const [deparaAutoDeclaracaoGenero, setDeparaAutoDeclaracaoGenero] = useState<
    DeparaProps[]
  >([]);
  const [deparaAutoDeclaracaoRacial, setDeparaAutoDeclaracaoRacial] = useState<
    DeparaProps[]
  >([]);

  const [deparaRegimeContratacao, setDeparaRegimeContratacao] = useState<
    DeparaProps[]
  >([]);

  const [deparaTipoJornada, setDeparaTipoJornada] = useState<DeparaProps[]>([]);

  const [deparaTipoFolha, setDeparaTipoFolha] = useState<DeparaProps[]>([]);

  const [deparaTipoColaborador, setDeparaTipoColaborador] = useState<
    DeparaProps[]
  >([]);

  const [deparaTipoCoordenadoria, setDeparaTipoCoordenadoria] = useState<
    DeparaProps[]
  >([]);

  const [deparaTipoAtividade, setDeparaTipoAtividade] = useState<DeparaProps[]>(
    []
  );

  async function handleGetDeparaAutoDeclaracaoGenero() {
    try {
      const { data } = await getDeparaAutoDeclaracaoGeneroPayroll();
      setDeparaAutoDeclaracaoGenero(data);
    } catch (error) {}
  }
  async function handleGetDeparaAutoDeclaracaoRacial() {
    try {
      const { data } = await getDeparaAutoDeclaracaoRacialPayroll();
      setDeparaAutoDeclaracaoRacial(data);
    } catch (error) {}
  }
  async function handleGetDeparaRegimeContratacao() {
    try {
      const { data } = await getDeparaRegimeContratacaoPayroll();
      setDeparaRegimeContratacao(data);
    } catch (error) {}
  }
  async function handleGetTipoJornada() {
    try {
      const { data } = await getDeparaTipoJornadaPayroll();
      setDeparaTipoJornada(data);
    } catch (error) {}
  }
  async function handleGetTipoFolha() {
    try {
      const { data } = await getDeparaTipoFolhaPayroll();
      setDeparaTipoFolha(data);
    } catch (error) {}
  }
  async function handleGetTipoColaborador() {
    try {
      const { data } = await getDeparaTipoColaboradorPayroll();
      setDeparaTipoColaborador(data);
    } catch (error) {}
  }
  async function handleGetTipoCoordenadoria() {
    try {
      const { data } = await getDeparaTipoCoordenadoriaPayroll();
      setDeparaTipoCoordenadoria(data);
    } catch (error) {}
  }
  async function handleGetTipoAtividade() {
    try {
      const { data } = await getDeparaTipoAtividadePayroll();
      setDeparaTipoAtividade(data);
    } catch (error) {}
  }

  useEffect(() => {
    if (selectedPayroll?.cdFolhaPagamento === undefined) {
      methods.reset();
    }
    handleGetDeparaAutoDeclaracaoGenero();
    handleGetDeparaAutoDeclaracaoRacial();
    handleGetDeparaRegimeContratacao();
    handleGetTipoJornada();
    handleGetTipoFolha();
    handleGetTipoColaborador();
    handleGetTipoCoordenadoria();
    handleGetTipoAtividade();
  }, []);

  const watchCPF = methods.watch("cpf");

  useEffect(() => {
    if (
      watchCPF?.replaceAll(".", "")?.replaceAll("-", "")?.length === 11 &&
      !isEditing
    ) {
      methods.setValue("cpf", cpfFormatter(watchCPF || ""));
      const formattedCPF = cpfFormatter(watchCPF);
      handleGetNameByCPF(formattedCPF);
    }
  }, [watchCPF, methods]);

  const handleSetEmployee = useCallback(
    (data: EmployeeData) => {
      methods.setValue(
        "tipoAtividade",
        deparaTipoAtividade.filter(
          (item) => item.dsDeparaSicap === data.atividade
        )[0]?.cdDeparaSicap || null
      );
      methods.setValue("nome", data.nome);
      methods.setValue("dataNascimento", data.dataNascimento);
      methods.setValue("dataAdmissao", data.dataAdmissao);
      methods.setValue("dataDesligamento", data.dataDemissao);
      methods.setValue("cnsProfissional", data.cns);
      methods.setValue(
        "autoDeclaracaoGenero",
        deparaAutoDeclaracaoGenero.filter(
          (item) => item.dsDeparaSicap === data.genero
        )[0]?.cdDeparaSicap || null
      );
      methods.setValue(
        "tipoColaborador",
        deparaTipoColaborador.filter(
          (item) => item.dsDeparaSicap === data.colaborador
        )[0]?.cdDeparaSicap || null
      );
      methods.setValue(
        "tipoFolha",
        deparaTipoFolha.filter((item) => item.dsDeparaSicap === data.folha)[0]
          ?.cdDeparaSicap || null
      );
      methods.setValue(
        "regimeContratacao",
        deparaRegimeContratacao.filter(
          (item) => item.dsDeparaSicap === data.regimeContratacao
        )[0]?.cdDeparaSicap || null
      );
      methods.setValue("jornada", data.jornada);
    },
    [
      deparaTipoAtividade,
      methods,
      deparaAutoDeclaracaoGenero,
      deparaTipoColaborador,
      deparaTipoFolha,
      deparaRegimeContratacao,
    ]
  );
  async function handleGetNameByCPF(cpf: string) {
    try {
      const { data } = await getNameByCPF(cpf);
      handleSetEmployee(data);
    } catch (error) {
      toast.error(error.response.data);
    }
  }

  return {
    handleSubmitForm,
    methods,
    setCarrouselPage,
    carrouselPage,
    deparaAutoDeclaracaoGenero,
    deparaAutoDeclaracaoRacial,
    deparaRegimeContratacao,
    deparaTipoJornada,
    deparaTipoFolha,
    deparaTipoColaborador,
    deparaTipoCoordenadoria,
    deparaTipoAtividade,
    handleGetNameByCPF,
  };
};
