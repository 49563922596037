import { ContentProps } from "../../../components/CustomScreens/totvs/Accountability/useAccountability";
import { customScreenApi } from "../../../services/api";

export const getAccountability = async (page?: number) => {
  const response = await customScreenApi.get(`/prestacaoConta`, {
    params: {
      page: page || 0,
      size: 7,
    },
  });
  return response;
};

export const postImportAccountability = async (dataPrestacao: string) => {
  const response = await customScreenApi.post(
    `/prestacaoConta`,
    {},
    {
      headers: {
        dataPrestacao: dataPrestacao,
      },
    }
  );
  return response;
};

export const putFinishAccountability = async (data: ContentProps) => {
  const response = await customScreenApi.put("/prestacaoConta/finalizar", {
    ...data,
  });
  return response;
};
