import { RxCross1 } from "react-icons/rx";
import { DeparaProps } from "../../useDeparas";
import "./styles.scss";
import { useUpdateDepara } from "./useUpdateDepara";
interface UpdateDeparaProps {
  handleCloseModal: () => void;
  deparaData: DeparaProps;
  handleRefreshDeparas: () => void;
}
export function UpdateDepara({
  handleCloseModal,
  deparaData,
  handleRefreshDeparas,
}: UpdateDeparaProps) {
  const { handleSubmitForm, methods, isContaContabil } = useUpdateDepara({
    deparaData,
    handleCloseModal,
    handleRefreshDeparas,
  });
  return (
    <div className="dialog-update-depara-container">
      <header>
        <RxCross1 onClick={() => handleCloseModal()} />
      </header>
      <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <main>
          <div className="dialog-input-group-normal">
            <p>Tipo Depara </p>
            <select {...methods.register("tp_depara")}>
              <option selected hidden value="">
                Tipo de depara
              </option>
              <option>CONTA_CONTABIL_RM</option>
              <option>CENTRO_CUSTO_RM</option>
              <option>HISTORICO_RM</option>
            </select>
          </div>
          <div className="dialog-input-group-small">
            <p>Código Empresa</p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("cd_multi_empresa")}
            />
          </div>
          <div className="dialog-input-group-small">
            <p>Código MV </p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("cd_depara_mv")}
              className={
                methods.formState?.errors?.cd_depara_mv ? "input-error" : ""
              }
            />
          </div>
          {isContaContabil === "CONTA_CONTABIL_RM" ? (
            <div className="dialog-input-group-small">
              <p>Descrição MV</p>
              <select {...methods.register("ds_depara_mv")}>
                <option value="RESULTADO">RESULTADO</option>
                <option value="PATRIMONIAL">PATRIMONIAL</option>
              </select>
            </div>
          ) : (
            <div className="dialog-input-group-small">
              <p>Descrição MV</p>
              <input
                type="text"
                autoComplete="off"
                {...methods.register("ds_depara_mv")}
              />
            </div>
          )}
          <div className="dialog-input-group-small">
            <p>Código RM </p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("cd_depara_integra")}
              className={
                methods.formState?.errors?.cd_depara_integra
                  ? "input-error"
                  : ""
              }
            />
          </div>
          <div className="dialog-input-group-small">
            <p>Descrição RM </p>
            <input
              type="text"
              autoComplete="off"
              {...methods.register("ds_depara_integra")}
            />
          </div>
          <div className="dialog-input-group-small">
            <p>Sistema Integrado </p>
            <input
              type="text"
              disabled
              autoComplete="off"
              {...methods.register("cd_sistema_integra")}
            />
          </div>
        </main>
        <footer>
          <button type="submit">Confirmar</button>

          <button type="button" onClick={() => handleCloseModal()}>
            Cancelar
          </button>
        </footer>
      </form>
    </div>
  );
}
