import { RxCross1 } from "react-icons/rx";
import { CustomController } from "../../../../../utils/FormController/FormController";
import { ContentProps } from "../usePositionSalary";
import "./styles.scss";
import { useModalPositionSalary } from "./useModalPositionSalary";

interface ModalRegisterComponentProps {
  handleCloseModal: () => void;
  handleGetPositionSalary: () => void;
  selectedPositionSalary: ContentProps;
  prestacaoContaId: number;
}
export function ModalPositionSalary({
  handleCloseModal,
  handleGetPositionSalary,
  selectedPositionSalary,
  prestacaoContaId,
}: ModalRegisterComponentProps) {
  const { handleSubmitForm, methods } = useModalPositionSalary({
    handleCloseModal: handleCloseModal,
    handleGetPositionSalary: handleGetPositionSalary,
    selectedPositionSalary: selectedPositionSalary,
    prestacaoContaId: prestacaoContaId,
  });

  return (
    <div className="modal-register-container-salary">
      <header>
        <RxCross1 onClick={() => handleCloseModal()} />
      </header>
      <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <main>
          <div className="input-group-small">
            <p>Código Cbo</p>
            <CustomController
              name="codigoCbo"
              type="text"
              control={methods.control}
              defaultValue={selectedPositionSalary.codigoCbo || null}
            />
          </div>
          <div className="input-group-normal">
            <p>Número Horas Mês</p>
            <CustomController
              name="numeroHorasMes"
              type="number"
              control={methods.control}
              defaultValue={selectedPositionSalary.numeroHorasMes || null}
            />
            {/* <input
              type="text"
              autoComplete="off"
              {...methods.register("numeroHorasMes")}
            /> */}
          </div>
          <div className="input-group-small">
            <p>Convenção coletiva</p>
            <CustomController
              name="convencaoColetiva"
              type="text"
              control={methods.control}
              defaultValue={selectedPositionSalary.convencaoColetiva || null}
            />
            {/* <input
              type="text"
              autoComplete="off"
              {...methods.register("convencaoColetiva")}
            /> */}
          </div>
          <div className="input-group-small">
            <p>Nível</p>
            <CustomController
              name="nivel"
              type="text"
              control={methods.control}
              defaultValue={selectedPositionSalary.nivel || null}
            />
            {/* <input
              type="text"
              autoComplete="off"
              {...methods.register("nivel")}
            /> */}
          </div>
          <div className="input-group-normal">
            <p>Descricao cargo</p>
            <CustomController
              name="descricaoCargo"
              type="text"
              control={methods.control}
              defaultValue={selectedPositionSalary.descricaoCargo || null}
            />
            {/* <input
              type="text"
              autoComplete="off"
              {...methods.register("descricaoCargo")}
            /> */}
          </div>

          <div className="input-group-small">
            <p>Sindicato</p>
            <CustomController
              name="sindicato"
              type="text"
              control={methods.control}
              defaultValue={selectedPositionSalary.sindicato || null}
            />
            {/* <input
              type="text"
              autoComplete="off"
              {...methods.register("sindicato")}
            /> */}
          </div>
          <div className="input-group-small">
            <p>Valor salário</p>
            <CustomController
              name="valorSalario"
              type="text"
              control={methods.control}
              defaultValue={selectedPositionSalary.valorSalario || null}
            />
            {/* <input
              type="text"
              autoComplete="off"
              {...methods.register("valorSalario")}
            /> */}
          </div>
          <div className="input-group-normal">
            <p>Número horas semanais</p>
            <CustomController
              name="numeroHorasSemanais"
              type="number"
              control={methods.control}
              defaultValue={selectedPositionSalary.numeroHorasSemanais || null}
            />
            {/* <input
              type="text"
              autoComplete="off"
              {...methods.register("numeroHorasSemanais")}
            /> */}
          </div>
          <div className="input-group-small">
            <p>Comentários</p>
            <CustomController
              name="comentarios"
              type="text"
              control={methods.control}
              defaultValue={selectedPositionSalary.comentarios || null}
            />
            {/* <input
              type="string"
              autoComplete="off"
              {...methods.register("comentarios")}
            /> */}
          </div>
        </main>
        <footer>
          <button type="submit" disabled={methods.formState.isSubmitting}>
            Confirmar
          </button>

          <button onClick={() => handleCloseModal()} type="button">
            Cancelar
          </button>
        </footer>
      </form>
    </div>
  );
}
