import { useState } from "react";
import { toast } from "react-toastify";
import {
  getBankAccount,
  postImportBankAccount,
} from "../../../../../Controllers/CustomScreens/BankAccount/BankAccountController";
import { BankAccountListProps, InstallmentListProps } from "../useBankAccount";

export interface ImportConfig {
  prestacaoContaId: number;
  parceriaId: number;
  dataInicial: string;
  dataFinal: string;
  mes: number;
  ano: number;
}

interface UseModalImportProps {
  handleCloseModal: () => void;
  setNewList: React.Dispatch<React.SetStateAction<BankAccountListProps>>;
  installmentList?: InstallmentListProps[];
}
export const useModalImport = ({
  handleCloseModal,
  setNewList,
  installmentList,
}: UseModalImportProps) => {
  const [importConfig, setImportConfig] = useState<ImportConfig>();

  async function handleGetNewList() {
    try {
      const { data } = await getBankAccount(
        0,
        "",
        "",
        importConfig?.prestacaoContaId || installmentList[0].prestacaoContaId
      );
      setNewList(data);
    } catch (error) { }
  }

  async function handleImport() {
    try {
      await postImportBankAccount(importConfig);
      toast.success("Importação realizada com sucesso!");
      handleCloseModal();
      await handleGetNewList();
    } catch (error) {
      toast.error("Erro ao importar");
    }
  }
  return {
    setImportConfig,
    importConfig,
    handleImport,
  };
};
