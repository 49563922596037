import moment from "moment";
import { ImportConfig } from "../../../components/CustomScreens/totvs/BankAccount/ModalImport/useModalImport";
import { FinancialReleaseSchemaProps } from "../../../components/CustomScreens/totvs/FinancialRelease/ModalFinancialRelease/schema";
import { ContentProps } from "../../../components/CustomScreens/totvs/FinancialRelease/useFinancialRelease";
import { customScreenApi } from "../../../services/api";

export const getFinancialRelease = async (
  page?: number,
  tpStatus?: string,
  numDocumento?: string,
  valor?: string,
  dataPagamento?: string,
  prestacaoContaId?: number
) => {
  const response = await customScreenApi.get("/lancamentosFinanceiros", {
    params: {
      size: 7,
      page: page || 0,
      tpStatus: tpStatus || "",
      numDocumento: numDocumento || "",
      valor: valor || "",
      dataPagamento: dataPagamento || "",
    },
    headers: {
      prestacaoContaId: prestacaoContaId || "",
    },
  });
  return response;
};

export const postFinancialRelease = async (
  data: FinancialReleaseSchemaProps
) => {
  const response = await customScreenApi.post("/lancamentosFinanceiros", {
    ...data,
    lancamento: {
      ...data.lancamento,
      numDocumento:
        data.lancamento.numDocumento === null
          ? ""
          : data.lancamento.numDocumento,
      docRetencaoFonte:
        data.lancamento.docRetencaoFonte === "true" ? true : false,

      dataEmissao:
        data.lancamento.dataEmissao === ""
          ? null
          : moment(data.lancamento.dataEmissao).format("yyyy-MM-DD HH:mm:ss"),
      dataPagamento:
        data.lancamento.dataPagamento === ""
          ? null
          : moment(data.lancamento.dataPagamento).format("yyyy-MM-DD HH:mm:ss"),
      vencimento:
        data.lancamento.vencimento === ""
          ? null
          : moment(data.lancamento.vencimento).format("yyyy-MM-DD HH:mm:ss"),
      tpStatus: "A",
      parceriaId: sessionStorage.getItem("parceriaId"),
    },
    arquivo: {
      ...data.arquivo,
    },
  });
  return response;
};

export const putFinancialRelease = async (
  data: FinancialReleaseSchemaProps,
  selectedFinancialRelease: ContentProps
) => {
  const response = await customScreenApi.put("/lancamentosFinanceiros", {
    arquivo: {
      ...data.arquivo,
    },
    lancamento: {
      ...data.lancamento,
      cdDocFinanceiro: selectedFinancialRelease.cdDocFinanceiro,
      tpStatus: selectedFinancialRelease.tpStatus,
      // dataEmissao:
      //   data.lancamento.dataEmissao === ""
      //     ? ""
      //     : `${data.lancamento.dataEmissao} 00:00:00`,
      // dataPagamento:
      //   data.lancamento.dataPagamento === ""
      //     ? ""
      //     : `${data.lancamento.dataPagamento} 00:00:00`,
      // vencimento:
      //   data.lancamento.vencimento === ""
      //     ? ""
      //     : `${data.lancamento.vencimento} 00:00:00`,
      dataEmissao:
        data.lancamento.dataEmissao === ""
          ? ""
          : moment(data.lancamento.dataEmissao).format("yyyy-MM-DD HH:mm:ss"),
      dataPagamento:
        data.lancamento.dataPagamento === ""
          ? ""
          : moment(data.lancamento.dataPagamento).format("yyyy-MM-DD HH:mm:ss"),
      vencimento:
        data.lancamento.vencimento === ""
          ? ""
          : moment(data.lancamento.vencimento).format("yyyy-MM-DD HH:mm:ss"),
    },
  });
  return response;
};

export const deleteFinancialRelease = async (cdDocFinanceiro: number) => {
  const response = await customScreenApi.delete(
    `/lancamentosFinanceiros/${cdDocFinanceiro}`
  );
  return response;
};

export const putImportFinancialRelease = async (listImports: any) => {
  const response = await customScreenApi.put(
    `/lancamentosFinanceiros/importar`,
    {},
    {
      headers: {
        cdLancamentosFinanceiros: listImports,
      },
    }
  );
  return response;
};

export const getDeparaTipoOperacaoFinancialRelease = async () => {
  const response = await customScreenApi.get("/depara/operacao");
  return response;
};
export const getDeparaTipoDocumentoFinancialRelease = async () => {
  const response = await customScreenApi.get("/depara/documento");
  return response;
};
export const getDeparaClassificacaoPagamentoFinancialRelease = async () => {
  const response = await customScreenApi.get("/depara/classificacaoPagamento");
  return response;
};
export const getDeparaTipoFornecedorFinancialRelease = async () => {
  const response = await customScreenApi.get("/depara/fornecedor");
  return response;
};
export const getDeparaFonteRecursoFinancialRelease = async () => {
  const response = await customScreenApi.get("/depara/recurso");
  return response;
};

export const getBase64File = async (cdDocFinanceiro: number) => {
  const response = await customScreenApi.get(
    `/lancamentosFinanceiros/${cdDocFinanceiro}/baixarArquivo`
  );
  return response;
};

export const postImportFinancialRelease = async (
  importObject: ImportConfig
) => {
  const response = await customScreenApi.post(
    `/lancamentosFinanceiros/buscaRegistroTotvs`,
    {
      ...importObject,
      parceriaId: sessionStorage.getItem("parceriaId"),
    }
  );
  return response;
};
