import { RxCross1 } from "react-icons/rx";
import { CustomController } from "../../../../../utils/FormController/FormController";
import { ContentProps } from "../useEmployees";
import "./styles.scss";
import { useModalEmployees } from "./useModalEmployees";

interface ModalRegisterComponentProps {
  handleCloseModal: () => void;
  handleGetEmployees: () => void;
  selectedEmployees: ContentProps;
  prestacaoContaId: number;
}
export function ModalEmployees({
  handleCloseModal,
  handleGetEmployees,
  selectedEmployees,
  prestacaoContaId,
}: ModalRegisterComponentProps) {
  const {
    handleSubmitForm,
    methods,
    deparaGenero,
    deparaTipoAtividade,
    deparaTipoColaborador,
    deparaTipoFolha,
    deparaRegimeContratacao,
  } = useModalEmployees({
    handleCloseModal: handleCloseModal,
    handleGetEmployees: handleGetEmployees,
    selectedEmployees: selectedEmployees,
    prestacaoContaId: prestacaoContaId,
  });

  return (
    <div className="modal-register-container-employees">
      <header>
        <RxCross1 onClick={() => handleCloseModal()} />
      </header>
      <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <main>
          <div className="input-group-small">
            <p>Id cargo</p>
            <CustomController
              name="cargoId"
              type="text"
              control={methods.control}
              defaultValue={selectedEmployees.cargoId || null}
            />
          </div>{" "}
          <div className="input-group-small">
            <p>Nome</p>
            <CustomController
              name="nome"
              type="text"
              control={methods.control}
              defaultValue={selectedEmployees.nome || null}
            />
          </div>
          <div className="input-group-small">
            <p>Cns</p>
            <CustomController
              name="cns"
              type="text"
              control={methods.control}
              defaultValue={selectedEmployees.cns || null}
            />
          </div>
          <div className="input-group-small">
            <p>Cpf</p>
            <CustomController
              name="cpf"
              type="text"
              control={methods.control}
              defaultValue={selectedEmployees.cpf || null}
            />
          </div>
          <div className="input-group-small">
            <p>Data admissão</p>
            <input
              type="date"
              {...methods.register("dataAdmissao")}
              autoComplete="off"
            />
          </div>
          <div className="input-group-small">
            <p>Data demissão</p>
            <input
              type="date"
              {...methods.register("dataDemissao")}
              autoComplete="off"
            />
          </div>
          <div className="input-group-small">
            <p>Data nascimento</p>
            <input
              type="date"
              {...methods.register("dataNascimento")}
              autoComplete="off"
            />
          </div>
          <div className="input-group-small">
            <p>Carga H/ semanal</p>
            <CustomController
              name="cargaHorariaSemanal"
              type="number"
              control={methods.control}
              defaultValue={selectedEmployees.cargaHorariaSemanal || null}
            />
          </div>
          <div className="input-group-small">
            <p>Gênero</p>
            <select {...methods.register("genero")}>
              <option
                hidden
                value={
                  selectedEmployees.genero !== undefined &&
                    selectedEmployees.genero !== null &&
                    selectedEmployees.genero !== ""
                    ? selectedEmployees.genero
                    : ""
                }
              >
                {selectedEmployees.genero !== undefined &&
                  selectedEmployees.genero !== null &&
                  selectedEmployees.genero !== ""
                  ? selectedEmployees.genero
                  : "Selecione"}
              </option>
              {deparaGenero.map((genero) => (
                <option key={genero.cdDeparaSicap} value={genero.dsDeparaSicap}>
                  {genero.dsDeparaSicap}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group-small">
            <p>Jornada</p>
            <CustomController
              name="jornada"
              type="number"
              control={methods.control}
              defaultValue={selectedEmployees.jornada || null}
            />
          </div>
          <div className="input-group-small">
            <p>Linha serviço Id</p>
            <CustomController
              name="linhaServicoId"
              type="number"
              control={methods.control}
              defaultValue={
                selectedEmployees.linhaServicoId !== null &&
                  selectedEmployees.linhaServicoId !== undefined
                  ? selectedEmployees.linhaServicoId
                  : ""
              }
            />
          </div>
          <div className="input-group-small">
            <p>Matricula</p>
            <CustomController
              name="matricula"
              type="text"
              control={methods.control}
              defaultValue={selectedEmployees.matricula || null}
            />
          </div>
          <div className="input-group-small">
            <p>Regime contratação</p>
            <select {...methods.register("regimeContratacao")}>
              <option
                hidden
                value={
                  selectedEmployees.regimeContratacao !== undefined &&
                    selectedEmployees.regimeContratacao !== null &&
                    selectedEmployees.regimeContratacao !== ""
                    ? selectedEmployees.regimeContratacao
                    : ""
                }
              >
                {selectedEmployees.regimeContratacao !== undefined &&
                  selectedEmployees.regimeContratacao !== null &&
                  selectedEmployees.regimeContratacao !== ""
                  ? selectedEmployees.regimeContratacao
                  : "Selecione"}
              </option>
              {deparaRegimeContratacao.map((regimeContratacao) => (
                <option
                  key={regimeContratacao.cdDeparaSicap}
                  value={regimeContratacao.dsDeparaSicap}
                >
                  {regimeContratacao.dsDeparaSicap}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group-small">
            <p>Tipo atividade</p>
            <select {...methods.register("tipoAtividade")}>
              <option
                hidden
                value={
                  selectedEmployees.tipoAtividade !== undefined &&
                    selectedEmployees.tipoAtividade !== null &&
                    selectedEmployees.tipoAtividade !== ""
                    ? selectedEmployees.tipoAtividade
                    : ""
                }
              >
                {selectedEmployees.tipoAtividade !== undefined &&
                  selectedEmployees.tipoAtividade !== null &&
                  selectedEmployees.tipoAtividade !== ""
                  ? selectedEmployees.tipoAtividade
                  : "Selecione"}
              </option>
              {deparaTipoAtividade.map((tipoAtividade) => (
                <option
                  key={tipoAtividade.cdDeparaSicap}
                  value={tipoAtividade.dsDeparaSicap}
                >
                  {tipoAtividade.dsDeparaSicap}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group-small">
            <p>Tipo colaborador</p>
            <select {...methods.register("tipoColaborador")}>
              <option
                hidden
                value={
                  selectedEmployees.tipoColaborador !== undefined &&
                    selectedEmployees.tipoColaborador !== null &&
                    selectedEmployees.tipoColaborador !== ""
                    ? selectedEmployees.tipoColaborador
                    : ""
                }
              >
                {selectedEmployees.tipoColaborador !== undefined &&
                  selectedEmployees.tipoColaborador !== null &&
                  selectedEmployees.tipoColaborador !== ""
                  ? selectedEmployees.tipoColaborador
                  : "Selecione"}
              </option>
              {deparaTipoColaborador.map((tipoAtividade) => (
                <option
                  key={tipoAtividade.cdDeparaSicap}
                  value={tipoAtividade.dsDeparaSicap}
                >
                  {tipoAtividade.dsDeparaSicap}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group-small">
            <p>Tipo folha</p>
            <select {...methods.register("tipoFolha")}>
              <option
                hidden
                value={
                  selectedEmployees.tipoFolha !== undefined &&
                    selectedEmployees.tipoFolha !== null &&
                    selectedEmployees.tipoFolha !== ""
                    ? selectedEmployees.tipoFolha
                    : ""
                }
              >
                {selectedEmployees.tipoFolha !== undefined &&
                  selectedEmployees.tipoFolha !== null &&
                  selectedEmployees.tipoFolha !== ""
                  ? selectedEmployees.tipoFolha
                  : "Selecione"}
              </option>
              {deparaTipoFolha.map((tipoFolha) => (
                <option
                  key={tipoFolha.cdDeparaSicap}
                  value={tipoFolha.dsDeparaSicap}
                >
                  {tipoFolha.dsDeparaSicap}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group-small">
            <p>Unidade Id</p>
            <CustomController
              name="unidadeId"
              type="text"
              control={methods.control}
              defaultValue={selectedEmployees.unidadeId || null}
            />
          </div>
          <div className="input-group-small">
            <p>Valor salario base</p>
            <CustomController
              name="valorSalarioBase"
              type="number"
              control={methods.control}
              defaultValue={selectedEmployees.valorSalarioBase || null}
            />
          </div>
        </main>
        <footer>
          <button type="submit" disabled={methods.formState.isSubmitting}>
            Confirmar
          </button>

          <button onClick={() => handleCloseModal()} type="button">
            Cancelar
          </button>
        </footer>
      </form>
    </div>
  );
}
