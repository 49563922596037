import { UseFormReturn } from "react-hook-form";
import { CustomController } from "../../../../../../utils/FormController/FormController";
import { ContentProps } from "../../usePayroll";

interface ProviderFieldsProps {
  methods: UseFormReturn;
  selectedPayroll: ContentProps;
}
export function ProviderFields({
  methods,
  selectedPayroll,
}: ProviderFieldsProps) {
  return (
    <>
      <div className="input-group-normal">
        <p>Valor Bruto RPA</p>
        <CustomController
          name="valorBrutoRpa"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.valorBrutoRpa !== null &&
              selectedPayroll.valorBrutoRpa !== undefined
              ? selectedPayroll.valorBrutoRpa
              : null
          }
        //defaultValue={selectedPayroll.valorBrutoRpa || null}
        />
      </div>
      <div className="input-group-normal">
        <p>ISS S/ RPA</p>
        <CustomController
          name="issRpa"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.issRpa !== null &&
              selectedPayroll.issRpa !== undefined
              ? selectedPayroll.issRpa
              : null
          }
        //defaultValue={selectedPayroll.issRpa || null}
        />
      </div>
      {/* <div className="input-group-normal">
        <p>INSS Retido S/ RPA</p>
        <CustomController
          name="insssRpa"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.insssRpa !== null &&
            selectedPayroll.insssRpa !== undefined
              ? selectedPayroll.insssRpa
              : null
          }
        />
      </div> */}
      <div className="input-group-normal">
        <p>Valor Líquido RPA</p>
        <CustomController
          name="valorLiquidoRpa"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.valorLiquidoRpa !== null &&
              selectedPayroll.valorLiquidoRpa !== undefined
              ? selectedPayroll.valorLiquidoRpa
              : null
          }
        //  defaultValue={selectedPayroll.valorLiquidoRpa || null}
        />
      </div>
      <div className="input-group-normal">
        <p>INSS S/ RPA (Patronal)</p>
        <CustomController
          name="inssRpaPatronal"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.inssRpaPatronal !== null &&
              selectedPayroll.inssRpaPatronal !== undefined
              ? selectedPayroll.inssRpaPatronal
              : null
          }
        //defaultValue={selectedPayroll.inssRpaPatronal || null}
        />
      </div>
      <div className="input-group-normal">
        <p>Desconto INSS RPA</p>
        <CustomController
          name="descontoInssRpa"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descontoInssRpa !== null &&
              selectedPayroll.descontoInssRpa !== undefined
              ? selectedPayroll.descontoInssRpa
              : null
          }
        //defaultValue={selectedPayroll.descontoInssRpa || null}
        />
      </div>
      {/* <div className="input-group-normal">
        <p>Descontos RPA</p>
        <CustomController
          name="descsRpa"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.descsRpa !== null &&
            selectedPayroll.descsRpa !== undefined
              ? selectedPayroll.descsRpa
              : null
          }
        />
      </div> */}
      <div className="input-group-normal">
        <p>IRRF S/ RPA</p>
        <CustomController
          name="irrfSrpa"
          type="number"
          control={methods.control}
          defaultValue={
            selectedPayroll.irrfSrpa !== null &&
              selectedPayroll.irrfSrpa !== undefined
              ? selectedPayroll.irrfSrpa
              : null
          }
        // defaultValue={selectedPayroll.irrfSrpa || null}
        />
      </div>
    </>
  );
}
