import { BankAccountSchemaProps } from "../../../components/CustomScreens/totvs/BankAccount/ModalBankAccount/schema";
import { ImportConfig } from "../../../components/CustomScreens/totvs/BankAccount/ModalImport/useModalImport";
import { ContentProps } from "../../../components/CustomScreens/totvs/BankAccount/useBankAccount";
import { customScreenApi } from "../../../services/api";
export const getBankAccount = async (
  page?: number,
  dsContaBancaria?: string,
  contaContabil?: string,
  prestacaoContaId?: number
) => {
  const response = await customScreenApi.get("/contasBancarias/filtrada", {
    params: {
      page: page || 0,
      size: 7,
      dsContaBancaria: dsContaBancaria || "",
      contaContabil: contaContabil || "",
    },
    headers: {
      prestacaoContaId: prestacaoContaId || "",
    },
  });
  return response;
};

export const getDeparaModal = async () => {
  const response = await customScreenApi.get("/depara/recurso");
  return response;
};

export const postNewBankAccount = async (data: BankAccountSchemaProps) => {
  const response = await customScreenApi.post("/contasBancarias", {
    ...data,
    contaContabil: data.contaContabil === "" ? null : data.contaContabil,
    contaAplicacao: data.contaContabil === "true" ? true : false,
    idFonteRecurso:
      data.idFonteRecurso === "" ? null : Number(data.idFonteRecurso),
    idTipoRecurso:
      data.idTipoRecurso === "" ? null : Number(data.idTipoRecurso),
    prestacaoContaId:
      data.prestacaoContaId === null ? null : Number(data.prestacaoContaId),
    saldoAnterior:
      data.saldoAnterior === "" ? null : Number(data.saldoAnterior),
    tpStatus: "A",
    parceriaId: sessionStorage.getItem("parceriaId"),
  });
  return response;
};

export const putEdidBankAccount = async (
  data: BankAccountSchemaProps,
  selectedBankAccount: ContentProps
) => {
  const response = await customScreenApi.put("/contasBancarias", {
    ...data,
    tblDtiCdBanco: selectedBankAccount.tblDtiCdBanco,
    tpStatus: selectedBankAccount.tpStatus,
  });
  return response;
};

export const deleteBankAccount = async (tblDtiCdBanco: number) => {
  const response = await customScreenApi.delete(
    `/contasBancarias/${tblDtiCdBanco}`
  );
  return response;
};

export const putImportBankAccount = async (listImports: any) => {
  const response = await customScreenApi.put(
    `/contasBancarias/importar`,
    {},
    {
      headers: {
        tblDtiCdBanco: listImports,
      },
    }
  );
  return response;
};

export const getFilteredRegisters = async (
  page?: number,
  dsContaBancaria?: string,
  contaContabil?: string
) => {
  const response = await customScreenApi.get("/contasBancarias/filtrada", {
    params: {
      page: page || 0,
      size: 7,
      dsContaBancaria: dsContaBancaria || "",
      contaContabil: contaContabil || "",
    },
  });
  return response;
};

export const getInstallment = async () => {
  const response = await customScreenApi.get(
    `/prestacaoConta/parceriaId/${sessionStorage.getItem("parceriaId")}`
  );
  return response;
};

export const postImportBankAccount = async (importObject: ImportConfig) => {
  const response = await customScreenApi.post(
    `/contasBancarias/buscaRegistroTotvs`,
    {
      ...importObject,
      parceriaId: sessionStorage.getItem("parceriaId"),
    }
  );
  return response;
};
