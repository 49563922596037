import {
  Checkbox,
  CircularProgress,
  Dialog,
  LinearProgress,
  Pagination,
} from "@mui/material";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { BsPencilSquare } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { VscDebugRestart } from "react-icons/vsc";
import ReactTooltip from "react-tooltip";
import iconOcultar from "../../../../assets/iconOcultar.svg";
import { Header } from "../../../Header";
import { ConfirmExclude } from "../ConfirmExclude";
import { ModalEmployees } from "./ModalEmployees";
import { ModalImport } from "./ModalImport";
import "./styles.scss";
import { ContentProps, useEmployees } from "./useEmployees";

export default function Employees() {
  const {
    employees,
    handlePaginate,
    selectedEmployees,
    handleGetEmployees,
    setSelectedEmployees,
    handleEditModal,
    modalState,
    setModalState,
    handleDeleteEmployees,
    isLoading,
    handleImportRegisters,
    selectedImportRegisters,
    allSelected,
    setAllSelected,
    handleSubmitImport,
    page,
    setFilterRegisters,
    filterRegisters,
    showModalExclude,
    setShowModalExclude,
    employToDelete,
    setEmployToDelete,
    showModalImport,
    setShowModalImport,
    installmentList,
    selectedInstallment,
    setEmployees,
  } = useEmployees();

  return (
    <div className="Employees-container">
      <Header message={"Dashboard/Funcionários"} showAtualizacao={true} />
      <main>
        <div className="Employees-main-card">
          <h2>Funcionários</h2>

          <header className="Employees-main-card-header">
            <aside className="Employees-main-card-filters">
              <input
                type="text"
                placeholder="CPF"
                onBlur={(e) =>
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    cpf: e.target.value,
                  }))
                }
              />
              <input
                type="text"
                placeholder="Matricula"
                onBlur={(e) =>
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    matricula: e.target.value,
                  }))
                }
              />
              <input
                type="text"
                placeholder="Unidade"
                onBlur={(e) =>
                  setFilterRegisters((prevState) => ({
                    ...prevState,
                    unidade: e.target.value,
                  }))
                }
              />
              <button
                type="button"
                className="filter-button"
                onClick={() =>
                  handleGetEmployees(
                    0,
                    filterRegisters.cpf,
                    filterRegisters.matricula,
                    filterRegisters.unidade
                  )
                }
              >
                Filtrar
              </button>
            </aside>
            <div className="header-buttons">
              <aside>
                <Button
                  variant="contained"
                  onClick={() => setShowModalImport(true)}
                >
                  Importar Totvs <FaPlus />
                </Button>
              </aside>
              <aside>
                <Button variant="outlined" onClick={() => setModalState(true)}>
                  Novo Registro <FaPlus />
                </Button>
              </aside>
              <aside>
                <Button
                  variant="contained"
                  onClick={() => handleSubmitImport()}
                  disabled={!allSelected && selectedImportRegisters.length <= 0}
                >
                  Enviar Sicap <FaPlus />
                </Button>
                <Checkbox
                  color="primary"
                  size="small"
                  title="Todos Registros"
                  checked={allSelected}
                  onChange={() => setAllSelected(!allSelected)}
                />{" "}
                Todos Registros
              </aside>
              <div className="header-buttons-restart">
                {isLoading ? (
                  <CircularProgress
                    style={{
                      color: "white",
                      animation: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                  />
                ) : (
                  <VscDebugRestart
                    data-tip="Atualizar"
                    onClick={() => handleGetEmployees(page)}
                  />
                )}
              </div>
            </div>
          </header>
          <section className="Employees-main-card-section">
            <Dialog
              open={modalState}
              onClose={() => (
                setModalState(false), setSelectedEmployees({} as ContentProps)
              )}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalEmployees
                prestacaoContaId={selectedInstallment}
                handleCloseModal={() => (
                  setModalState(false), setSelectedEmployees({} as ContentProps)
                )}
                selectedEmployees={selectedEmployees}
                handleGetEmployees={handleGetEmployees}
              />
            </Dialog>
            <Dialog
              open={showModalExclude}
              onClose={() => setShowModalExclude(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ConfirmExclude
                handleCloseModal={() => setShowModalExclude(false)}
                handleSubmit={() =>
                  handleDeleteEmployees(employToDelete.cdFuncionario)
                }
              />
            </Dialog>
            <Dialog
              open={showModalImport}
              onClose={() => setShowModalImport(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalImport
                handleCloseModal={() => setShowModalImport(false)}
                installmentList={installmentList}
                setNewList={setEmployees}
              />
            </Dialog>
            {!isLoading ? (
              <table>
                <thead>
                  <tr>
                    <th colSpan={1}></th>
                    <th>Status</th>
                    <th>Nome</th>
                    <th>Cpf</th>
                    <th>Data Admissão</th>
                    <th>Data Demissão</th>
                    <th>Gênero</th>
                    <th>Unidade Id</th>
                    <th>Valor Salario Base</th>
                    <th colSpan={1}></th>
                  </tr>
                </thead>
                <tbody>
                  {employees?.content?.map((employ, index) => (
                    <tr key={index}>
                      <td>
                        {(employ.tpStatus === "A" ||
                          employ.tpStatus === "E") && (
                          <Checkbox
                            color="primary"
                            size="small"
                            onChange={() => {
                              handleImportRegisters(employ.cdFuncionario);
                            }}
                            checked={selectedImportRegisters.some(
                              (item) => item === employ.cdFuncionario
                            )}
                          />
                        )}
                      </td>
                      <td>
                        {employ.tpStatus === "A" && (
                          <div className="circle-status">
                            <span
                              data-tip="Aguardando"
                              className="circle-aguardando"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {employ.tpStatus === "I" && (
                          <div className="circle-status">
                            <span
                              data-tip="Importado"
                              className="circle-importado"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {employ.tpStatus === "T" && (
                          <div className="circle-status">
                            <span
                              data-tip="Transferido"
                              className="circle-transferido"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {employ.tpStatus === "E" && (
                          <div className="circle-status">
                            <span
                              data-tip={employ.dsErro}
                              className="circle-error"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                        {employ.tpStatus === "F" && (
                          <div className="circle-status">
                            <span
                              data-tip="Finalizado"
                              className="circle-finalizado"
                            ></span>
                            <ReactTooltip
                              place="top"
                              type="light"
                              effect="solid"
                            />
                          </div>
                        )}
                      </td>
                      <td>{employ.nome}</td>
                      <td>{employ.cpf}</td>
                      <td>
                        {employ.dataAdmissao !== null
                          ? dayjs(
                              new Date(
                                employ.dataAdmissao[0], // Ano
                                employ.dataAdmissao[1] - 1, // Mês (precisa subtrair 1, pois começa do 0)
                                employ.dataAdmissao[2], // Dia
                                employ.dataAdmissao[3], // Hora
                                employ.dataAdmissao[4] // Minutos
                              )
                            ).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td>
                        {employ.dataDemissao !== null
                          ? dayjs(
                              new Date(
                                employ.dataDemissao[0], // Ano
                                employ.dataDemissao[1] - 1, // Mês (precisa subtrair 1, pois começa do 0)
                                employ.dataDemissao[2], // Dia
                                employ.dataDemissao[3], // Hora
                                employ.dataDemissao[4] // Minutos
                              )
                            ).format("DD/MM/YYYY")
                          : ""}
                        {/* {employ.dataDemissao !== null
                          ? dayjs(employ.dataDemissao).format("DD/MM/YYYY")
                          : ""} */}
                      </td>
                      <td>{employ.genero}</td>
                      <td>{employ.unidadeId}</td>
                      <td>{employ.valorSalarioBase}</td>
                      <td>
                        <div className="Employees-main-card-section-icons">
                          <p
                            data-tip="Editar Registro"
                            onClick={() => handleEditModal(employ)}
                          >
                            <BsPencilSquare color="#D8A511" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                          <p
                            data-tip="Excluir Registro"
                            onClick={() => {
                              setEmployToDelete(employ);
                              setShowModalExclude(true);
                            }}
                          >
                            <img src={iconOcultar} alt="" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <LinearProgress />
            )}
          </section>
          <footer>
            <Pagination
              count={employees.totalPages}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginate}
            />
          </footer>
        </div>
      </main>
    </div>
  );
}
