import { z } from "zod";

export const AggregateUnitsSchema = z.object({
  unidadeId: z.string().nullable(),
  materialEquipamentoId: z.string().nullable(),
  linhaServicoId: z.string().nullable(),
  quantidade: z.number().nullable(),
  valor: z.number().nullable(),
  numPagamento: z.string().nullable(),
  tipoDespesaId: z.string().nullable(),
  prestacaoContaId: z.number().optional(),
});

export type AggregateUnitsSchemaProps = z.infer<typeof AggregateUnitsSchema>;
