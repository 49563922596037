import { ImportConfig } from "../../../components/CustomScreens/totvs/BankAccount/ModalImport/useModalImport";
import { PayrollSchemaProps } from "../../../components/CustomScreens/totvs/Payroll/ModalPayroll/schema";
import { ContentProps } from "../../../components/CustomScreens/totvs/Payroll/usePayroll";
import { customScreenApi } from "../../../services/api";

export const getPayroll = async (
  page?: number,
  cpf?: string,
  salario?: string,
  unidade?: string,
  prestacaoContaId?: number
) => {
  const response = await customScreenApi.get("/folhaPagamento", {
    params: {
      size: 7,
      page: page || 0,
      cpf: cpf || "",
      salario: salario || "",
      unidade: unidade || "",
    },
    headers: {
      prestacaoContaId: prestacaoContaId || "",
    },
  });
  return response;
};

export const postPayroll = async (data: PayrollSchemaProps) => {
  const response = await customScreenApi.post("/folhaPagamento", {
    ...data,
    tpStatus: "A",
    dataAdmissao:
      data.dataAdmissao === "" ? "" : `${data.dataAdmissao}T00:00:00`,
    dataDesligamento:
      data.dataDesligamento === "" ? "" : `${data.dataDesligamento}T00:00:00`,
    dataNascimento:
      data.dataNascimento === "" ? "" : `${data.dataNascimento}T00:00:00`,
    parceriaId: sessionStorage.getItem("parceriaId"),
    cpf:
      data.cpf?.length > 0
        ? data.cpf.replaceAll(".", "").replace("-", "")
        : null,
  });
  return response;
};

export const putPayroll = async (
  data: PayrollSchemaProps,
  selectedPayroll: ContentProps
) => {
  const response = await customScreenApi.put("/folhaPagamento", {
    ...data,
    dataAdmissao:
      data.dataAdmissao === "" ? "" : `${data.dataAdmissao}T00:00:00`,
    dataDesligamento:
      data.dataDesligamento === "" ? "" : `${data.dataDesligamento}T00:00:00`,
    dataNascimento:
      data.dataNascimento === "" ? "" : `${data.dataNascimento}T00:00:00`,
    cdFolhaPagamento: selectedPayroll.cdFolhaPagamento,
    tpStatus: selectedPayroll.tpStatus,
  });
  return response;
};

export const deletePayroll = async (cdFolhaPagamento: number) => {
  const response = await customScreenApi.delete(
    `/folhaPagamento/${cdFolhaPagamento}`
  );
  return response;
};

export const putImportPayroll = async (listImports: any) => {
  const response = await customScreenApi.put(
    `/folhaPagamento/importar
    `,
    {},
    {
      headers: {
        cdFolhaPagamento: listImports,
      },
    }
  );
  return response;
};
export const getDeparaAutoDeclaracaoGeneroPayroll = async () => {
  const response = await customScreenApi.get("/depara/declaracaoGenero");
  return response;
};
export const getDeparaAutoDeclaracaoRacialPayroll = async () => {
  const response = await customScreenApi.get("/depara/declaracaoRacial");
  return response;
};
export const getDeparaRegimeContratacaoPayroll = async () => {
  const response = await customScreenApi.get("/depara/regimeContratacao");
  return response;
};
export const getDeparaTipoJornadaPayroll = async () => {
  const response = await customScreenApi.get("/depara/jornada");
  return response;
};
export const getDeparaTipoFolhaPayroll = async () => {
  const response = await customScreenApi.get("/depara/folha");
  return response;
};
export const getDeparaTipoColaboradorPayroll = async () => {
  const response = await customScreenApi.get("/depara/colaborador");
  return response;
};
export const getDeparaTipoCoordenadoriaPayroll = async () => {
  const response = await customScreenApi.get("/depara/coordenadoria");
  return response;
};
export const getDeparaTipoAtividadePayroll = async () => {
  const response = await customScreenApi.get("/depara/atividade");
  return response;
};

export const getNameByCPF = async (cpf: string) => {
  const response = await customScreenApi.get(
    `/folhaPagamento/funcionario/${cpf}`
  );
  return response;
};
export const postImportPayroll = async (importObject: ImportConfig) => {
  const response = await customScreenApi.post(
    `/folhaPagamento/buscaRegistroTotvs`,
    {
      ...importObject,
      parceriaId: sessionStorage.getItem("parceriaId"),
    }
  );
  return response;
};
