import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  getDeparaModal,
  postNewBankAccount,
  putEdidBankAccount,
} from "../../../../../Controllers/CustomScreens/BankAccount/BankAccountController";
import { ContentProps } from "../useBankAccount";
import { BankAccountSchema, BankAccountSchemaProps } from "./schema";

interface UseModalBankAccountProps {
  handleCloseModal: () => void;
  handleGetBankAccount: () => void;
  selectedBankAccount: ContentProps;
  prestacaoContaId: number;
}

interface DeparaProps {
  cdDepara: number;
  cdDeparaOrigem: string;
  cdDeparaSicap: string;
  dsDeparaOrigem: string;
  dsDeparaSicap: string;
  parceriaId: number;
  tpDepara: string;
}
export const useModalBankAccount = ({
  handleCloseModal,
  handleGetBankAccount,
  selectedBankAccount,
  prestacaoContaId,
}: UseModalBankAccountProps) => {
  const [deparaFonteRecurso, setDeparaFonteRecurso] = useState<DeparaProps[]>(
    []
  );
  const [deparaTipoRecurso, setDeparaTipoRecurso] = useState<DeparaProps[]>([]);
  const methods = useForm<BankAccountSchemaProps>({
    resolver: zodResolver(BankAccountSchema),
    defaultValues: {
      id: selectedBankAccount.id === null ? "" : selectedBankAccount.id,
      contaAplicacao:
        selectedBankAccount.contaAplicacao === true ? "true" : "false",
      contaContabil:
        selectedBankAccount.contaContabil === null
          ? ""
          : selectedBankAccount.contaContabil,
      dsContaBancaria:
        selectedBankAccount.dsContaBancaria === null
          ? ""
          : selectedBankAccount.dsContaBancaria,
      dsAgencia:
        selectedBankAccount.dsAgencia === null
          ? ""
          : selectedBankAccount.dsAgencia,
      dsAgenciaDigito:
        selectedBankAccount.dsAgenciaDigito === null
          ? ""
          : selectedBankAccount.dsAgenciaDigito,
      numeroContaBancaria:
        selectedBankAccount.numeroContaBancaria === null
          ? ""
          : selectedBankAccount.numeroContaBancaria,
      contaDigito:
        selectedBankAccount.contaDigito === null
          ? ""
          : selectedBankAccount.contaDigito,
      idFonteRecurso:
        selectedBankAccount.idFonteRecurso === null ||
          selectedBankAccount?.idTipoRecurso === undefined
          ? ""
          : String(selectedBankAccount.idFonteRecurso),
      dsFonteRecurso:
        selectedBankAccount.dsFonteRecurso === null ||
          selectedBankAccount?.dsFonteRecurso === undefined
          ? ""
          : selectedBankAccount.dsFonteRecurso,
      dsTipoRecurso:
        selectedBankAccount.dsTipoRecurso === null ||
          selectedBankAccount?.dsTipoRecurso === undefined
          ? ""
          : selectedBankAccount.dsTipoRecurso,
      idTipoRecurso:
        selectedBankAccount?.idTipoRecurso === null ||
          selectedBankAccount?.idTipoRecurso === undefined
          ? ""
          : String(selectedBankAccount.idTipoRecurso),
      saldoAnterior:
        selectedBankAccount?.saldoAnterior === null ||
          selectedBankAccount?.saldoAnterior === undefined
          ? ""
          : String(selectedBankAccount.saldoAnterior),
      // prestacaoContaId:
      //   selectedBankAccount?.prestacaoContaId === null ||
      //   selectedBankAccount?.prestacaoContaId === undefined
      //     ? ""
      //     : String(selectedBankAccount.prestacaoContaId),
      contaContabilPai:
        selectedBankAccount.contaContabilPai === null ||
          selectedBankAccount?.contaContabilPai === undefined
          ? ""
          : selectedBankAccount.contaContabilPai,
      parceriaId:
        selectedBankAccount.parceriaId !== undefined ||
          selectedBankAccount.parceriaId !== null
          ? selectedBankAccount.parceriaId
          : null,
      prestacaoContaId:
        selectedBankAccount.prestacaoContaId !== undefined &&
          selectedBankAccount.prestacaoContaId !== null
          ? selectedBankAccount.prestacaoContaId
          : prestacaoContaId,
    },
  });
  async function handleSubmitForm(form: BankAccountSchemaProps) {
    if (selectedBankAccount.tblDtiCdBanco === undefined) {
      try {
        await postNewBankAccount(form);
        handleCloseModal();
        handleGetBankAccount();
        methods.reset();
        toast.success("Registro inserido com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao cadastrar, por favor verifique os campos preenchidos!"
        );
      }
    } else {
      try {
        await putEdidBankAccount(form, selectedBankAccount);
        handleCloseModal();
        handleGetBankAccount();
        methods.reset();
        toast.success("Registro inserido com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao cadastrar, por favor verifique os campos preenchidos!"
        );
      }
    }
  }

  async function handleGetDeparaFonteRecurso() {
    try {
      const { data } = await getDeparaModal();
      const fonteRecurso = data.filter(
        (item) => item.tpDepara === "fonteRecurso"
      );
      setDeparaFonteRecurso(fonteRecurso);

      const tipoRecurso = data.filter(
        (item) => item.tpDepara === "tipoRecurso"
      );
      setDeparaTipoRecurso(tipoRecurso);
    } catch (error) { }
  }

  useEffect(() => {
    handleGetDeparaFonteRecurso();
  }, []);
  return {
    handleSubmitForm,
    methods,
    deparaFonteRecurso,
    deparaTipoRecurso,
  };
};
