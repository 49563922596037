import { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import { ContentProps } from "../usePayroll";

import { BenefitsFields } from "./BenefitsFields";
import { CompanyFields } from "./CompanyFields";
import { DiscountsFields } from "./DiscountsFields";
import { EarningsFields } from "./EarningsFields";
import { GeneralFields } from "./GeneralFields";
import { ProviderFields } from "./ProviderFields";
import "./styles.scss";
import { useModalPayroll } from "./useModalPayroll";
interface ModalRegisterComponentProps {
  handleCloseModal: () => void;
  handleGetPayroll: () => void;
  selectedPayroll: ContentProps;
  isEditing?: boolean;
  prestacaoContaId: number;
}

export function ModalPayroll({
  handleCloseModal,
  handleGetPayroll,
  selectedPayroll,
  isEditing,
  prestacaoContaId,
}: ModalRegisterComponentProps) {
  const { handleSubmitForm, methods, carrouselPage, setCarrouselPage } =
    useModalPayroll({
      handleCloseModal: handleCloseModal,
      handleGetPayroll: handleGetPayroll,
      selectedPayroll: selectedPayroll,
      isEditing: isEditing,
      prestacaoContaId: prestacaoContaId,
    });
  const [selectedScreen, setSelectedScreen] = useState(1);

  return (
    <div className="modal-register-container-payroll">
      <header>
        <button
          type="button"
          onClick={() => setSelectedScreen(1)}
          className={
            Object.keys(methods.formState.errors).length > 0
              ? "button-form-error"
              : ""
          }
        >
          Geral
          {selectedScreen === 1 && <span />}
        </button>
        <button type="button" onClick={() => setSelectedScreen(2)}>
          Proventos
          {selectedScreen === 2 && <span />}
        </button>
        <button type="button" onClick={() => setSelectedScreen(3)}>
          Benefícios
          {selectedScreen === 3 && <span />}
        </button>

        <button type="button" onClick={() => setSelectedScreen(4)}>
          Descontos
          {selectedScreen === 4 && <span />}
        </button>
        <button type="button" onClick={() => setSelectedScreen(5)}>
          Empresa
          {selectedScreen === 5 && <span />}
        </button>
        <button type="button" onClick={() => setSelectedScreen(6)}>
          Prestador/RPA
          {selectedScreen === 6 && <span />}
        </button>
        <aside>
          <RxCross1 onClick={() => handleCloseModal()} />
        </aside>
      </header>

      <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <main>
          <div
            style={{ display: selectedScreen === 1 ? "flex" : "none" }}
            className="container-form"
          >
            <GeneralFields
              methods={methods}
              selectedPayroll={selectedPayroll}
            />
          </div>
          <div
            style={{ display: selectedScreen === 2 ? "flex" : "none" }}
            className="container-form"
          >
            <EarningsFields
              methods={methods}
              selectedPayroll={selectedPayroll}
            />
          </div>
          <div
            style={{ display: selectedScreen === 3 ? "flex" : "none" }}
            className="container-form"
          >
            <BenefitsFields
              methods={methods}
              selectedPayroll={selectedPayroll}
            />
          </div>
          <div
            style={{ display: selectedScreen === 4 ? "flex" : "none" }}
            className="container-form"
          >
            <DiscountsFields
              methods={methods}
              selectedPayroll={selectedPayroll}
            />
          </div>
          <div
            style={{ display: selectedScreen === 5 ? "flex" : "none" }}
            className="container-form"
          >
            <CompanyFields
              methods={methods}
              selectedPayroll={selectedPayroll}
            />
          </div>
          <div
            style={{ display: selectedScreen === 6 ? "flex" : "none" }}
            className="container-form"
          >
            <ProviderFields
              methods={methods}
              selectedPayroll={selectedPayroll}
            />
          </div>
        </main>
        <footer>
          {selectedScreen === 6 ? (
            <button type="submit" disabled={methods.formState.isSubmitting}>
              Confirmar
            </button>
          ) : (
            <button
              type="button"
              onClick={(event) => {
                event.preventDefault();
                setSelectedScreen(selectedScreen + 1);
              }}
            >
              Próximo
            </button>
          )}

          <button onClick={() => handleCloseModal()} type="button">
            Cancelar
          </button>
        </footer>
      </form>
    </div>
  );
}
