import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "@mui/material/Pagination";
import React, { useContext, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { ErrorsDetailController } from "../../Controllers/ErrorDetailsController/ErrorDetailsController";
import iconDescricao from "../../assets/iconDescricao.svg";
import iconOcultar from "../../assets/iconOcultar.svg";
import iconRegerar from "../../assets/iconRegerar.svg";
import iconReprocessar from "../../assets/iconReprocessar.svg";
import iconSugestao from "../../assets/iconSugestao.svg";
import json from "../../assets/json.png";
import { ErrorsContext } from "../../contexts/ErrorContext";
import { ModalConfirmacoes } from "../../utils/ModalConfirmacoes/ModalConfirmacoes";
import { ModalDetalhes } from "../../utils/ModalDetalhes/ModalDetalhes";
import { ModalJson } from "../../utils/ModalJson/ModalJson";

import "react-toastify/dist/ReactToastify.css";
import * as XLSX from "xlsx";
import { Header } from "../../components/Header";
import "./styles.scss";

export function ErrorDetails() {
  const errorsDetailController = new ErrorsDetailController();
  const {
    errors,
    setErrors,
    nrDiasAnalise,
    integrationPoint,
    getDetails,
    loadingDetails,
    setFiltros,
    filtros,
    totalRegistros,
    filiaisState,
    tpStatusErro,
    tpStatusAguardando,
    tpStatusSucesso,
    tpStatusOculto,
    EmpresaPontontegracao,
  } = useContext(ErrorsContext);

  const [openModalJson, setOpenModalJson] = React.useState(false);
  const [loadingPagination, setLoadingPagination] = useState(false);

  const [openModalConfirmacao, setOpenModalConfirmacao] = React.useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [idModal, setIdModal] = useState<number>();
  const [openModalDetalhes, setOpenModalDetalhes] = React.useState(false);
  const [titleModalDetalhes, setTitleModalDetalhes] = useState("");
  const [textoModalDetalhes, setTextoModalDetalhes] = useState("");
  const [jsonEnviado, setJsonEnviado] = useState("");
  const [respostaJson, setRespostaJson] = useState("");
  const paginas = Math.ceil(totalRegistros / 30);
  useEffect(() => {
    getDetails();
  }, []);

  const CreateXLS = async () => {
    for (let i = 0; i < errors.length; i++) {
      let erros = errors[i].detalheDTOAdapter;

      let binaryWS = XLSX.utils.json_to_sheet(erros);
      var wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, binaryWS, "Binary values");

      XLSX.writeFile(wb, `${errors[i].nomeEmpresa}.XLSX`);
    }
  };

  async function Reprocessar(cdIntegracao?: any) {
    await errorsDetailController
      .reprocessar(cdIntegracao)
      .then((response) => {
        toast.success("Reprocessado com sucesso");
        getDetails();
      })
      .catch((error) => {
        toast.error("Erro ao reprocessar");
      });
  }

  async function Regerar(cdIntegracao?: any) {
    await errorsDetailController
      .regerar(cdIntegracao)
      .then(() => {
        toast.success("Regerado com sucesso");
        getDetails();
      })
      .catch((error) => {
        toast.error("Erro ao regerar");
      });
  }
  async function Ocultar(cdIntegracao?: any) {
    await errorsDetailController
      .ocultar(cdIntegracao)
      .then((response) => {
        toast.success("Ocultado com sucesso");
      })
      .catch((error) => {
        toast.error("Erro ao ocultar");
      });
  }

  function manipulateModalConfirmacao(title: string, id: number) {
    setTitleModal(title);
    setIdModal(id);
    setOpenModalConfirmacao(true);
  }

  function manipulateModalDetalhes(texto: string, title: string) {
    if (texto === "" || texto === null) {
      return toast.warn("Descrição vazia");
    }
    setTitleModalDetalhes(title);
    setTextoModalDetalhes(texto);
    setOpenModalDetalhes(true);
  }

  async function getComPagina(e: any, value: any) {
    setLoadingPagination(true);
    await errorsDetailController
      .getList(value, 30, filtros)
      .then((response) => {
        setErrors(response.data.empresaDetalheDTOAdapterList);
        setLoadingPagination(false);
      })
      .catch((erro) => {
        toast.error("Erro ao buscar dados");
        setLoadingPagination(false);
      });
  }

  const downloadJSON = async (json: any, identificador: string) => {
    if (!json) {
      return toast.warn("Mensagem não está disponível !");
    }

    const possiveisFrases = ["Retorno servidor:", "Retorno MXM:"];

    for (let i = 0; i < possiveisFrases.length; i++) {
      const regex = new RegExp(possiveisFrases[i], "i");
      const resultado = regex.test(json);
      if (resultado) {
        const split = json.split(`, ${possiveisFrases[i]}`);
        setJsonEnviado(split[0]);
        setRespostaJson(split[1]);
        setOpenModalJson(true);
        return;
      } else {
        setJsonEnviado(json);
        setOpenModalJson(true);
      }
    }
    // const split = json.split(", Retorno servidor:");

    // setJsonEnviado(split[0]);
    // setRespostaJson(split[1]);
    // setOpenModalJson(true);
  };

  function populaFiltros(key: string, value: string) {
    setFiltros({ ...filtros, [key]: value });
  }

  return (
    <>
      {openModalJson && (
        <ModalJson
          setOpenModalJson={setOpenModalJson}
          jsonEnviado={jsonEnviado}
          respostaJson={respostaJson}
        />
      )}
      <section>
        <ToastContainer />
        <Header
          message={"Dashboard/Detalhes"}
          showAtualizacao={true}
          dayValue={nrDiasAnalise}
        />
        {loadingDetails && (
          <div className="loading">
            <h2>Carregando...</h2>
          </div>
        )}
        {!loadingDetails && (
          <div className="error-content ">
            <div className="error-container">
              <div className="error-container-header">
                <div className="error-container-header-title">
                  <h1>{integrationPoint}</h1>
                </div>
                <div className="error-container-header-filtros">
                  <div className="error-container-header-filtros-periodo">
                    <div className="error-container-header-filtros-periodo-title">
                      <p>Período</p>
                    </div>
                    <div className="error-container-header-filtros-periodo-inputs">
                      <input
                        type="date"
                        value={filtros.dataInicio}
                        onChange={(e) =>
                          populaFiltros("dataInicio", e.target.value)
                        }
                      />
                      <input
                        type="date"
                        value={filtros.dataFim}
                        onChange={(e) =>
                          populaFiltros("dataFim", e.target.value)
                        }
                      />
                    </div>
                  </div>
                  <div className="error-container-header-filtros-inputs">
                    <div className="error-container-header-filtros-inputs-id-status-filial">
                      <div className="error-container-header-filtros-inputs-id">
                        <p>ID</p>
                        <input
                          type="text"
                          value={filtros.idRegistro}
                          data-
                          onChange={(e) =>
                            populaFiltros("idRegistro", e.target.value)
                          }
                        />
                      </div>
                      <div className="error-container-header-filtros-inputs-status">
                        <p>Status</p>
                        <select
                          name=""
                          id=""
                          value={filtros.status}
                          onChange={(e) =>
                            populaFiltros("status", e.target.value)
                          }
                        >
                          <option value="">TODOS</option>
                          <option value={tpStatusErro}>Erro</option>
                          <option value={tpStatusAguardando}>Aguardando</option>
                          <option value={tpStatusSucesso}>Sucesso</option>
                          <option value={tpStatusOculto}>Oculto</option>
                        </select>
                      </div>
                      <div className="error-container-header-filtros-inputs-filial">
                        <p>Filial</p>
                        <select
                          value={filtros.dsFilial}
                          onChange={(e) =>
                            setFiltros({
                              ...filtros,
                              dsFilial: e.target.value,
                              filial:
                                e.target.options[
                                  e.target.selectedIndex
                                ].getAttribute("data-cdempresa"),
                            })
                          }
                        >
                          <option value="" data-nmempresa="">
                            TODAS
                          </option>
                          {filiaisState.map((item) => (
                            <option
                              data-cdempresa={item.cdMultiempresa}
                              value={item.nmMultiempresa}
                              key={item.cdMultiempresa}
                            >
                              {item.nmMultiempresa}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="error-container-header-filtros-inputs-filtrar">
                      <button onClick={() => getDetails()}>Filtrar</button>
                    </div>
                    <div className="error-container-header-filtros-inputs-botoes">
                      {/* <Button

                      > 
                        <img src={downloadDetails} alt="" />
                      </Button> */}
                      <Button onClick={() => CreateXLS()}>
                        <img
                          src="https://icons.iconarchive.com/icons/carlosjj/microsoft-office-2013/128/Excel-icon.png"
                          width="30"
                          height="30"
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="error-container-body">
                {errors.map((item) => (
                  <>
                    <div className="error-container-body-title">
                      <h3>{item.nomeEmpresa}</h3>
                    </div>
                    <table>
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th>ID</th>
                          <th>Data Gerado</th>
                          <th>Data Processado</th>
                          <th>Descrição</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.detalheDTOAdapter.map((e, index) => (
                          <tr>
                            {e.tpStatus === tpStatusSucesso && (
                              <td colSpan={1}>
                                <div className="dsStatus-sucesso" />
                              </td>
                            )}
                            {e.tpStatus === tpStatusErro && (
                              <td colSpan={1}>
                                <div className="dsStatus-erro" />
                              </td>
                            )}
                            {e.tpStatus === tpStatusAguardando && (
                              <td colSpan={1}>
                                <div className="dsStatus-aguardando" />
                              </td>
                            )}
                            {e.tpStatus === tpStatusOculto && (
                              <td colSpan={1}>
                                <div className="dsStatus-outro" />
                              </td>
                            )}
                            {e.tpStatus === "R" && <td colSpan={1}></td>}

                            {e.tpStatus !== tpStatusOculto &&
                              e.tpStatus !== tpStatusAguardando &&
                              e.tpStatus !== tpStatusSucesso &&
                              e.tpStatus !== tpStatusErro &&
                              e.tpStatus !== "R" && (
                                <td colSpan={1}>
                                  <div className="dsStatus-noStatus" />
                                </td>
                              )}
                            <td colSpan={1}>{e.identificador}</td>
                            <td className="data-gerada-td" colSpan={1}>
                              {e.dataHoraGeradoFormat}
                            </td>
                            <td className="data-gerada-td" colSpan={1}>
                              {e.dataHoraProcessadoFormat}
                            </td>
                            <td className="descricao-td" colSpan={1}>
                              {e.descricaoErro !== null
                                ? `${e.descricaoErro.substring(0, 70)}...`
                                : ""}
                            </td>
                            <td className="error-container-body-td-acoes">
                              <div
                                className="error-container-body-td-acoes-descricao"
                                onClick={() =>
                                  manipulateModalDetalhes(
                                    e.descricaoErro,
                                    "Descrição do Erro:"
                                  )
                                }
                              >
                                <img src={iconDescricao} alt="" />
                                <div>
                                  <p>Detalhes</p>
                                </div>
                              </div>
                              <div
                                className="error-container-body-td-acoes-json"
                                onClick={() =>
                                  downloadJSON(e.descricaoJson, e.identificador)
                                }
                              >
                                <img src={json} alt="" />
                                <div>
                                  <p>Mensagens</p>
                                </div>
                              </div>
                              <div
                                className="error-container-body-td-acoes-reprocessar"
                                onClick={() =>
                                  manipulateModalConfirmacao(
                                    "reprocessar",
                                    e.cdIntegracao
                                  )
                                }
                              >
                                <img src={iconReprocessar} alt="" />
                                <div>
                                  <p>Reprocessar</p>
                                </div>
                              </div>

                              <div
                                className={
                                  EmpresaPontontegracao?.snQueryRegerar === "N"
                                    ? "error-container-body-td-acoes-reprocessar-disabled"
                                    : "error-container-body-td-acoes-reprocessar"
                                }
                                onClick={() => {
                                  if (
                                    EmpresaPontontegracao?.snQueryRegerar ===
                                    "N"
                                  ) {
                                    return;
                                  }
                                  manipulateModalConfirmacao(
                                    "regerar",
                                    e.cdIntegracao
                                  );
                                }}
                              >
                                <img src={iconRegerar} alt="" />
                                <div>
                                  <p>Regerar</p>
                                </div>
                              </div>

                              <div
                                className="error-container-body-td-acoes-ocultar"
                                onClick={() =>
                                  manipulateModalConfirmacao(
                                    "ocultar",
                                    e.cdIntegracao
                                  )
                                }
                              >
                                <img src={iconOcultar} alt="" />
                                <div>
                                  <p>Ocultar</p>
                                </div>
                              </div>
                              <div
                                className="error-container-body-td-acoes-sugestao"
                                onClick={() =>
                                  manipulateModalDetalhes(
                                    e.sugestaoErro,
                                    "Sugestão para corrigir os erros:"
                                  )
                                }
                              >
                                <img src={iconSugestao} alt="" />
                                <div>
                                  <p>Sugestão</p>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}

                        {openModalConfirmacao && (
                          <ModalConfirmacoes
                            id={idModal}
                            title={titleModal}
                            setFlagModal={setOpenModalConfirmacao}
                            reprocessar={Reprocessar}
                            ocultar={Ocultar}
                            regerar={Regerar}
                          />
                        )}
                        {openModalDetalhes && (
                          <ModalDetalhes
                            setFlagModal={setOpenModalDetalhes}
                            text={textoModalDetalhes}
                            title={titleModalDetalhes}
                          />
                        )}
                      </tbody>
                    </table>
                  </>
                ))}
                <div className="error-container-body-pagination">
                  <Pagination
                    count={paginas}
                    variant="outlined"
                    shape="rounded"
                    onChange={getComPagina}
                  />
                  {loadingPagination && <CircularProgress />}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}
