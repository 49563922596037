import { AggregateUnitsSchemaProps } from "../../../../components/CustomScreens/totvs/FinancialRelease/AggregateUnits/ModalAggregateUnits/schema";
import { customScreenApi } from "../../../../services/api";

export const getAggregateUnits = async (
  numPagamento: number,
  prestacaoContaId: number,
  page?: number
) => {
  const response = await customScreenApi.get(
    `/lancamentosFinanceiros/unidadesAgregadas/doc/${numPagamento}`,
    {
      params: {
        size: 7,
        page: page || 0,
      },
      headers: {
        prestacaoContaId: prestacaoContaId,
      },
    }
  );
  return response;
};

export const postAggregateUnits = async (data: AggregateUnitsSchemaProps) => {
  const response = await customScreenApi.post(
    "/lancamentosFinanceiros/unidadesAgregadas",
    {
      ...data,
      parceriaId: sessionStorage.getItem("parceriaId"),
    }
  );
  return response;
};

export const putAggregateUnits = async (
  data: AggregateUnitsSchemaProps,
  cdUnidadesAgregadas: number
) => {
  const response = await customScreenApi.put(
    "/lancamentosFinanceiros/unidadesAgregadas",
    {
      ...data,
      cdUnidadesAgregadas: cdUnidadesAgregadas,
      // quantidade: data.quantidade !== "" ? Number(data.quantidade) : null,
      // valor: data.valor !== "" ? Number(data.valor) : null,
      numPagamento: data.numPagamento !== "" ? Number(data.numPagamento) : null,
      parceriaId: sessionStorage.getItem("parceriaId"),
    }
  );
  return response;
};

export const deleteAggregateUnits = async (cdUnidadesAgregadas: number) => {
  const response = await customScreenApi.delete(
    `/lancamentosFinanceiros/unidadesAgregadas/${cdUnidadesAgregadas}`
  );
  return response;
};

export const getDeparaTipoDespesaFinancialRelease = async () => {
  const response = await customScreenApi.get("/depara/despesa");
  return response;
};
