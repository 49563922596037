import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  postWithheldTaxes,
  putWithheldTaxes,
} from "../../../../../../Controllers/CustomScreens/FinancialRelease/WithheldTaxes/WithheldTaxesController";
import { ContentProps } from "../useWithheldTaxes";
import { WithheldTaxesSchema, WithheldTaxesSchemaProps } from "./schema";

interface UseModalWithheldTaxes {
  handleCloseModal: () => void;
  selectedWithheldTaxes: ContentProps;
  handleGetWithheldTaxes: () => void;
}
export const useModalWithheldTaxes = ({
  handleCloseModal,
  selectedWithheldTaxes,
  handleGetWithheldTaxes,
}: UseModalWithheldTaxes) => {
  const { numPagamento, prestacaoContaId } = useParams();
  const methods = useForm<WithheldTaxesSchemaProps>({
    resolver: zodResolver(WithheldTaxesSchema),
    values: {
      numPagamento: numPagamento,
      prestacaoContaId: Number(prestacaoContaId),
    },
  });
  async function handleSubmitForm(form: WithheldTaxesSchemaProps) {
    if (selectedWithheldTaxes.cdPagTribRet === undefined) {
      try {
        await postWithheldTaxes(form);
        handleGetWithheldTaxes();
        handleCloseModal();
        toast.success("Registro inserido com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao cadastrar, por favor verifique os campos preenchidos!"
        );
      }
    } else {
      try {
        await putWithheldTaxes(form, selectedWithheldTaxes.cdPagTribRet);
        handleGetWithheldTaxes();
        handleCloseModal();
        toast.success("Registro alterado com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao alterar, por favor verifique os campos preenchidos!"
        );
      }
    }
  }

  return { handleSubmitForm, methods };
};
