import { Control, Controller } from "react-hook-form";

interface CustomControllerProps {
  control: Control;
  defaultValue: number | string;
  name: string;
  type: "text" | "number";
  hasStep?: boolean;
}
export function CustomController({
  control,
  defaultValue,
  name,
  type,
  hasStep,
}: CustomControllerProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <input
          step={hasStep ? "0.01" : "1"}
          type={type}
          autoComplete="off"
          {...field}
          onChange={(e) =>
            type !== "text"
              ? field.onChange(parseFloat(e.target.value))
              : field.onChange(e.target.value)
          }
        />
      )}
      defaultValue={defaultValue}
    />
  );
}
