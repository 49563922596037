import { z } from "zod";

export const PayrollSchema = z.object({
  plantaoMensal: z.number().nullable(),
  abonoPecuniario: z.number().nullable(),
  irrfSrpa: z.number().nullable(),
  adcDecimoTerceiroFeriasPropResc: z.number().nullable(),
  adiantamentoSalario: z.number().nullable(),
  adicionalInsalubridade: z.number().nullable(),
  adicionalNoturno: z.number().nullable(),
  adicionalNoturnoDsr: z.number().nullable(),
  adicionalPericulosidade: z.number().nullable(),
  arredondamento: z.number().nullable(),
  atestadoAteQuinzeDias: z.number().nullable(),
  autoDeclaracaoGenero: z.string().min(1),
  autoDeclaracaoRacial: z.string().nullable(),
  auxilioCreche: z.number().nullable(),
  auxilios: z.number().nullable(),
  baseFgts: z.number().nullable(),
  baseFgtsDecimoTerceiro: z.number().nullable(),
  baseFgtsDecimoTerceiroDemitidos: z.number().nullable(),
  baseFgtsDemitidos: z.number().nullable(),
  baseFgtsFerias: z.number().nullable(),
  baseFgtsRescisao: z.number().nullable(),
  baseInss: z.number().nullable(),
  baseInssDecimoTerceiro: z.number().nullable(),
  baseInssFerias: z.number().nullable(),
  baseInssRescisao: z.number().nullable(),
  baseIrrf: z.number().nullable(),
  baseIrrfDecimoTerceiro: z.number().nullable(),
  baseIrrfFerias: z.number().nullable(),
  baseIrrfPlrAnual: z.number().nullable(),
  baseIrrfRescisao: z.number().nullable(),
  basePisDecimoTerceiroSemFolha: z.number().nullable(),
  basePisFeriasSemFolha: z.number().nullable(),
  basePisSemFolha: z.number().nullable(),
  basePisRescisaoSemFolha: z.number().nullable(),
  cdFolhaPagamento: z.number().nullable().optional(),
  cestaBasica: z.number().nullable(),
  cnsProfissional: z.string().nullable(),
  cpf: z.string().nullable(),
  codigoCbo: z.string().min(1),
  dataAdmissao: z.string().nullable(),
  dataDesligamento: z.string().nullable(),
  dataNascimento: z.string().nullable(),
  decimoTerPropRescisao: z.number().nullable(),
  decimoTerceiroIndenizado: z.number().nullable(),
  decimoTerceiroPrimeiraParcela: z.number().nullable(),
  decimoTerceiroSegundaParcela: z.number().nullable(),
  deducoesGps: z.number().nullable(),
  desAdianDecimoTerceiro: z.number().nullable(),
  desAdiantamentoSalario: z.number().nullable(),
  desArredonSaldoDesconto: z.number().nullable(),
  desAvisoPrevio: z.number().nullable(),
  desContriSindicalAnual: z.number().nullable(),
  desContriSindicalMensal: z.number().nullable(),
  desEmprestimosConsignados: z.number().nullable(),
  desMultaResAntecipado: z.number().nullable(),
  desPrimeParcelaDecTer: z.number().nullable(),
  desValetransIndivi: z.number().nullable(),
  descontoInssRpa: z.number().nullable(),
  descontosAntecipacaoFolha: z.number().nullable(),
  descontosArredondamento: z.number().nullable(),
  descontosAuxilios: z.number().nullable(),
  descontosConvenios: z.number().nullable(),
  descontosDsr: z.number().nullable(),
  descontosEstorno: z.number().nullable(),
  descontosFaltas: z.number().nullable(),
  descontosInss: z.number().nullable(),
  descontosInssDecimoTerceiro: z.number().nullable(),
  descontosInssFerias: z.number().nullable(),
  descontosInssRescisao: z.number().nullable(),
  descontosIrrf: z.number().nullable(),
  descontosIrrfDecimoTerceiro: z.number().nullable(),
  descontosIrrfFerias: z.number().nullable(),
  descontosIrrfRescisao: z.number().nullable(),
  descontosMultaRescisoria: z.number().nullable(),
  descontosNaoDedutiveis: z.number().nullable(),
  descontosOutros: z.number().nullable(),
  descontosPagamentoIndevido: z.number().nullable(),
  descontosPlanoSaude: z.number().nullable(),
  descontosSeguroVida: z.number().nullable(),
  devolucao: z.number().nullable(),
  diferencaSalarial: z.number().nullable(),
  encargosSobreRescisao: z.number().nullable(),
  especificacao: z.string().nullable(),
  ferias: z.number().nullable(),
  feriasAbono: z.number().nullable(),
  feriasProporcionaisRescisao: z.number().nullable(),
  feriasProximoMes: z.number().nullable(),
  fgts: z.number().nullable(),
  fgtsDecimoTerceiro: z.number().nullable(),
  fgtsFerias: z.number().nullable(),
  fgtsRescisao: z.number().nullable(),
  fgtsRescisaoAntecipada: z.number().nullable(),
  gratificacao: z.number().nullable(),
  grrf: z.number().nullable(),
  horasExtras: z.number().nullable(),
  horasExtrasDsr: z.number().nullable(),
  inssPatronal: z.number().nullable(),
  inssRpaPatronal: z.number().nullable(),
  inssTerceiros: z.number().nullable(),
  issRpa: z.number().nullable(),
  jornada: z.number().nullable(),
  multaRescisao: z.number().nullable(),
  nome: z.string().nullable(),
  nomeSocial: z.string().nullable(),
  numConselhoClasse: z.string().nullable(),
  outrasVerbasRescisorias: z.number().nullable(),
  outrosBeneficios: z.number().nullable(),
  outrosProventos: z.number().nullable(),
  parceriaId: z.number().nullable().optional(),
  pensaoAlimenticia: z.number().nullable(),
  pisSemFolha: z.number().nullable(),
  premio: z.number().nullable(),
  prestacaoContaId: z.number().optional(),
  ratfap: z.number().nullable(),
  regimeContratacao: z.string().min(1),
  restituicaoInss: z.number().nullable(),
  restituicaoIrrf: z.number().nullable(),
  salarioBase: z.number().nullable(),
  salarioBruto: z.number().nullable(),
  salarioFamilia: z.number().nullable(),
  salarioLiquido: z.number().nullable(),
  salarioMaternidade: z.number().nullable(),
  saldoSalarioRescisao: z.number().nullable(),
  tipoAtividade: z.string().min(1),
  tipoColaborador: z.string().min(1),
  tipoCoordenadoria: z.string().min(1),
  tipoFolha: z.string().nullable(),
  tipoJornada: z
    .number()
    .or(z.string())
    .nullable()
    .transform((value) => Number(value)),
  valeAlimentacao: z.number().nullable(),
  valeRefeicao: z.number().nullable(),
  valeTransporte: z.number().nullable(),
  valorBrutoRescisao: z.number().nullable(),
  valorBrutoRpa: z.number().nullable(),
  valorLiquidoRescisaoApagar: z.number().nullable(),
  valorLiquidoRpa: z.number().nullable(),
});

export type PayrollSchemaProps = z.infer<typeof PayrollSchema>;
