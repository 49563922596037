//import "./styles";

import { Button, Dialog, LinearProgress, Pagination } from "@mui/material";
import { BsPencilSquare } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import iconOcultar from "../../../../../assets/iconOcultar.svg";
import { Header } from "../../../../Header";
import { ConfirmExclude } from "../../ConfirmExclude";
import { ModalWithheldTaxes } from "./ModalWithheldTaxes";
import "./styles.scss";
import { ContentProps, useWithheldTaxes } from "./useWithheldTaxes";
export function WithheldTaxes() {
  const {
    WithheldTaxes,
    selectedWithheldTaxes,
    handleGetWithheldTaxes,
    setSelectedWithheldTaxes,
    handleEditModal,
    modalState,
    setModalState,
    handleDeleteWithheldTaxes,
    handlePaginate,
    isLoading,
    withheldToDelete,
    setWithheldToDelete,
    showModalExclude,
    setShowModalExclude,
  } = useWithheldTaxes();
  return (
    <div className="WithheldTaxes-container">
      <Header
        message={"Dashboard/Lançamentos Financeiros/Tributos Retidos"}
        showAtualizacao={true}
        backScreen={true}
        url="LancamentosFinanceiros"
      />
      <main>
        <div className="WithheldTaxes-main-card">
          <header className="WithheldTaxes-main-card-header">
            <h2>Tributos Retidos</h2>

            <div className="header-buttons">
              <aside>
                <Button variant="outlined" onClick={() => setModalState(true)}>
                  Novo Registro <FaPlus />
                </Button>
              </aside>
            </div>
          </header>
          <section className="WithheldTaxes-main-card-section">
            <Dialog
              open={modalState}
              onClose={() => (
                setModalState(false),
                setSelectedWithheldTaxes({} as ContentProps)
              )}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ModalWithheldTaxes
                handleCloseModal={() => (
                  setModalState(false),
                  setSelectedWithheldTaxes({} as ContentProps)
                )}
                handleGetWithheldTaxes={() => handleGetWithheldTaxes()}
                selectedWithheldTaxes={selectedWithheldTaxes}
              />
            </Dialog>
            <Dialog
              open={showModalExclude}
              onClose={() => setShowModalExclude(false)}
              PaperProps={{
                style: {
                  backgroundColor: " hsl(206, 100%, 12%)",
                },
              }}
            >
              <ConfirmExclude
                handleCloseModal={() => setShowModalExclude(false)}
                handleSubmit={() =>
                  handleDeleteWithheldTaxes(withheldToDelete.cdPagTribRet)
                }
              />
            </Dialog>
            {!isLoading ? (
              <table>
                {WithheldTaxes?.content?.length !== 0 ? (
                  <thead>
                    <tr>
                      <th>Id fornecedor</th>
                      <th>Fornecedor cnpj cpf</th>
                      <th>Id linha serviço </th>
                      <th>Id nota fiscal</th>
                      <th>Número pagamento</th>
                      <th>Id tributo</th>
                      <th>Id unidade</th>
                      <th>Valor linha serviço</th>
                      <th>Valor unidade</th>
                    </tr>
                  </thead>
                ) : (
                  <p>Nenhum Registro Encontrado</p>
                )}
                <tbody>
                  {WithheldTaxes?.content?.map((taxes, index) => (
                    <tr key={index}>
                      <td>{taxes.fornecedorId}</td>
                      <td>{taxes.fornecedorcnpjcpf}</td>
                      <td>{taxes.linhaServicoId}</td>
                      <td>{taxes.notaFiscalId}</td>
                      <td>{taxes.numPagamento}</td>
                      <td>{taxes.tributoId}</td>
                      <td>{taxes.unidadeId}</td>
                      <td>{taxes.valorLinhaServico}</td>
                      <td>{taxes.valorUnidade}</td>
                      <td>
                        <div className="WithheldTaxes-main-card-section-icons">
                          <p
                            data-tip="Editar Registro"
                            onClick={() => handleEditModal(taxes)}
                          >
                            <BsPencilSquare color="#D8A511" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                          <p
                            data-tip="Excluir Registro"
                            onClick={() => {
                              setWithheldToDelete(taxes);
                              setShowModalExclude(true);
                            }}
                          >
                            <img src={iconOcultar} alt="" />
                          </p>
                          <ReactTooltip
                            place="top"
                            type="light"
                            effect="solid"
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <LinearProgress />
            )}
          </section>
          <footer>
            <Pagination
              count={WithheldTaxes.totalPages}
              variant="outlined"
              shape="rounded"
              onChange={handlePaginate}
            />
          </footer>
        </div>
      </main>
    </div>
  );
}
