import { z } from "zod";

export const WithheldTaxesSchema = z.object({
  fornecedorId: z.string().nullable(),
  fornecedorcnpjcpf: z.string().nullable(),
  linhaServicoId: z.string().nullable(),
  notaFiscalId: z.string().nullable(),
  numPagamento: z.string().nullable(),
  tributoId: z.string().nullable(),
  unidadeId: z.string().nullable(),
  valorLinhaServico: z.number().nullable(),
  valorUnidade: z.number().nullable(),
  prestacaoContaId: z.number().optional(),
});

export type WithheldTaxesSchemaProps = z.infer<typeof WithheldTaxesSchema>;
