import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getDeparaTipoDespesaFinancialRelease,
  postAggregateUnits,
  putAggregateUnits,
} from "../../../../../../Controllers/CustomScreens/FinancialRelease/AggregateUnitsController/AggregateUnitsController";
import { ContentProps } from "../useAggregateUnits";
import { AggregateUnitsSchema, AggregateUnitsSchemaProps } from "./schema";

interface UseModalAggregateUnits {
  handleCloseModal: () => void;
  selectedAggregateUnits: ContentProps;
  handleGetAggregateUnits: () => void;
}
interface DeparaProps {
  cdDepara: number;
  cdDeparaOrigem: string;
  cdDeparaSicap: string;
  dsDeparaOrigem: string;
  dsDeparaSicap: string;
  parceriaId: number;
  tpDepara: string;
}
export const useModalAggregateUnits = ({
  handleCloseModal,
  selectedAggregateUnits,
  handleGetAggregateUnits,
}: UseModalAggregateUnits) => {
  const { numPagamento, prestacaoContaId } = useParams();

  const methods = useForm<AggregateUnitsSchemaProps>({
    resolver: zodResolver(AggregateUnitsSchema),
    values: {
      numPagamento: numPagamento,
      prestacaoContaId: Number(prestacaoContaId),
    },
    defaultValues: {
      tipoDespesaId:
        selectedAggregateUnits.tipoDespesaId === "" ||
          selectedAggregateUnits.tipoDespesaId === undefined
          ? ""
          : selectedAggregateUnits.tipoDespesaId,
    },
  });

  const [deparaTipoDocumento, setDeparaTipoDocumento] = useState<DeparaProps[]>(
    []
  );
  async function handleGetTipoDespesaDepara() {
    try {
      const { data } = await getDeparaTipoDespesaFinancialRelease();
      setDeparaTipoDocumento(data);
    } catch (error) { }
  }

  useEffect(() => {
    setTimeout(() => {
      handleGetTipoDespesaDepara();
    }, 1000);
  }, []);
  async function handleSubmitForm(form: AggregateUnitsSchemaProps) {
    if (selectedAggregateUnits.cdUnidadesAgregadas === undefined) {
      try {
        await postAggregateUnits(form);
        handleCloseModal();
        handleGetAggregateUnits();
        toast.success("Unidade agregada inserida com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao cadastrar Unidade, por favor verifique os dados informados!"
        );
      }
    } else {
      try {
        await putAggregateUnits(
          form,
          selectedAggregateUnits.cdUnidadesAgregadas
        );
        handleCloseModal();
        handleGetAggregateUnits();
        toast.success("Unidade agregada alterada com sucesso!");
      } catch (error) {
        toast.error(
          "Erro ao alterar Unidade, por favor verifique os dados informados!"
        );
      }
    }
  }

  return {
    handleSubmitForm,
    methods,
    deparaTipoDocumento,
  };
};
