import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  postAccountPlan,
  putAccountPlan,
} from "../../../../../../Controllers/CustomScreens/FinancialRelease/AccountPlanController/AccountPlanController";
import { getDeparaTipoDespesaFinancialRelease } from "../../../../../../Controllers/CustomScreens/FinancialRelease/AggregateUnitsController/AggregateUnitsController";
import { ContentProps } from "../useAccountPlan";
import { AccountPlanSchema, AccountPlanSchemaProps } from "./schema";
interface DeparaProps {
  cdDepara: number;
  cdDeparaOrigem: string;
  cdDeparaSicap: string;
  dsDeparaOrigem: string;
  dsDeparaSicap: string;
  parceriaId: number;
  tpDepara: string;
}
interface UseModalAccountPlanProps {
  handleCloseModal: () => void;
  handleGetAccountPlan: () => void;
  selectedAccountPlan: ContentProps;
}
export const useModalAccountPlan = ({
  handleCloseModal,
  handleGetAccountPlan,
  selectedAccountPlan,
}: UseModalAccountPlanProps) => {
  const { numPagamento } = useParams();
  const methods = useForm<AccountPlanSchemaProps>({
    resolver: zodResolver(AccountPlanSchema),
    values: {
      numPagamento: numPagamento,
    },
    defaultValues: {
      tipoDespesaId:
        selectedAccountPlan.tipoDespesaId === "" ||
          selectedAccountPlan.tipoDespesaId === undefined
          ? ""
          : selectedAccountPlan.tipoDespesaId,
    },
  });

  const [deparaTipoDocumento, setDeparaTipoDocumento] = useState<DeparaProps[]>(
    []
  );
  async function handleGetTipoDespesaDepara() {
    try {
      const { data } = await getDeparaTipoDespesaFinancialRelease();
      setDeparaTipoDocumento(data);
    } catch (error) { }
  }

  useEffect(() => {
    handleGetTipoDespesaDepara();
  }, []);
  async function handleSubmitForm(form: AccountPlanSchemaProps) {
    if (selectedAccountPlan.cdPlanoConta === undefined) {
      try {
        await postAccountPlan(form);
        handleGetAccountPlan();
        handleCloseModal();
        methods.reset();
      } catch (error) {
        toast.error(
          "Erro ao cadastrar plano de conta, por favor verifique os campos preenchidos!"
        );
      }
    } else {
      try {
        await putAccountPlan(form, selectedAccountPlan.cdPlanoConta);
        handleGetAccountPlan();
        handleCloseModal();
        methods.reset();
      } catch (error) { }
    }
  }

  return { handleSubmitForm, methods, deparaTipoDocumento };
};
