import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment";
import { Link } from "react-router-dom";
import logo from "../../assets/LogoArgo.svg";
import "./styles.scss";

interface Props {
  message?: string;
  dayValue?: number;
  showAtualizacao?: boolean;
  showLogo?: boolean;
  backScreen?: boolean;
  url?: string;
}

export function Header(props: Props) {
  return (
    <header>
      <div className="text">
        {props.showLogo ? (
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        ) : (
          <Link to={props.backScreen ? `/${props.url}` : "/"}>
            <ArrowBackIcon className="arrow" />
            <p>{props.message}</p>
          </Link>
        )}

        {props.showAtualizacao && (
          <h6>
            Atualização: {moment().format("DD/MM/YY")} -{" "}
            {moment().format("HH:mm")} - Últimos {props.dayValue} Dias
          </h6>
        )}
      </div>

      <div className="divider"></div>
    </header>
  );
}
