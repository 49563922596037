import { RxCross1 } from "react-icons/rx";
import { CustomController } from "../../../../../utils/FormController/FormController";
import { ContentProps } from "../useFinancialRelease";
import "./styles.scss";
import { useModalFinancialRelease } from "./useModalFinancialRelease";

interface ModalRegisterComponentProps {
  handleCloseModal: () => void;
  handleGetFinancialRelease: () => void;
  selectedFinancialRelease: ContentProps;
  prestacaoContaId: number;
}
export function ModalFinancialRelease({
  handleCloseModal,
  handleGetFinancialRelease,
  selectedFinancialRelease,
  prestacaoContaId,
}: ModalRegisterComponentProps) {
  const {
    handleSubmitForm,
    methods,
    deparaTipoOperacao,
    deparaTipoDocumento,
    deparaClassificacaoPagamento,
    deparaTipoFornecedor,
    deparaFonteRecurso,
    handleFileUpload,
    fileName,
  } = useModalFinancialRelease({
    handleCloseModal: handleCloseModal,
    handleGetFinancialRelease: handleGetFinancialRelease,
    selectedFinancialRelease: selectedFinancialRelease,
    prestacaoContaId: prestacaoContaId,
  });

  return (
    <div className="modal-register-container-finance">
      <header>
        <RxCross1 onClick={() => handleCloseModal()} />
      </header>
      <form onSubmit={methods.handleSubmit(handleSubmitForm)}>
        <main>
          <div className="input-group-normal">
            <p>Id classificação fornecedor </p>
            <CustomController
              name="lancamento.classificacaoFornecedorId"
              type="text"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.classificacaoFornecedorId || null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Vencimento</p>
            <input
              type="date"
              autoComplete="off"
              {...methods.register("lancamento.vencimento")}
            />
          </div>
          <div className="input-group-small">
            <p>Chave Nfe</p>
            <CustomController
              name="lancamento.chaveNfe"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.chaveNfe || null}
            />
          </div>
          <div className="input-group-normal">
            <p>Id classificação investimento</p>
            <CustomController
              name="lancamento.classificacaoInvestimentoId"
              type="text"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.classificacaoInvestimentoId || null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Id pagamento</p>
            <select {...methods.register("lancamento.classificacaoPgtoId")}>
              <option hidden>
                {selectedFinancialRelease.classificacaoPgtoId !== null
                  ? deparaClassificacaoPagamento.find(
                    (tipo) =>
                      tipo.cdDeparaSicap ===
                      selectedFinancialRelease.classificacaoPgtoId
                  )?.dsDeparaSicap || "Selecione"
                  : "Selecione"}
              </option>
              {deparaClassificacaoPagamento.map((tipoOperacao) => (
                <option
                  key={tipoOperacao.cdDepara}
                  value={tipoOperacao.cdDeparaSicap}
                >
                  {tipoOperacao.dsDeparaSicap}
                </option>
              ))}
            </select>
          </div>
          <div className="input-group-small">
            <p>Clss</p>
            <CustomController
              name="lancamento.clss"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.clss !== null &&
                  selectedFinancialRelease.clss !== undefined
                  ? selectedFinancialRelease.clss
                  : null
              }
            />
          </div>
          <div className="input-group-normal">
            <p>Arquivo NF</p>
            <input
              type="file"
              id="file"
              className="input-file"
              accept=".pdf"
              multiple={false}
              onChange={(e) => handleFileUpload(e.target.files)}
            />
            <label className="custom-file-upload" htmlFor="file">
              {fileName?.length > 25
                ? fileName?.slice(0, 25) + "..."
                : fileName ||
                  methods.getValues("arquivo.nomeArquivo")?.length > 25
                  ? methods.getValues("arquivo.nomeArquivo")?.slice(0, 25) + "..."
                  : methods.getValues("arquivo.nomeArquivo")}
            </label>

            {/* <CustomController
              name="arquivoNf"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.arquivoNf || null}
            /> */}
          </div>{" "}
          <div className="input-group-normal">
            <p>Conta contábil banco</p>
            <CustomController
              name="lancamento.contaContabilBancaria"
              type="text"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.contaContabilBancaria || null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Confins</p>
            <CustomController
              name="lancamento.confins"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.confins !== null &&
                  selectedFinancialRelease.confins !== undefined
                  ? selectedFinancialRelease.confins
                  : null
              }
            />
          </div>
          {/* <div className="input-group-small">
            <p>Id conta contábil</p>
            <CustomController
              name="lancamento.contaContabilId"
              type="number"
              control={methods.control}
              defaultValue={selectedFinancialRelease.contaContabilId || null}
            />
          </div> */}
          {/* <div className="input-group-normal">
            <p>Id contrato Terceirizado</p>
            <CustomController
              name="lancamento.contratoTerceirizadoId"
              type="text"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.contratoTerceirizadoId || null
              }
            />
          </div> */}
          <div className="input-group-small">
            <p>Data emissão</p>
            <input
              type="date"
              autoComplete="off"
              {...methods.register("lancamento.dataEmissao")}
            />
          </div>
          <div className="input-group-normal">
            <p>Doc retenção fonte</p>
            <select {...methods.register("lancamento.docRetencaoFonte")}>
              <option hidden>
                {selectedFinancialRelease.docRetencaoFonte !== null
                  ? selectedFinancialRelease.docRetencaoFonte
                    ? "SIM"
                    : "NÃO"
                  : "Selecione"}
              </option>
              <option value="true">SIM</option>
              <option value="false">NÃO</option>
            </select>
          </div>
          <div className="input-group-small">
            <p>Data pagamento</p>
            <input
              type="date"
              autoComplete="off"
              {...methods.register("lancamento.dataPagamento")}
            />
          </div>
          <div className="input-group-small">
            <p>Doc Cnpj Cpf</p>
            <CustomController
              name="lancamento.documentosCnpjCpf"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.documentosCnpjCpf || null}
            />
          </div>
          <div className="input-group-small">
            <p>Desconto</p>
            <CustomController
              name="lancamento.desconto"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.desconto !== null &&
                  selectedFinancialRelease.desconto !== undefined
                  ? selectedFinancialRelease.desconto
                  : null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Fonte recurso cod</p>
            <select {...methods.register("lancamento.fonteRecursoCod")}>
              <option hidden>
                {selectedFinancialRelease.fonteRecursoCod !== null
                  ? deparaFonteRecurso.find(
                    (tipo) =>
                      tipo.cdDeparaSicap ===
                      selectedFinancialRelease.fonteRecursoCod
                  )?.dsDeparaSicap || "Selecione"
                  : "Selecione"}
              </option>
              {deparaFonteRecurso.map((tipoOperacao) => (
                <option
                  key={tipoOperacao.cdDepara}
                  value={tipoOperacao.cdDeparaSicap}
                >
                  {tipoOperacao.dsDeparaSicap}
                </option>
              ))}
            </select>
            {/* <CustomController
              name="fonteRecursoCod"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.fonteRecursoCod || null}
            /> */}
          </div>
          {/* <div className="input-group-small">
            <p>Id fornecedor</p>
            <CustomController
              name="lancamento.fornecedorId"
              type="number"
              control={methods.control}
              defaultValue={selectedFinancialRelease.fornecedorId || null}
            />
          </div> */}
          <div className="input-group-normal">
            <p>Fornecedor cnpj cpf</p>
            <CustomController
              name="lancamento.fornecedorcnpjcpf"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.fornecedorcnpjcpf || null}
            />
          </div>
          <div className="input-group-small">
            <p>Frete</p>
            <CustomController
              name="lancamento.frete"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.frete !== null &&
                  selectedFinancialRelease.frete !== undefined
                  ? selectedFinancialRelease.frete
                  : null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Histórico</p>
            <CustomController
              name="lancamento.historico"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.historico || null}
            />
          </div>
          <div className="input-group-small">
            <p>Icmsst</p>
            <CustomController
              name="lancamento.icmsst"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.icmsst !== null &&
                  selectedFinancialRelease.icmsst !== undefined
                  ? selectedFinancialRelease.icmsst
                  : null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Inss</p>
            <CustomController
              name="lancamento.inss"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.inss !== null &&
                  selectedFinancialRelease.inss !== undefined
                  ? selectedFinancialRelease.inss
                  : null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Ipi</p>
            <CustomController
              name="lancamento.ipi"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.ipi !== null &&
                  selectedFinancialRelease.ipi !== undefined
                  ? selectedFinancialRelease.ipi
                  : null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Irrf</p>
            <CustomController
              name="lancamento.irrf"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.irrf !== null &&
                  selectedFinancialRelease.irrf !== undefined
                  ? selectedFinancialRelease.irrf
                  : null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Iss</p>
            <CustomController
              name="lancamento.iss"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.iss !== null &&
                  selectedFinancialRelease.iss !== undefined
                  ? selectedFinancialRelease.iss
                  : null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Juros Multa</p>
            <CustomController
              name="lancamento.jurosMulta"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.jurosMulta !== null &&
                  selectedFinancialRelease.jurosMulta !== undefined
                  ? selectedFinancialRelease.jurosMulta
                  : null
              }
            />
          </div>
          <div className="input-group-normal">
            <p>Número Documento</p>
            <CustomController
              name="lancamento.numDocumento"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.numDocumento || ""}
            />
          </div>
          <div className="input-group-small">
            <p>Nf Doc</p>
            <CustomController
              name="lancamento.nfDoc"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.nfDoc || null}
            />
          </div>
          <div className="input-group-small">
            <p>pcc</p>
            <CustomController
              name="lancamento.pcc"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.pcc !== null &&
                  selectedFinancialRelease.pcc !== undefined
                  ? selectedFinancialRelease.pcc
                  : null
              }
            />
          </div>
          <div className="input-group-normal">
            <p>Número Pagamento</p>

            <input
              type="text"
              autoComplete="off"
              {...methods.register("lancamento.numPagamento")}
              className={
                methods.formState.errors?.lancamento?.numPagamento
                  ? "input-error"
                  : "error"
              }
            />
          </div>
          <div className="input-group-small">
            <p>Número série</p>
            <CustomController
              name="lancamento.numSerie"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.numSerie || null}
            />
          </div>
          <div className="input-group-small">
            <p>Observação Nf</p>
            <CustomController
              name="lancamento.observacaoNf"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.observacaoNf || null}
            />
          </div>
          <div className="input-group-small">
            <p>Pispasep</p>
            <CustomController
              name="lancamento.pispasep"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.pispasep !== null &&
                  selectedFinancialRelease.pispasep !== undefined
                  ? selectedFinancialRelease.pispasep
                  : null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Id tipo doc </p>
            <select {...methods.register("lancamento.tipoDocId")}>
              <option hidden>
                {selectedFinancialRelease.tipoDocId !== null
                  ? deparaTipoDocumento.find(
                    (tipo) =>
                      tipo.cdDeparaSicap ===
                      selectedFinancialRelease.tipoDocId
                  )?.dsDeparaSicap || "Selecione"
                  : "Selecione"}
              </option>
              {deparaTipoDocumento.map((tipoOperacao) => (
                <option
                  key={tipoOperacao.cdDepara}
                  value={tipoOperacao.cdDeparaSicap}
                >
                  {tipoOperacao.dsDeparaSicap}
                </option>
              ))}
            </select>
            {/* <CustomController
              name="tipoDocId"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.tipoDocId || null}
            /> */}
          </div>
          {/* <div className="input-group-normal">
            <p>Id prestação conta</p>
            <CustomController
              name="prestacaoContaId"
              type="number"
              control={methods.control}
              defaultValue={selectedFinancialRelease.prestacaoContaId || null}
            />
          </div>{" "} */}
          <div className="input-group-small">
            <p>valor</p>
            <CustomController
              name="lancamento.valor"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.valor !== null &&
                  selectedFinancialRelease.valor !== undefined
                  ? selectedFinancialRelease.valor
                  : null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Valor doc</p>
            <CustomController
              name="lancamento.valorDoc"
              type="number"
              control={methods.control}
              defaultValue={
                selectedFinancialRelease.valorDoc !== null &&
                  selectedFinancialRelease.valorDoc !== undefined
                  ? selectedFinancialRelease.valorDoc
                  : null
              }
            />
          </div>
          <div className="input-group-small">
            <p>Id tipo fornecedor</p>
            <select {...methods.register("lancamento.tipoFornecedorId")}>
              <option hidden>
                {selectedFinancialRelease.tipoFornecedorId !== null
                  ? deparaTipoFornecedor.find(
                    (tipo) =>
                      tipo.cdDeparaSicap ===
                      selectedFinancialRelease.tipoFornecedorId
                  )?.dsDeparaSicap || "Selecione"
                  : "Selecione"}
              </option>
              {deparaTipoFornecedor.map((tipoOperacao) => (
                <option
                  key={tipoOperacao.cdDepara}
                  value={tipoOperacao.cdDeparaSicap}
                >
                  {tipoOperacao.dsDeparaSicap}
                </option>
              ))}
            </select>
            {/* <CustomController
              name="tipoFornecedorId"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.tipoFornecedorId || null}
            /> */}
          </div>
          <div className="input-group-small">
            <p>Id tipo operação</p>
            <select {...methods.register("lancamento.tipoOperacaoId")}>
              <option hidden>
                {selectedFinancialRelease.tipoOperacaoId !== null
                  ? deparaTipoOperacao.find(
                    (tipo) =>
                      tipo.cdDeparaSicap ===
                      selectedFinancialRelease.tipoOperacaoId
                  )?.dsDeparaSicap || "Selecione"
                  : "Selecione"}
              </option>
              {deparaTipoOperacao.map((tipoOperacao) => (
                <option
                  key={tipoOperacao.cdDepara}
                  value={tipoOperacao.cdDeparaSicap}
                >
                  {tipoOperacao.dsDeparaSicap}
                </option>
              ))}
            </select>
            {/* <CustomController
              name="tipoOperacaoId"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.tipoOperacaoId || null}
            /> */}
          </div>
          <div className="input-group-small">
            <p>Competência</p>
            <CustomController
              name="lancamento.competencia"
              type="text"
              control={methods.control}
              defaultValue={selectedFinancialRelease.competencia || null}
            />
          </div>
          {/* <div className="input-group-small">
            <p>Transmitido</p>

            <select {...methods.register("lancamento.transmitido")}>
              <option hidden value="false">
                Selecione
              </option>
              <option value="true">SIM</option>
              <option value="false">NÃO</option>{" "}
            </select>
          </div> */}
        </main>
        <footer>
          <button type="submit" disabled={methods.formState.isSubmitting}>
            Confirmar
          </button>

          <button onClick={() => handleCloseModal()} type="button">
            Cancelar
          </button>
        </footer>
      </form>
    </div>
  );
}
